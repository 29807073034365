<template>
  <v-dialog
    v-model="fileUploadVisible"
    :hide-overlay="maxlimit?true:false"
    width="750"
    scrollable
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="handleAddClick(update)"
        :disabled="btnDisabledFileUpload"
        v-bind="attrs"
        v-on="on"
        text
        class="btn-s ml-2"
        >{{ $t(update ? 'Edit' : 'Add') }}</v-btn
      >
    </template>
    <v-card class=" add_store_popup" v-if="!maxlimit">
      <div class="popup">
        <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-system" style="margin-left:-25px;"></i>{{ $t('File Upload') }}
      </h3>
    </v-card-title>
      <v-row  >
        <v-col style="margin-left:-25px;" cols="12" sm="7">
          <v-text-field
            v-model="customerInfoInput.description"
            :label="$t('Title')"
            :placeholder="$t('Input the title')"
            class="form-input"
            outlined
            dense
            hide-details
            clearable
            @keydown="clearTabindex"
          ></v-text-field>
        </v-col>
      </v-row>
      </div>
          <hr style="">
<div style="background-color:#ececec;width:750px;overflow-x: hidden !important;" >
  <div class="popup"  >

      <span style="margin-left:-25px;">{{ $t('File Upload') }}</span>
      <v-card-text>

      <v-row>
<div style="margin-top:45px;margin-left:-10px;margin-right:10px;"><b>2.6"</b></div>
        <v-col cols="12" sm="4" >
          <v-file-input
            v-model="customerInfoInput.file_1"
            :label="$t('No Split')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
            @keydown="clearTabindex"
          >
            <template v-slot:append >
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <div style="margin-top:45px;margin-left:-10px;margin-right:4px;"><b>5.85"</b></div>

        <v-col cols="12" sm="4">
          <v-file-input
            v-model="customerInfoInput.file_2"
            :label="$t('No Split')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
             @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" sm="4">
          <v-file-input
            v-model="customerInfoInput.file_3"
            :label="$t('Split 2')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
             @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" sm="3">
          <v-file-input
            v-model="customerInfoInput.file_4"
            :label="$t('Split 3')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
             @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <div style="margin-top:45px;margin-left:-10px;margin-right:10px;" ><b>9.7"</b></div>

        <v-col cols="12" sm="4">
          <v-file-input
            v-model="customerInfoInput.file_5"
            :label="$t('No Split')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
             @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" sm="4">
          <v-file-input
            v-model="customerInfoInput.file_6"
            :label="$t('Split 2')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
             @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>

      </v-row>
      <v-row>
        <div style="margin-top:45px;margin-left:-7px;" align:left><b>12.2"</b></div>

        <v-col cols="12" sm="4">
          <v-file-input
            v-model="customerInfoInput.file_7"
            :label="$t('No Split')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
             @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" sm="4">
          <v-file-input
            v-model="customerInfoInput.file_8"
            :label="$t('Split 2')"
            :placeholder="`${$t('Select file')}`"
            :multiple="false"
            outlined
            dense
            hide-details
            prepend-icon
            flat
            class="form-file"
             @keydown="clearTabindex"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </v-card-text>

  </div>
</div>
<div style="background-color:#ececec">

  <v-card-actions   class="d-flex justify-center">
        <v-btn text icon @click="handleUploadCustomerInfo()" class="btn">{{
          $t('Upload')
        }}</v-btn>
        <v-btn text icon @click="cancelFileUpload()" class="btn">{{
          $t('Cancel')
        }}</v-btn>
      </v-card-actions>
</div>

    </v-card>
    <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />
  </v-dialog>
</template>
<script>
// Utils
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'

// Mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
import FileUploader from '@/mixins/FileUploader'

export default {
  name: 'CustomerInfoAdd',
  mixins: [FileUploader, MsgBoxParent],
  props: {
    stationCode: {
      type: String,
      required: false
    },
    usage: {
      type: String,
      required: true
    },
    update: {
      type: Boolean,
      required: true
    },
    selectedInfo: {
      type: Object,
      required: false
    },
    customerInfoList: {
      type: Array,
      required: true
    },
    selectedareaid: [Array, Object, String, Number]
  },
  data () {
    return {
      customerInfoInput: {
        file_1: null,
        file_2: null,
        file_3: null,
        file_4: null,
        file_5: null,
        file_6: null,
        file_7: null,
        file_8: null,
        description: null,
        language: 'ENG'
        // language: null
      },
      maxlimit: Boolean
    }
  },
  computed: {
    togetcustomerinfo () {
      return this.selectedareaid
    }
  },
  watch: {
    togetcustomerinfo () {
      console.log('sdsadasdada', this.selectedareaid)
      // this.handleCustomerInfoDefaultInput(this.selectedareaid)
    }

  },

  mounted () {},
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    // etc
    afterCancelFileUpload () {
      this.customerInfoInput = {
        description: null,
        language: 'ENG',
        // language: null,
        file_1: [],
        file_2: [],
        file_3: [],
        file_4: [],
        file_5: [],
        file_6: [],
        file_7: [],
        file_8: []
      }
    },
    async buildCustomerInfo (stationCode, usage, customerInfoInput, id) {
      console.log(stationCode)
      console.log(customerInfoInput)
      const arraypush = []
      let splitvalue
      let labelType
      for (var i = 1; i <= 8; i++) {
        if (customerInfoInput['file_' + i] != null && customerInfoInput['file_' + i].length > 0) {
          if (customerInfoInput['file_' + i] === customerInfoInput.file_1 ||
          customerInfoInput['file_' + i] === customerInfoInput.file_2 ||
          customerInfoInput['file_' + i] === customerInfoInput.file_5 ||
          customerInfoInput['file_' + i] === customerInfoInput.file_7) {
            splitvalue = '1'
          } else if (customerInfoInput['file_' + i] === customerInfoInput.file_3 ||
           customerInfoInput['file_' + i] === customerInfoInput.file_6 ||
            customerInfoInput['file_' + i] === customerInfoInput.file_8) {
            splitvalue = '2'
          } else {
            splitvalue = '3'
          }
          if (customerInfoInput['file_' + i] === customerInfoInput.file_1) {
            labelType = '2_6'
          } else if (customerInfoInput['file_' + i] === customerInfoInput.file_2 ||
          customerInfoInput['file_' + i] === customerInfoInput.file_3 ||
          customerInfoInput['file_' + i] === customerInfoInput.file_4) {
            labelType = '5_85'
          } else if (customerInfoInput['file_' + i] === customerInfoInput.file_5 ||
          customerInfoInput['file_' + i] === customerInfoInput.file_6) {
            labelType = '9_7'
          } else if (customerInfoInput['file_' + i] === customerInfoInput.file_7 ||
          customerInfoInput['file_' + i] === customerInfoInput.file_8) {
            labelType = '12_2'
          }
          const file = await commons.getBase64withFormat(customerInfoInput['file_' + i][0])
          if (!this.update) {
            arraypush.push(
              {
                content: file,
                customerInfoContentsPK: {
                  split: splitvalue,
                  type: labelType
                },

                fileName: customerInfoInput['file_' + i][0].name
              }
            )
          } else {
            arraypush.push(
              {
                content: file,
                customerInfoContentsPK: {
                  id: id,
                  split: splitvalue,
                  type: labelType

                },

                fileName: customerInfoInput['file_' + i][0].name
              }
            )
          }
        }
      }
      let customerInfo = []
      if (!this.update) {
        const type = typeof (this.selectedareaid)
        if (type === 'string') {
          console.log('streeirie')
          customerInfo = {
            contents: arraypush,
            description: customerInfoInput.description,
            language: 'ENG',
            priority: this.customerInfoList.length + 1,
            stationCode: this.selectedareaid,
            usage,
            areaId: null
          }
        } else {
          console.log('number')

          customerInfo = {
            contents: arraypush,
            description: customerInfoInput.description,
            language: 'ENG',
            priority: this.customerInfoList.length + 1,
            stationCode: null,
            usage,
            areaId: this.selectedareaid
          }
        }
      } else {
        console.log('kskdjksajlkdjka')
        const type = typeof (this.selectedareaid)
        if (type === 'string') {
          customerInfo = {
            contents: arraypush,
            id: customerInfoInput.id,
            description: customerInfoInput.description,
            language: customerInfoInput.language,
            priority: this.customerInfoList.length + 1,
            stationCode: this.selectedareaid,
            usage,
            areaId: null

          }
        } else {
          customerInfo = {
            contents: arraypush,
            id: customerInfoInput.id,
            description: customerInfoInput.description,
            language: customerInfoInput.language,
            priority: this.customerInfoList.length + 1,
            stationCode: null,
            usage,
            areaId: this.selectedareaid

          }
        }
      }

      if (id) {
        // Edit인 경우
        customerInfo.id = id
        customerInfo.priority = customerInfoInput.priority
      }
      return customerInfo
    },
    // Event Listeners
    async handleAddClick (update) {
      if (this.customerInfoList.length >= 99 && update === false) {
        this.maxlimit = true
        this.openMsgBox(this.$t('You cannot register more than 99'))
        this.$forceUpdate()
      } else if (this.customerInfoList.length <= 99) {
        this.maxlimit = false
        if (!update) return
        this.customerInfoInput.description = this.selectedInfo.description
        this.customerInfoInput.language = 'ENG'
        this.customerInfoInput.priority = this.selectedInfo.priority
        for (var i = 0; i < this.selectedInfo.contents.length; i++) {
          const splitvalue = this.selectedInfo.contents[i].customerInfoContentsPK.split
          const labelType = this.selectedInfo.contents[i].customerInfoContentsPK.type
          if (splitvalue === '3' && labelType === '5_85') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_4 = []
            this.customerInfoInput.file_4[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          } else if (splitvalue === '1' && labelType === '2_6') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_1 = []
            this.customerInfoInput.file_1[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          } else if (splitvalue === '1' && labelType === '5_85') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_2 = []
            this.customerInfoInput.file_2[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          } else if (splitvalue === '1' && labelType === '9_7') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_5 = []
            this.customerInfoInput.file_5[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          } else if (splitvalue === '1' && labelType === '12_2') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_7 = []
            this.customerInfoInput.file_7[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          } else if (splitvalue === '2' && labelType === '5_85') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_3 = []
            this.customerInfoInput.file_3[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          } else if (splitvalue === '2' && labelType === '9_7') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_6 = []
            this.customerInfoInput.file_6[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          } else if (splitvalue === '2' && labelType === '12_2') {
            const blob = await fetch(this.selectedInfo.contents[i].content)
              .then(response => response.blob())
              .then(blob => blob)
            this.customerInfoInput.file_8 = []
            this.customerInfoInput.file_8[0] = new File(
              [blob],
              this.selectedInfo.contents[i].fileName || 'Current file'
            )
          }
        }
      }
    },
    handleUploadCustomerInfo () {
      if (this.update) {
        this.postCustomerInfo(
          this.stationCode,
          this.usage,
          this.customerInfoInput,
          this.selectedInfo
        )
      } else {
        this.postCustomerInfo(
          this.stationCode,
          this.usage,
          this.customerInfoInput
        )
      }
    },
    // API
    async postCustomerInfo (
      stationCode,
      usage,
      customerInfoInput,
      selectedInfo
    ) {
      // TODO: validation check of (this.customerInfoInput)
      // this.openMsgBox(this.$t('Select the file to upload.'))
      const body = [
        await this.buildCustomerInfo(
          stationCode,
          usage,
          customerInfoInput,
          selectedInfo ? selectedInfo.id : null
        )
      ]
      console.log(body)
      const config = { params: {} }
      if (customerInfoInput.description != null && customerInfoInput.description !== '') {
        await this.$utils
          .callAxiosWithBody(
            codes.requests.saveCustomerInfo.method,
            codes.requests.saveCustomerInfo.url.replace('/only', ''),
            body,
            config
          )
          .then(res => {
            this.openMsgBox(this.$t(res.data.responseMessage))
            this.cancelFileUpload()
            this.$emit('reloadCustomerInformation', usage)
          })
          .catch(error => {
            if (error.response.data.responseCode > 300) {
              this.openMsgBox(this.$t(error.response.data.responseMessage))
            }
            console.debug(
            `Failed to upload customer information. error: ${error}`
            )
          })
      } else {
        this.openMsgBox('Please Input Tile')
      }
    }
  }
}
</script>
<style scoped>
::v-deep .v-input__append-inner{
z-index: 100 !important;
}
::v-deep .v-input__control{
  background: white !important;
}
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
