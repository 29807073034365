var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "350" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.handleDeleteExtractionClick }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/ico-delete3.png"),
                      alt: "삭제"
                    }
                  })
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog_msg,
        callback: function($$v) {
          _vm.dialog_msg = $$v
        },
        expression: "dialog_msg"
      }
    },
    [
      _c("v-card", { staticClass: "msg-popup" }, [
        _c(
          "div",
          { staticClass: "inner-popup" },
          [
            _c("i", { staticClass: "ico-success" }, [
              _c("img", {
                attrs: {
                  src: require("../../../assets/img/ico-caution.png"),
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "msg" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("Delete")))]),
              _c("p", [
                _vm._v(_vm._s(_vm.$t("Are you sure you want to delete?")))
              ])
            ]),
            _c(
              "v-card-actions",
              { staticClass: "d-flex justify-center btnArea" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn",
                    attrs: { text: "", icon: "" },
                    on: {
                      click: function($event) {
                        _vm.dialog_msg = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Yes")))]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn",
                    attrs: { text: "", icon: "" },
                    on: {
                      click: function($event) {
                        _vm.dialog_msg = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("No")))]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }