<template>
  <div>
    <v-dialog v-model="msgBox.msgBoxVisible" width="300">
      <v-card class="popup add_store_popup" align="center" justify="center">
        <v-row>
          <v-col>
            <span v-text="msgBox.msg"></span>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="$emit('closeMsgBox')" class="btn">{{
            $t("OK")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MessageBox',
  props: {
    msgBox: {
      type: Object,
      required: true,
      default () {
        return {
          msgBoxVisible: false,
          msg: null
        }
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style>
</style>
