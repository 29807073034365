<template>
	<div>
		<h2 class="page-title-bar">
			<i class="ico ico-system"></i>{{ $t("App Settings") }}
		</h2>
		<v-row
			class="mt-12"
			v-if="Customer !== 'LIDL'"
		>
			<v-col cols="4">
				<h3
					class="page-sub-title-bar"
					style="font-weight: bold"
				>
					{{ $t("AREA LIST") }}
				</h3>
				<!--
        <v-text-field outlined dense hide-details solo
        placeholder="Input the store code"
        prepend-inner-icon="fa-search"
        class="form-input ico-search mt-5"
        >
        </v-text-field>
        -->
				<div class="select_store_box mt-5">
					<h4 class="select_article is-complete">{{ $t("Company") }}</h4>
					<div class="forms-area">
						<v-select
							v-model="selectedCompany"
							:items="companyItems"
							:label="`${$t('Select the company')}`"
							item-text="companyItems.company"
							item-value="companyItems.company"
							@change="changeedCompany()"
							:disabled="disabledCompany"
							clearable:false
							dense
							flat
							hide-details
							solo
						></v-select>
					</div>
				</div>
				<div class="select_store_box mt-5">
					<h4
						ref="country"
						class="select_article"
					>{{ $t("Country") }} <span v-if="regionItems.length === 0">({{
						countryItems.length }})</span> </h4>
					<div class="forms-area">
						<v-select
							v-model="selectedCountry"
							:items="countryItems"
							:label="`${$t('Select the country')}`"
							@change="changeedCountry(); Flagchange('country')"
              :disabled="countryInputDisabled"
							clearable:false
							dense
							flat
							hide-details
							solo
							clearable
						></v-select>
					</div>
				</div>
				<div class="select_store_box">
					<h4
						ref="region"
						class="select_article"
					>{{ $t("Region") }}<span v-if=" cityItems.length === 0 ">({{
							regionItems.length }})</span> </h4>
					<div class="forms-area">
						<v-select
							v-model=" selectedRegion "
							:items=" regionItems "
							:label=" `${$t('Select the region')}` "
							@change=" changeedRegion(); Flagchange('region') "
							clearable:false
              :disabled="regionInputDisabled"
							dense
							flat
							hide-details
							solo
							clearable
						></v-select>
					</div>
				</div>
				<div class="select_store_box">
					<h4
						ref="city"
						class="select_article"
					>{{ $t("City") }} <span v-if=" storeItems.length === 0 ">({{
							cityItems.length }})</span> </h4>
					<div class="forms-area">
						<v-select
							v-model=" selectedCity "
							:items=" cityItems "
							:label=" `${$t('Select the city')}` "
							@change=" changeedCity(); Flagchange('city') "
							clearable:false
              :disabled="cityInputDisabled"
							dense
							flat
							hide-details
							solo
							clearable
						></v-select>
					</div>
				</div>
				<div class="select_store_box">
					<h4
						ref="store"
						class="select_article"
					>{{ $t("Store") }} ({{ storeItems.length }})</h4>
					<div class="forms-area list">
						<!-- <v-select
              v-model="selectedStore"
              :items="storeItems"
              :label="`${$t('Select the city')}`"
              item-value="code" item-text="name"
              @change="changeedCity()"
              clearable:false
              dense
              flat
              hide-details
              solo
              clearable
            ></v-select> -->
						<v-list>
							<v-list-item-group
								v-model=" selectedStore "
								active-class="list-select"
							>
								<v-list-item
									@change=" changeedStore(item.code) "
									v-for="(     item, i     ) in      storeItems     "
									:key=" i "
									:value=" item.code + ',' + item.name "
								>
									<v-list-item-content>
										<v-list-item-title v-text=" item.name + '(' + item.code + ')' "></v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</div>
				</div>
			</v-col>
			<v-col cols="8">
				<div v-bind:class=" { 'className': disableappsettingsbutton === true } ">
					<h3
						class="page-sub-title-bar"
						style="font-weight: bold"
					>
						{{ $t("DETAIL SETTING") }}
					</h3>
					<div
						class="mt-5 custom-scrollBox"
						style="height:500px;overflow-y:scroll;background: #fff !important;border: 1px solid #f5f5f5;box-shadow: 0 1px 5px rgb(220 212 212 / 10);padding:30px;border-radius:5px"
					>
						<div>
							<h3
								class="tit-group-bg mt-0"
								style="font-weight: bold; color: #001e38"
							>
								{{ $t("ASSIGN") }} : {{ $t("Product ID Extraction") }}
							</h3>
							<div class="form-group mt-5">
								<span class="label-txt ml-5">{{ $t("Function On/Off") }}</span>
								<v-select
									:value=" !productIdExtractionDisabled "
									@change=" handleProdIdExtChange "
									:items=" productIdExtractionEnableItems "
									item-text="txt"
									item-value="val"
									:placeholder=" productIdExtractionEnableItems[0].txt "
									class="form-select ml-7"
									outlined
									dense
									dark
									hide-details
									style="width: 82px"
									background-color="#001e38"
									solo
								>
              <template v-slot:item="{item }">
                <span style="font-size: 0.8125rem;">{{ $t(item.txt) }}</span>
              </template>
              <template v-slot:selection="{item }">
                <span class="LedPatternClass" style="display: contents !important;">{{ $t(item.txt) }}</span>
              </template>
             </v-select>
							</div>
							<table
								v-show=" !productIdExtractionDisabled "
								class="tbl-custom-type02 mt-5"
							>
								<colgroup>
									<col style="width: 20%" />
									<col style="width: 20%" />
									<col style="width: 20%" />
									<col style="width: 20%" />
									<col />
								</colgroup>
								<thead>
									<tr>
										<th>{{ $t("PREFIX") }}</th>
										<th>{{ $t("VALID DIGITS") }}</th>
										<th>{{ $t("PADDING VALUE") }}</th>
										<th>{{ $t("TOTAL DIGITS") }}</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="     productIdExtraction      of      productIdExtractionList.filter((x) => { return x.enable || !x.hasOwnProperty('enable'); })     "
										:key=" productIdExtraction.prefix ">
										<td>{{ productIdExtraction.prefix }}</td>
										<td>{{ productIdExtraction.validDigits }}</td>
										<td>{{ productIdExtraction.paddingValue }}</td>
										<td>{{ productIdExtraction.totalDigits }}</td>
										<td>
											<app-config-warning-msg-box
												:productIdExtraction=" productIdExtraction "
												@deleteExtraction=" deleteExtraction "
											/>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="mt-5">
								<app-config-new-extraction
									@addNewExtraction=" addNewExtraction "
									:productIdExtractionDisabled=" productIdExtractionDisabled || appConfigSaveDisabled "
								/>
								<!-- <v-btn
          @click="handleSaveProductIdExtraction"
          :disabled="appConfigSaveDisabled"
          class="btn-s ml-2"
          text
        >
          {{ $t('Save') }}
        </v-btn> -->
							</div>
						</div>
						<div>
							<div>
								<h3
									class="tit-group-bg mt-10"
									style="font-weight: bold; color: #001e38"
								>
									{{ $t("ASSIGN") }} : {{ $t("Extra Info") }}
								</h3>
								<table class="tbl-custom-type02 center-1 mt-5">
									<colgroup>
										<col style="width: 30%" />
										<col />
									</colgroup>
									<thead>
										<tr>
											<th>{{ $t("On/Off") }}</th>
											<th>{{ $t("Category") }}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<v-switch
													v-model=" addInfoConfigList[0].enable "
													class="form-switch center"
													inset
													hide-details
													solo
												></v-switch>
											</td>
											<td>{{ addInfoConfigList[0].name }}</td>
										</tr>
										<tr>
											<td>
												<v-switch
													v-model=" addInfoConfigList[1].enable "
													class="form-switch center"
													inset
													hide-details
													solo
												></v-switch>
											</td>
											<td>
												<v-text-field
													v-model=" addInfoConfigList[1].name "
													:disabled=" !addInfoConfigList[1].enable "
													placeholder="Input the custom info"
													class="form-input"
													dense
													hide-details
													solo
												></v-text-field>
											</td>
										</tr>
										<tr>
											<td>
												<v-switch
													v-model=" addInfoConfigList[2].enable "
													class="form-switch center"
													inset
													hide-details
													solo
												></v-switch>
											</td>
											<td>
												<v-text-field
													v-model=" addInfoConfigList[2].name "
													:disabled=" !addInfoConfigList[2].enable "
													placeholder="Input the custom info"
													class="form-input"
													dense
													hide-details
													solo
												></v-text-field>
											</td>
										</tr>
										<tr>
											<td>
												<v-switch
													v-model=" addInfoConfigList[3].enable "
													class="form-switch center"
													inset
													hide-details
													solo
												></v-switch>
											</td>
											<td>
												<v-text-field
													v-model=" addInfoConfigList[3].name "
													:disabled=" !addInfoConfigList[3].enable "
													placeholder="Input the custom info"
													class="form-input"
													dense
													hide-details
													solo
												></v-text-field>
											</td>
										</tr>
										<tr>
											<td>
												<v-switch
													v-model=" addInfoConfigList[4].enable "
													class="form-switch center"
													inset
													hide-details
													solo
												></v-switch>
											</td>
											<td>
												<v-text-field
													v-model=" addInfoConfigList[4].name "
													:disabled=" !addInfoConfigList[4].enable "
													placeholder="Input the custom info"
													class="form-input"
													dense
													hide-details
													solo
												></v-text-field>
											</td>
										</tr>
									</tbody>
								</table>
								<!-- <v-btn
          @click="handleSaveAddInfoConfigList"
          :disabled="appConfigSaveDisabled"
          class="btn-s mt-5"
          text
        >
          {{ $t('Save') }}
        </v-btn> -->
							</div>
							<div>
								<h3
									class="tit-group-bg mt-10"
									style="font-weight: bold; color: #001e38"
								>
									{{ $t("Editable ProductInfo") }}
								</h3>
								<table class="tbl-custom-type02 center-1 mt-5">
									<colgroup>
										<col style="width: 30%" />
										<col />
									</colgroup>
									<thead>
										<tr>
											<th>#</th>
											<th>{{ $t("Field") }}</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(     editProdInfo, idx     ) of      editableProductInfo     "
											:key=" idx "
										>
											<td>{{ idx }}</td>
											<td>
												<v-select
													v-model=" editableProductInfo[idx] "
													:items=" productKeys "
													class="form-select"
													placeholder="---- Not selected ----"
													style="width: 100%"
													dense
													hide-details
													solo
												></v-select>
											</td>
											<td @click=" handleDelEditProdInfo(editProdInfo) ">
												<div class="v-input__append-inner">
													<div class="v-input__icon v-input__icon--clear">
														<button type="button">
															<img
																src="@/assets/img/ico-delete3.png"
																alt="삭제"
															/>
														</button>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="mt-5">
									<v-btn
										@click=" handleAddEditProdInfo "
										:disabled=' appConfigSaveDisabled '
										text
										class="btn-s"
									>
										{{ $t("Add") }}
									</v-btn>
								</div>
							</div>
							<div>
								<h3
									class="tit-group-bg mt-10"
									style="font-weight: bold; color: #001e38"
								>
									{{ $t("Activate Features") }}
								</h3>
								<div class="row">
									<div class="form-group mt-5">
										<span class="label-txt ml-11">{{ $t("Batch_Assign Menu") }}</span>
										<v-select
											:items=" selectedItems "
											outlined
											v-model=" batchMode "
											dense
											hide-details
											solo
											class="form-select ml-7"
											dark
											style="width: 82px"
											background-color="#001e38"
										></v-select>
									</div>
									<div class="form-group mt-5">
										<span class="label-txt ml-7">{{ $t("Popup_Assign Success") }}</span>
										<v-select
											:items=" selectedItems "
											outlined
											v-model=" assignSuccess "
											class="form-select ml-7"
											dense
											hide-details
											solo
											dark
											style="width: 82px"
											background-color="#001e38"
										></v-select>
									</div>
								</div>
								<div class="row">
									<div class="form-group mt-5">
										<span class="label-txt ml-11">{{ $t("Popup_Assign Start") }}</span>
										<v-select
											:items=" selectedItems "
											outlined
											v-model=" assignStart "
											dense
											hide-details
											solo
											class="form-select ml-7"
											dark
											style="width: 82px"
											background-color="#001e38"
										></v-select>
									</div>
									<div class="form-group mt-5">
										<span class="label-txt ml-7">{{ $t("Popup_Unassign") }}</span>
										<v-select
											:items=" selectedItems "
											outlined
											v-model=" unassign "
											class="form-select ml-16"
											dense
											hide-details
											solo
											dark
											style="width: 82px"
											background-color="#001e38"
										></v-select>
									</div>
								</div>
							</div>
						</div>
						<div
							style="max-width: 100%"
							v-if=" lidlInformationEnabled "
						>
							<h3
								class="tit-group-bg mt-10"
								style="font-weight:bold;color: #001e38;"
							>{{ $t("Label Actions")
								}}</h3>
							<div
								class="customInfo mt-7"
								style="padding: 5px;"
							>
								<div
									class="info-list"
									style="width:100%"
								>
									<v-data-table
										:headers=" LabelActionsHeaders "
										:items=" LabelActionsList "
										:hide-default-footer=" true "
										@page-count=" pageCount = $event "
										class="tableclass"
									>
										<template #item=" { item } ">
											<tr
												:key=" item.name "
												@click=" selectItem(item), selectCustomerInfo(item, LabelActionsList) "
												:class=" { 'selectedRow': item === selectedItem } "
											>
												<td>{{ item.priority }}</td>
												<td>{{ item.name | capitalize }}</td>
												<td style="display:block">
													<v-switch
														:key=" item.name "
														style="margin-top:10px"
														class="form-switch center"
														inset
														hide-details
														solo
														v-model=" item.enable "
													/>
												</td>
											</tr>
										</template>
									</v-data-table>
									<div class="mt-5">
										<v-btn
											@click=" moveCustomerInfo(moveType.start, LabelActionsList, selectedLabelActions) "
											icon
											small
										><img
												src="@/assets/img/ico-move-top.png"
												alt=""
											/></v-btn>
										<v-btn
											@click=" moveCustomerInfo(moveType.up, LabelActionsList, selectedLabelActions) "
											icon
											small
										><img
												src="@/assets/img/ico-move-up.png"
												alt=""
											/></v-btn>
										<v-btn
											@click=" moveCustomerInfo(moveType.down, LabelActionsList, selectedLabelActions) "
											icon
											small
										><img
												src="@/assets/img/ico-move-down.png"
												alt=""
											/></v-btn>
										<v-btn
											@click=" moveCustomerInfo(moveType.end, LabelActionsList, selectedLabelActions) "
											icon
											small
										><img
												src="@/assets/img/ico-move-bottom.png"
												alt=""
											/></v-btn>
									</div>
								</div>
							</div>
						</div>

						<div
							style="max-width: 100%"
							v-if=" lidlInformationEnabled "
						>
							<h3
								class="tit-group-bg mt-10"
								style="font-weight:bold;color: #001e38;"
							>{{ $t("Features") }}
							</h3>
							<div
								class="customInfo mt-7"
								style="padding: 5px;"
							>
								<div
									class="info-list"
									style="width:100%"
								>
									<v-data-table
										:headers=" FeaturesHeaders "
										:items=" FeaturesList "
										:hide-default-footer=" true "
										class="tbl-type04"
									>
										<template #item=" { item } ">
											<tr>
												<td>{{ item.name | capitalize }}</td>
												<td style="display:block">
													<v-switch
														:key=" item.name "
														style="margin-top:10px"
														class="form-switch center"
														inset
														hide-details
														solo
														v-model=" item.enable "
													/>
												</td>
											</tr>
										</template>
									</v-data-table>
								</div>
							</div>
						</div>
						<div
							style="max-width: 100%"
							v-if=" lidlInformationEnabled "
						>
							<h3
								class="tit-group-bg mt-10"
								style="font-weight:bold;color: #001e38;"
							>{{
								$t("SpecialFunctions") }}</h3>
							<div
								class="customInfo mt-7"
								style="padding: 5px;"
							>
								<div
									class="info-list"
									style="width:100%"
								>
									<v-data-table
										:headers=" SpecialFunctionsHeaders "
										:items=" SpecialFunctionsList "
										:hide-default-footer=" true "
										class="tbl-type04"
									>
										<template #item=" { item } ">
											<tr>
												<td>{{ item.name | capitalize }}</td>
												<td style="display:block">
													<v-switch
														:key=" item.name "
														style="margin-top:10px"
														class="form-switch"
														inset
														hide-details
														solo
														v-model=" item.enable "
													/>
												</td>
											</tr>
										</template>
									</v-data-table>
								</div>
							</div>
						</div>

						<customer-information
							:customerInformationEnabled=" customerInformationEnabled "
							:selectedareaid=' areaid '
							:reloaddatacust=' reloadcust '
							:appConfigSaveDisabled1=' appConfigSaveDisabled '
						/>
						<favorite-menu
							:favoriteButtonEnabled=" favoriteButtonEnabled "
							:selectedareaid=' areaid '
							:reloaddatafav=' reloadfav '
							:appConfigSaveDisabled1=' appConfigSaveDisabled '
						/>
						<app-config-msg-box
							:msgModalVisible=" msgModalVisible "
							@closeAppConfigMsgBox=" closeAppConfigMsgBox "
						/>
					</div>
					<v-btn
						@click=" Tosaveappsettings "
						:disabled=" appConfigSaveDisabled "
						class="btn-s mt-5"
						text
					>
						{{ $t("Save") }}
					</v-btn>
				</div>
				<v-btn
					@click=" deleteappsettings "
					:disabled=' appConfigSaveDisabled '
					v-show=" !disableappsettingsbutton && selectedCountry !== '' && selectedCountry !== 'undefined' "
					style="width: 128px; float: right;margin-top: -33px !important; background: red; border-radius: 5px;color: white;padding: 3px;"
					class="btn btn-danger"
				>
					{{ $t("Delete Config") }}
				</v-btn>
			</v-col>
		</v-row>

		<!-- //LIDL Requirements -->

		<v-row
			class="mt-12 ml-2"
			v-if=" Customer === 'LIDL' "
		>
			<h3
				class="page-sub-title-bar"
				style="font-weight: bold"
			>
				{{ $t("EXISTING CONFIGRATION") }}
			</h3>
			<div
				class="col-12"
				style="padding:0"
			>
				<v-data-table
					v-model=" selectedconfig "
					:headers=" existingConfigHeaders "
					:items=" existingConfig "
					item-key="indexVal"
					:options.sync=" options "
					:server-items-length=" 10 "
					show-select
					:hide-default-footer=" true "
					@click:row=" editExistConfig "
					class="tbl-type01 mt-10"
				>
					<template slot="no-data">
						<p>
							{{ $t('No data available') }}
						</p>
					</template>
				</v-data-table>
				<div class="table-options">
					<div>
						<v-btn
							@click=" AddNewConfigpopup "
							text
							class="btn  ml-2"
              :disabled="appConfigSaveDisabled"
						>{{ $t('Add') }}</v-btn>
						<v-btn
							@click=" deleteExistingConfig "
							:disabled=" selectedconfig.length === 0 || appConfigSaveDisabled  "
							text
							class="btn  ml-2"
						>{{
							$t('Delete') }}</v-btn>
					</div>
				</div>
				<div
					class="col-12 pr-0"
					style="padding:0px"
				>
					<Pagination
						@paging=" paging($event) "
						:pageInfoText=" pageInfoText "
						:pageIndex=" pageIndex "
						:rowPerPage=" rowPerPage "
						:goToPageInput=' goToPageInput '
						:totalPages=" totalPages "
						@updatePage=" updatePage($event) "
					></Pagination>
				</div>
				<a
					ref="link"
					:style=" { display: 'none' } "
				/>
			</div>

			<v-dialog
				v-model=" toSelectLevel "
				@click:outside=" closeAppConfigMsgBox "
				width="530"
			>
				<v-card class="popup-success">
					<div class="msg">
						<v-col cols="12">
							<h3 class="page-title-bar">
								<i class="ico ico-overview"></i>{{ $t("Select the store") }}
							</h3>
							<div class="select_store_box mt-5">
								<h4 class="select_article is-complete">{{ $t("Company") }}</h4>
								<div class="forms-area">
									<v-select
										v-model=" selectedCompany "
										:items=" companyItems "
										:label=" `${$t('Select the company')}` "
										item-text="companyItems.company"
										item-value="companyItems.company"
										@change=" changeedCompany() "
										:disabled=" disabledCompany "
										clearable:false
										dense
										flat
										hide-details
										solo
									></v-select>
								</div>
							</div>
							<div class="select_store_box mt-5">
								<h4
									ref="country"
									class="select_article"
								>{{ $t("Country") }} <span v-if=" regionItems.length === 0 ">({{ countryItems.length }})</span> </h4>
								<div class="forms-area">
									<v-select
										v-model=" selectedCountry "
										:items=" countryItems "
										:label=" `${$t('Select the country')}` "
										@change=" changeedCountry(); Flagchange('country') "
										clearable:false
                    :disabled="countryInputDisabled"
										dense
										flat
										hide-details
										solo
										clearable
									></v-select>
								</div>
							</div>
							<div class="select_store_box">
								<h4
									ref="region"
									class="select_article"
								>{{ $t("Region") }}<span v-if=" cityItems.length === 0 ">({{ regionItems.length }})</span> </h4>
								<div class="forms-area">
									<v-select
										v-model=" selectedRegion "
										:items=" regionItems "
										:label=" `${$t('Select the region')}` "
										@change=" changeedRegion(); Flagchange('region') "
										clearable:false
                    :disabled="regionInputDisabled"
										dense
										flat
										hide-details
										solo
										clearable
									></v-select>
								</div>
							</div>
							<div class="select_store_box">
								<h4
									ref="city"
									class="select_article"
								>{{ $t("City") }} <span v-if=" storeItems.length === 0 ">({{ cityItems.length }})</span> </h4>
								<div class="forms-area">
									<v-select
										v-model=" selectedCity "
										:items=" cityItems "
										:label=" `${$t('Select the city')}` "
										@change=" changeedCity(); Flagchange('city') "
										clearable:false
                    :disabled="cityInputDisabled"
										dense
										flat
										hide-details
										solo
										clearable
									></v-select>
								</div>
							</div>
							<div class="select_store_box">
								<h4
									ref="store"
									class="select_article"
								>{{ $t("Store") }} ({{ storeItems.length }})</h4>
								<div class="forms-area list">
									<v-list>
										<v-list-item-group
											v-model=" selectedStore "
											active-class="list-select"
										>
											<v-list-item
												@change=" changeedStore(item.code) "
												v-for="(     item, i     ) in      storeItems     "
												:key=" i "
												:value=" item.code + ',' + item.name "
											>
												<v-list-item-content>
													<v-list-item-title v-text=" item.name + '(' + item.code + ')' "></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</div>
							</div>
						</v-col>
					</div>
					<v-card-actions class="d-flex justify-center btnArea">
						<v-btn
							@click=" toSelectareaLevel "
							text
							icon
							class="btn"
						>
							{{ $t('Select') }}
						</v-btn>
						<v-btn
							@click=" closeSelectareaPopup "
							text
							icon
							class="btn"
						>
							{{ $t('Cancel') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model=" addNewConfig "
				@click:outside=" closeAppConfigMsgBox "
				width="45%"
			>
				<v-card class="popup-success">
					<div class="msg">
						<v-col cols="12">
							<div v-bind:class=" { 'className': disableappsettingsbutton === true } ">
								<h3
									class="page-title-bar"
									style="font-weight: bold"
								>
									{{ $t("DETAIL SETTING") }}
								</h3>
								<div
									class="mt-5 custom-scrollBox"
									style="height:500px;overflow-y:scroll;background: #fff !important;border: 1px solid #f5f5f5;box-shadow: 0 1px 5px rgb(220 212 212 / 10);padding:30px;border-radius:5px"
								>
									<div>
										<h3
											class="tit-group-bg mt-0"
											style="font-weight: bold; color: #001e38"
										>
											{{ $t("ASSIGN") }} : {{ $t("Product ID Extraction") }}
										</h3>
										<div class="form-group mt-5">
											<span class="label-txt ml-5">{{ $t("Function On/Off") }}</span>
											<v-select
												:value=" !productIdExtractionDisabled "
												@change=" handleProdIdExtChange "
												:items=" productIdExtractionEnableItems "
												item-text="txt"
												item-value="val"
												:placeholder=" productIdExtractionEnableItems[0].txt "
												class="form-select ml-7"
												outlined
												dense
												dark
												hide-details
												style="width: 82px"
												background-color="#001e38"
												solo
											></v-select>
										</div>
										<table
											v-show=" !productIdExtractionDisabled "
											class="tbl-custom-type02 mt-5"
										>
											<colgroup>
												<col style="width: 20%" />
												<col style="width: 20%" />
												<col style="width: 20%" />
												<col style="width: 20%" />
												<col />
											</colgroup>
											<thead>
												<tr>
													<th>{{ $t("PREFIX") }}</th>
													<th>{{ $t("VALID DIGITS") }}</th>
													<th>{{ $t("PADDING VALUE") }}</th>
													<th>{{ $t("TOTAL DIGITS") }}</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="productIdExtraction of productIdExtractionList.filter((x) => {return x.enable || !x.hasOwnProperty('enable');})"
													:key=" productIdExtraction.prefix "
												>
													<td>{{ productIdExtraction.prefix }}</td>
													<td>{{ productIdExtraction.validDigits }}</td>
													<td>{{ productIdExtraction.paddingValue }}</td>
													<td>{{ productIdExtraction.totalDigits }}</td>
													<td>
														<app-config-warning-msg-box
															:productIdExtraction=" productIdExtraction "
															@deleteExtraction=" deleteExtraction "
														/>
													</td>
												</tr>
											</tbody>
										</table>
										<div class="mt-5">
											<app-config-new-extraction
												@addNewExtraction=" addNewExtraction "
												:productIdExtractionDisabled=" productIdExtractionDisabled || appConfigSaveDisabled "
											/>
										</div>
									</div>
									<div>
										<div>
											<h3
												class="tit-group-bg mt-10"
												style="font-weight: bold; color: #001e38"
											>
												{{ $t("ASSIGN") }} : {{ $t("Extra Info") }}
											</h3>
											<table class="tbl-custom-type02 center-1 mt-5">
												<colgroup>
													<col style="width: 30%" />
													<col />
												</colgroup>
												<thead>
													<tr>
														<th>{{ $t("On/Off") }}</th>
														<th>{{ $t("Category") }}</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<v-switch
																v-model=" addInfoConfigList[0].enable "
																class="form-switch center"
																inset
																hide-details
																solo
															></v-switch>
														</td>
														<td>{{ addInfoConfigList[0].name }}</td>
													</tr>
													<tr>
														<td>
															<v-switch
																v-model=" addInfoConfigList[1].enable "
																class="form-switch center"
																inset
																hide-details
																solo
															></v-switch>
														</td>
														<td>
															<v-text-field
																v-model=" addInfoConfigList[1].name "
																:disabled=" !addInfoConfigList[1].enable "
																placeholder="Input the custom info"
																class="form-input"
																dense
																hide-details
																solo
															></v-text-field>
														</td>
													</tr>
													<tr>
														<td>
															<v-switch
																v-model=" addInfoConfigList[2].enable "
																class="form-switch center"
																inset
																hide-details
																solo
															></v-switch>
														</td>
														<td>
															<v-text-field
																v-model=" addInfoConfigList[2].name "
																:disabled=" !addInfoConfigList[2].enable "
																placeholder="Input the custom info"
																class="form-input"
																dense
																hide-details
																solo
															></v-text-field>
														</td>
													</tr>
													<tr>
														<td>
															<v-switch
																v-model=" addInfoConfigList[3].enable "
																class="form-switch center"
																inset
																hide-details
																solo
															></v-switch>
														</td>
														<td>
															<v-text-field
																v-model=" addInfoConfigList[3].name "
																:disabled=" !addInfoConfigList[3].enable "
																placeholder="Input the custom info"
																class="form-input"
																dense
																hide-details
																solo
															></v-text-field>
														</td>
													</tr>
													<tr>
														<td>
															<v-switch
																v-model=" addInfoConfigList[4].enable "
																class="form-switch center"
																inset
																hide-details
																solo
															></v-switch>
														</td>
														<td>
															<v-text-field
																v-model=" addInfoConfigList[4].name "
																:disabled=" !addInfoConfigList[4].enable "
																placeholder="Input the custom info"
																class="form-input"
																dense
																hide-details
																solo
															></v-text-field>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div>
											<h3
												class="tit-group-bg mt-10"
												style="font-weight: bold; color: #001e38"
											>
												{{ $t("Editable ProductInfo") }}
											</h3>
											<table class="tbl-custom-type02 center-1 mt-5">
												<colgroup>
													<col style="width: 30%" />
													<col />
												</colgroup>
												<thead>
													<tr>
														<th>#</th>
														<th>{{ $t("Field") }}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr
														v-for="(     editProdInfo, idx     ) of      editableProductInfo     "
														:key=" idx "
													>
														<td>{{ idx }}</td>
														<td>
															<v-select
																v-model=" editableProductInfo[idx] "
																:items=" productKeys "
																class="form-select"
																placeholder="---- Not selected ----"
																style="width: 100%"
																dense
																hide-details
																solo
															></v-select>
														</td>
														<td @click=" handleDelEditProdInfo(editProdInfo) ">
															<div class="v-input__append-inner">
																<div class="v-input__icon v-input__icon--clear">
																	<button type="button">
																		<img
																			src="@/assets/img/ico-delete3.png"
																			alt="삭제"
																		/>
																	</button>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
											<div class="mt-5">
												<v-btn
													@click=" handleAddEditProdInfo "
													:disabled=' appConfigSaveDisabled '
													text
													class="btn-s"
												>
													{{ $t("Add") }}
												</v-btn>
											</div>
										</div>
										<div>
											<h3
												class="tit-group-bg mt-10"
												style="font-weight: bold; color: #001e38"
											>
												{{ $t("Activate Features") }}
											</h3>
											<div class="row">
												<div class="form-group mt-5">
													<span class="label-txt ml-11">{{ $t("Batch_Assign Menu") }}</span>
													<v-select
														:items=" selectedItems "
														outlined
														v-model=" batchMode "
														dense
														hide-details
														solo
														class="form-select ml-7"
														dark
														style="width: 82px"
														background-color="#001e38"
													></v-select>
												</div>
												<div class="form-group mt-5">
													<span class="label-txt ml-7">{{ $t("Popup_Assign Success") }}</span>
													<v-select
														:items=" selectedItems "
														outlined
														v-model=" assignSuccess "
														class="form-select ml-7"
														dense
														hide-details
														solo
														dark
														style="width: 82px"
														background-color="#001e38"
													></v-select>
												</div>
											</div>
											<div class="row">
												<div class="form-group mt-5">
													<span class="label-txt ml-11">{{ $t("Popup_Assign Start") }}</span>
													<v-select
														:items=" selectedItems "
														outlined
														v-model=" assignStart "
														dense
														hide-details
														solo
														class="form-select ml-7"
														dark
														style="width: 82px"
														background-color="#001e38"
													></v-select>
												</div>
												<div class="form-group mt-5">
													<span class="label-txt ml-7">{{ $t("Popup_Unassign") }}</span>
													<v-select
														:items=" selectedItems "
														outlined
														v-model=" unassign "
														class="form-select ml-16"
														dense
														hide-details
														solo
														dark
														style="width: 82px"
														background-color="#001e38"
													></v-select>
												</div>
											</div>
										</div>
									</div>
									<div
										style="max-width: 100%"
										v-if=" lidlInformationEnabled "
									>
										<h3
											class="tit-group-bg mt-10"
											style="font-weight:bold;color: #001e38;"
										>{{ $t("Label Actions") }}</h3>
										<div
											class="customInfo mt-7"
											style="padding: 5px;"
										>
											<div
												class="info-list"
												style="width:100%"
											>
												<v-data-table
													:headers=" LabelActionsListForLiDL "
													:items=" LabelActionsList "
													:hide-default-footer=" true "
													@page-count=" pageCount = $event "
													class="tableclass"
												>
													<template #item=" { item } ">
														<tr
															:key=" item.name "
															@click=" selectItem(item), selectCustomerInfo(item, LabelActionsList) "
															:class=" { 'selectedRow': item === selectedItem } "
														>
															<td>{{ item.priority }}</td>
															<td>{{ item.name | capitalize }}</td>
															<td style="display:block">
																<v-switch
																	:key=" item.name "
																	style="margin-top:10px"
																	class="form-switch center"
																	inset
																	hide-details
																	solo
																	v-model=" item.enable "
																/>
															</td>
															<td>
																<div class="d-flex">
																	<v-select
																		v-if=" item.name.includes('customerInfoShortCutEnabled') && item.enable == true "
																		v-model=" item.description "
																		:items=" customerInfoLabelActions "
																		:label=" `${$t('Select the Customer Information')}` "
																		item-text="description"
																		item-value="description"
																		class="label_details"
																		style="width: 15%;"
																		clearable:false
																		dense
																		flat
																		ref="clearCIInput"
																		hide-details
																		solo
																	>
																		<template v-slot:selection="{ item }">
																			<v-tooltip top>
																				<template v-slot:activator="{ on, attrs }">
																					<span
																						v-bind="attrs"
																						v-on="on"
																					>{{ item.description }}</span>
																				</template>
																				<span>{{ item.description }}</span>
																			</v-tooltip>
																		</template>
																	</v-select>
																</div>
															</td>
														</tr>
													</template>
												</v-data-table>
												<div class="mt-5">
													<v-btn
														@click=" moveCustomerInfo(moveType.start, LabelActionsList, selectedLabelActions) "
														icon
														small
													><img
															src="@/assets/img/ico-move-top.png"
															alt=""
														/></v-btn>
													<v-btn
														@click=" moveCustomerInfo(moveType.up, LabelActionsList, selectedLabelActions) "
														icon
														small
													><img
															src="@/assets/img/ico-move-up.png"
															alt=""
														/></v-btn>
													<v-btn
														@click=" moveCustomerInfo(moveType.down, LabelActionsList, selectedLabelActions) "
														icon
														small
													><img
															src="@/assets/img/ico-move-down.png"
															alt=""
														/></v-btn>
													<v-btn
														@click=" moveCustomerInfo(moveType.end, LabelActionsList, selectedLabelActions) "
														icon
														small
													><img
															src="@/assets/img/ico-move-bottom.png"
															alt=""
														/></v-btn>
												</div>
											</div>
										</div>
									</div>

									<div
										style="max-width: 100%"
										v-if=" lidlInformationEnabled "
									>
										<h3
											class="tit-group-bg mt-10"
											style="font-weight:bold;color: #001e38;"
										>{{
											$t("Features") }}</h3>
										<div
											class="customInfo mt-7"
											style="padding: 5px;"
										>
											<div
												class="info-list"
												style="width:100%"
											>
												<v-data-table
													:headers=" FeaturesHeaders "
													:items=" FeaturesList "
													:hide-default-footer=" true "
													class="tbl-type04"
												>
													<template #item=" { item } ">
														<tr>
															<td>{{ item.name | capitalize }}</td>
															<td style="display:block">
																<v-switch
																	:key=" item.name "
																	style="margin-top:10px"
																	class="form-switch center"
																	inset
																	hide-details
																	solo
																	v-model=" item.enable "
																/>
															</td>
														</tr>
													</template>
												</v-data-table>
											</div>
										</div>
									</div>
									<div
										style="max-width: 100%"
										v-if=" lidlInformationEnabled "
									>
										<h3
											class="tit-group-bg mt-10"
											style="font-weight:bold;color: #001e38;"
										>{{
											$t("SpecialFunctions") }}</h3>
										<div
											class="customInfo mt-7"
											style="padding: 5px;"
										>
											<div
												class="info-list"
												style="width:100%"
											>
												<v-data-table
													:headers=" SpecialFunctionsHeaders "
													:items=" SpecialFunctionsList "
													:hide-default-footer=" true "
													class="tbl-type04"
												>
													<template #item=" { item } ">
														<tr>
															<td>{{ item.name | capitalize }}</td>
															<td style="display:block">
																<v-switch
																	:key=" item.name "
																	style="margin-top:10px"
																	class="form-switch"
																	inset
																	hide-details
																	solo
																	v-model=" item.enable "
																/>
															</td>
														</tr>
													</template>
												</v-data-table>
											</div>
										</div>
									</div>

									<customer-information
										:customerInformationEnabled=" customerInformationEnabled "
										:selectedareaid=' areaid '
										:reloaddatacust=' reloadcust '
										:appConfigSaveDisabled1=' appConfigSaveDisabled '
									/>
									<favorite-menu
										:favoriteButtonEnabled=" favoriteButtonEnabled "
										:selectedareaid=' areaid '
										:reloaddatafav=' reloadfav '
										:appConfigSaveDisabled1=' appConfigSaveDisabled '
									/>
									<app-config-msg-box
										:msgModalVisible=" msgModalVisible "
										@closeAppConfigMsgBox=" closeAppConfigMsgBox "
									/>
								</div>
								<v-btn
									@click=" Tosaveappsettings "
									:disabled=" appConfigSaveDisabled "
									class="btn-s mt-5"
									text
								>
									{{ $t("Save") }}
								</v-btn>
								<v-btn
									@click=" addNewConfig = false "
									class="btn-s mt-5 ml-2"
									text
								>
									{{ $t("Cancel") }}
								</v-btn>
							</div>
						</v-col>
					</div>
				</v-card>
			</v-dialog>
		</v-row>

		<!-- //END LIDL -->
	</div>
</template>

<script>
import config from '@/plugins/configs'
import codes from '@/plugins/codes'
import commons from '@/plugins/commons'
import AppConfigMsgBox from './modal/AppConfigMsgBox'
import AppConfigNewExtraction from './modal/AppConfigNewExtraction'
import AppConfigWarningMsgBox from './modal/AppConfigWarningMsgBox.vue'
import CustomerInformation from './AppConfigCustomerInformation'
import FavoriteMenu from './AppConfigFavoriteMenu'
import EventBus from '@/plugins/eventBus.js'

// for LIDL
import Pagenation from '@/mixins/Pagenation'
import Pagination from '@/components/pagination/Pagination.vue'
const getexistConfig = {
  url: '/api/common/config/appSettings/existing/configurations',
  method: 'get'
}
const deleteexistConfig = {
  url: '/api/common/config/appSettings/existing/configurations',
  method: 'delete'
}

// END LIDL

export default {
  name: 'AppConfig',
  mixins: [Pagenation],
  components: {
    AppConfigMsgBox,
    AppConfigNewExtraction,
    AppConfigWarningMsgBox,
    CustomerInformation,
    FavoriteMenu,
    Pagination
  },
  data () {
    return {
      favoriteButtonEnabled: false,
      customerInformationEnabled: false,
      lidlInformationEnabled: false,
      appConfigSaveDisabled: null,
      msgModalVisible: false,
      productIdExtractionEnableItems: [
        { txt: 'On', val: true },
        { txt: 'Off', val: false }
      ],

      // activatefeatures
      batchMode: '',
      assignSuccess: '',
      assignStart: '',
      unassign: '',
      selectedItems: ['On', 'Off'],
      activateFeatures: {},

      productIdExtractionList: [],
      // productIdExtraction: {
      //   enable: true,
      //   prefix: '',
      //   validDigits: '',
      //   paddingValue: '',
      //   totalDigits: ''
      // },
      productIdExtractionOptions: {
        enable: [true, false]
      },
      productIdExtractionDisabled: false,
      addInfoConfigList: [
        // { name: 'Arrow', enable: false },
        // { name: 'Origin', enable: false },
        // { name: 'Location', enable: false },
        // { name: '', enable: false },
        // { name: '', enable: false }
        { id: 1, name: 'Arrow', enable: true },
        { id: 2, name: 'Below', enable: false },
        { id: 3, name: '', enable: false },
        { id: 4, name: '', enable: false },
        { id: 5, name: '', enable: false }
      ],
      LabelActionsList: [
        { id: 1, name: 'splitEnabled', enable: 'true', priority: '1' },
        { id: 2, name: 'customerInfoEnabled', enable: 'false', priority: '2' },
        { id: 3, name: 'additionalLabelEnabled', enable: 'false', priority: '3' },
        { id: 4, name: 'customerInfoShortCutEnabled', enable: false, priority: '4' }
      ],
      LabelActionsListForLiDL: [
        { id: 1, name: 'splitEnabled', enable: 'true', priority: '1', description: '' },
        { id: 2, name: 'customerInfoEnabled', enable: 'false', priority: '2', description: '' },
        { id: 3, name: 'additionalLabelEnabled', enable: 'false', priority: '3', description: '' },
        { id: 4, name: 'customerInfoShortCutEnabled1', enable: false, priority: '4', description: '' },
        { id: 5, name: 'customerInfoShortCutEnabled2', enable: false, priority: '5', description: '' },
        { id: 6, name: 'customerInfoShortCutEnabled3', enable: false, priority: '6', description: '' }
      ],
      FeaturesList: [
        { name: 'deleteLabel', enable: true },
        { name: 'RMA', enable: false },
        { name: 'ScanWithoutConfirmation', enable: false }
      ],
      SpecialFunctionsList: [
        { name: 'SSCEnabled', enable: true },
        { name: 'RollingEnabled', enable: false },
        { name: 'WateringEnabled', enable: false },
        { name: 'FreshnessEnabled', enable: false }
      ],

      editableProductInfo: [],
      productKeys: [],

      LabelActionsHeaders: [
        {
          text: 'POS',
          align: 'start',
          sortable: false,
          value: 'priority',
          width: '20%'
        },
        {
          text: 'NAME',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '35%'
        },
        {
          text: 'ENABLED',
          align: 'start',
          sortable: false,
          value: 'enable',
          width: '35%'
        }
      ],
      FeaturesHeaders: [
        {
          text: 'NAME',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '35%'
        },
        {
          text: 'ENABLED',
          align: 'start',
          sortable: false,
          value: 'enable',
          width: '35%'
        }
      ],
      SpecialFunctionsHeaders: [
        {
          text: 'NAME',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '35%'
        },
        {
          text: 'ENABLED',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '35%'
        }
      ],

      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      dialog_UserPopup: false,
      buttonDisabled: false,
      page: 1,
      pageCount: 0,
      selectedSaveUsers: [],
      disabledCompany: true,
      selectedCompany: '',
      selectedCountry: '',
      selectedRegion: '',
      selectedCity: '',
      selectedStoreCode: '',
      selectedStoreName: '',
      searchStoreItems: [],
      rtnStore: {},
      selectedStore: '',
      companyItems: [],
      countryItems: [],
      regionItems: [],
      cityItems: [],
      storeItems: [],
      toselectedcountry: [],
      toselectedregion: [],
      toselectedcity: [],
      toselectedstore: [],
      areaparamlist: '',
      countrylist: '',
      areaid: '',
      disableappsettingsbutton: false,
      permissionkey: '',
      permissionvalue: '',
      changeStoreActionFlag: false,
      reloadcust: false,
      reloadfav: false,
      selectedLabelActions: {},
      selectedItem: null,
      flagdata: '',
      btndisabled: false,

      // Lidl requirements

      Customer: config.Customer,
      existingConfigHeaders: [
        {
          text: 'COMPANY',
          align: 'start',
          sortable: false,
          value: 'company',
          width: '20%'
        },
        {
          text: 'COUNTRY',
          align: 'start',
          sortable: false,
          value: 'country',
          width: '20%'
        },
        {
          text: 'REGION',
          align: 'start',
          sortable: false,
          value: 'region',
          width: '20%'
        },
        {
          text: 'CITY',
          align: 'start',
          sortable: false,
          value: 'city',
          width: '20%'
        }, {
          text: 'STORE',
          align: 'start',
          sortable: false,
          value: 'stationcode',
          width: '20%'
        }
      ],
      toSelectLevel: false,
      addNewConfig: false,
      existingConfig: [],
      selectedconfig: [],
      options: {},
      totalPages: 1,
      pageIndex: 1,
      rowPerPage: '10',
      rowPerPageList: [5, 10, 20, 50, 100],
      pageInfoText: '',
      totalVisiblePages: 5,
      FinalAreaId: '',
      customerInfoLabelActions: [],
      CIdescription: '',

      // END LIDL

      countryInputDisabled: true,
      regionInputDisabled: true,
      cityInputDisabled: true

    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  computed: {
    user: function () {
      return this.$store.state.auth.user
    },
    cloudMode: function () {
      return this.productionType === codes.productionTypes.CLOUD
    },
    moveType () {
      const moveType = {
        start: 'START',
        up: 'UP',
        down: 'DOWN',
        end: 'END'
      }
      return moveType
    }
  },

  // LIDL
  watch: {
    // options: {
    //   handler () {
    //     this.getExistingConfig(1, this.page)
    //     // this.page = 1
    //   },
    //   deep: true
    // },
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },
  // END LIDL
  created () {
    // Show Customer Information, Favorite Buttons refer to properties.json
    this.favoriteButtonEnabled = config.favoriteButton
    this.customerInformationEnabled = config.customerInformation
    this.lidlInformationEnabled = config.lidlFunction
  },
  mounted () {
    this.clearTabindex()
    this.initAppConfig(this.user)
    this.getProductKeys(this.user)
    this.permissionkey = this.$store.state.dataStore.permissionKey
    this.permissionvalue = this.$store.state.dataStore.permissionValue
    const storecode = this.$store.state.dataStore.managedStores
    console.log(storecode)
    this.togetareaparam()
    // Disable buttons
    this.$store.dispatch('auth/getDisabledBtn', '9300').then((flag) => {
      if (flag) {
        this.appConfigSaveDisabled = flag
        this.btndisabled = flag
      } else {
        if (this.permissionkey === 'store' && this.selectedStore.code === '') {
          this.appConfigSaveDisabled = true
        } else if (this.permissionkey === 'company' && this.selectedCompany === '') {
          this.appConfigSaveDisabled = true
        } else if (this.permissionkey === 'city' && this.selectedCity === '') {
          this.appConfigSaveDisabled = true
        } else if (this.permissionkey === 'country' && this.selectedCountry === '') {
          this.appConfigSaveDisabled = true
        } else if (this.permissionkey === 'region' && this.selectedRegion === '') {
          this.appConfigSaveDisabled = true
        } else {
          this.appConfigSaveDisabled = flag
        }
      }
      // this.appConfigSaveDisabled = flag
    })
    if (this.permissionkey === 'company') {
      this.countryInputDisabled = false
      this.regionInputDisabled = false
      this.cityInputDisabled = false
    }
    if (this.permissionkey === 'country') {
      this.countryInputDisabled = false
      this.regionInputDisabled = false
      this.cityInputDisabled = false
    }
    if (this.permissionkey === 'region') {
      this.countryInputDisabled = true
      this.regionInputDisabled = false
      this.cityInputDisabled = false
    }
    if (this.permissionkey === 'city') {
      this.countryInputDisabled = true
      this.regionInputDisabled = true
      this.cityInputDisabled = false
    }
    if (this.permissionkey === 'store') {
      this.countryInputDisabled = true
      this.regionInputDisabled = true
      this.cityInputDisabled = true
    }

    switch (this.$store.state.dataStore.permissionKey) {
      case 'company':
        console.log('comapny')
        this.setCompany()
        break
      case 'country':
        this.setCompany()
        this.setCountry()
        break
      case 'region':
        console.log('region')
        this.setCompany()
        this.setCountry()
        this.setRegion()
        break
      case 'city':
        this.setCompany()
        this.setCountry()
        this.setRegion()
        this.setCity()
        break
      case 'store':
        this.setCompany()
        this.setCountry()
        this.setRegion()
        this.setCity()
        this.setStore()
        this.togetapiconfig(this.permissionvalue)
        // this.changeedStore(this.permissionvalue)
        this.areaId = this.permissionvalue
        break

      default:
        break
    }

    // if (this.permissionkey === 'store') {
    //   console.log(this.selectedStore)
    //   this.togetapiconfig(this.permissionvalue)
    //   // this.changeedStore(this.permissionvalue)
    //   this.areaId = this.permissionvalue
    //   this.setCompany()
    //   this.setCountry()
    //   this.setRegion()
    //   this.setCity()
    //   this.setStore()
    // } else {
    //   this.setCompany()
    //   this.setCountry()
    //   // this.togetapiconfig(this.areaidforcompany)
    // }

    // init
    if (this.productionType === 'cloud') {
      this.disabledCompany = false
    }
    //  User Mapping : Add / Delete
    this.$store.dispatch('auth/getDisabledBtn', '8200').then((flag) => {
      console.log('sdfpo')
      this.buttonDisabled = flag
    })

    // 상단 공통 팝업 & 자동완성기능 사용유무
    EventBus.$emit('enableSelectedStores', false)

    // seleted Store 값이 없으면 Popup
    // if (!this.$store.getters['dataStore/IS_SELECTED_STORE']) {
    //   EventBus.$emit('openSelectedStores')
    // }

    // this.setCountry()

    if (this.selectedCity !== '') this.$refs.city.classList.add('is-complete')
  },
  methods: {

    selectItem (item) {
      console.log('Item selected: ' + item.name)
      this.selectedItem = item
    },

    // Event Listeners
    resetSelcted (arr) {
      return arr.map((el) => {
        el.selected = false
        return el
      })
    },
    async selectCustomerInfo (item, customerInfoList) {
      console.log(item)
      console.log(customerInfoList)

      // this.selectedCustomerInfo = {}
      this.LabelActionsList = await this.resetSelcted(customerInfoList)
      console.log(this.LabelActionsList.length)
      if (this.LabelActionsList.length !== 0) {
        item.selected = true
        this.selectedLabelActions = item
      }
    },

    async moveCustomerInfo (moveType, customerInfoList, selectedCustomerInfo) {
      const selectedIdx = customerInfoList.indexOf(selectedCustomerInfo)
      const total = customerInfoList.length
      switch (moveType) {
        case this.moveType.start:
          for (let i = 0; i < selectedIdx; i++) {
            commons.swap(customerInfoList, selectedIdx - i, selectedIdx - i - 1)
          }
          break
        case this.moveType.up:
          if (selectedIdx > 0) commons.swap(customerInfoList, selectedIdx, selectedIdx - 1)
          break
        case this.moveType.down:
          if (selectedIdx < total - 1) commons.swap(customerInfoList, selectedIdx, selectedIdx + 1)
          break
        case this.moveType.end:
          // for (let i = 0; i < total - 1; i++) {
          //   commons.swap(customerInfoList, selectedIdx + i, selectedIdx + i + 1)
          // }
          if (selectedIdx === 0) {
            for (let i = 0; i < total - 1; i++) {
              console.log(i)
              commons.swap(customerInfoList, selectedIdx + i, selectedIdx + i + 1)
            }
          } else {
            if (selectedIdx < total - 1) {
              for (let i = selectedIdx; i < total - 1; i++) {
                console.log(i)
                commons.swap(customerInfoList, i, i + 1)
              }
            }
          }
          break
        default:
          break
      }
      await this.$nextTick() // CustomerInfoList 배열 요소 위치이동 DOM 반영을 기다림
      this.syncCustomerInfoPriority() // 이동한 위치에 맞게 각 요소의 priority 변경.
    },

    // etc
    getRowClass: commons.getRowClass,
    syncCustomerInfoPriority () {
      // customerInfoList의 각 요소들의 priority값을 요소의 현재 위치+1로 맞춘다.
      this.LabelActionsList = this.LabelActionsList.map((info, idx) => {
        info.priority = idx + 1
        return info
      }) || []
    },
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    popupunassignChange (value) {
      console.log(value)
      this.popupunassignChange = value
    },
    popupassignsuccessChange (value) {
      this.popupassignsuccessChange = value
    },
    popupassignstartChange (value) {
      this.popupassignstartChange = value
    },
    batchassignmenuChange (value) {
      this.batchassignmenuChange = value
      console.log(this.batchassignmenuChange)
    },

    disableappsettings () {
      this.disableappsettingsbutton = true
    },

    enableappsettings () {
      this.disableappsettingsbutton = false
    },
    // # etc
    isValidResponse (response) {
      return response.data.responseCode === '200'
    },
    deleteExtraction (productIdExtraction) {
      commons.remove(this.productIdExtractionList, productIdExtraction)
    },
    // # EventHandlers
    // ## From children
    addNewExtraction (newExtraction) {
      console.log(newExtraction)
      this.productIdExtractionList = Array.from(
        new Set([...this.productIdExtractionList, newExtraction])
      )
      console.log(this.productIdExtractionList)
    },
    // ## Modal
    openAppConfigMsgBox () {
      this.msgModalVisible = true
    },
    closeAppConfigMsgBox () {
      this.msgModalVisible = false
    },
    // ## Save
    async handleSaveProductIdExtraction () {
      try {
        const productIdExtractionList = this.productIdExtractionList
        const user = this.user
        const putProductIdExtractionResult =
					await this.putProductIdExtractionList(productIdExtractionList, user)
        if (this.isValidResponse(putProductIdExtractionResult)) {
          this.openAppConfigMsgBox()
          const updatedProductIdExtractionList =
						await this.getProductIdExtractionList(user)
          console(updatedProductIdExtractionList)
          this.setProductIdExtractionList(updatedProductIdExtractionList)
          await this.$store.dispatch(
            'auth/updateProductIdExtractionList',
            updatedProductIdExtractionList
          )
          if (
            !updatedProductIdExtractionList ||
						updatedProductIdExtractionList.length < 1
          ) {
            // 저장하려는 Product ID extraction list가 없거나 빈 경우에는 disable.
            this.$store.dispatch('auth/updateProductIdExtractionEnable', false)
          } else {
            // 그 외에는 사용자가 설정한 대로 enable 혹은 disable.
            this.$store.dispatch(
              'auth/updateProductIdExtractionEnable',
              !this.productIdExtractionDisabled
            )
          }
        }
      } catch (error) {
        console.debug(
					`Faild to save Product ID Extraction setting. error: ${error}`
        )
      }
    },
    async handleSaveAddInfoConfigList () {
      try {
        const addInfoConfigList = this.addInfoConfigList
        let idx = 0
        for (const addInfoConfig of addInfoConfigList) {
          addInfoConfig.id = ++idx
          if (addInfoConfig.enable && !commons.isValidStr(addInfoConfig.name)) {
            addInfoConfig.enable = false
          }
        }
        if (addInfoConfigList.length > 6) addInfoConfigList.splice(6)
        const user = this.user
        const putAddInfoResult = await this.putAddInfo(addInfoConfigList, user)
        if (this.isValidResponse(putAddInfoResult)) {
          this.openAppConfigMsgBox()
          const updatedAddInfoConfigList = await this.getAddInfo(user)
          this.setAddInfoConfigList(updatedAddInfoConfigList)
          this.$store.dispatch(
            'auth/updateAddInfoConfigList',
            updatedAddInfoConfigList
          )
        }
      } catch (error) {
        console.debug(`Faild to save addInfoConfigList. error: ${error}`)
      }
    },
    async handleSaveEditableProductInfo () {
      try {
        const editableProductInfo = this.editableProductInfo.filter(
          (prodInfo) => !commons.isNull(prodInfo)
        )
        const user = this.user
        const putEditableProductInfoResult = await this.putEditableProductInfo(
          editableProductInfo,
          user
        )
        if (this.isValidResponse(putEditableProductInfoResult)) {
          this.openAppConfigMsgBox()
          const updatedEditableProductInfo = await this.getEditableProductInfo(
            user
          )
          this.setEditableProductInfo(updatedEditableProductInfo)
          this.$store.dispatch(
            'auth/updateEditableProductInfo',
            updatedEditableProductInfo
          )
        }
      } catch (error) {
        console.debug(`Faild to save editableProductInfo. error: ${error}`)
      }
    },
    // ## etc
    handleAddEditProdInfo () {
      this.editableProductInfo.push(null)
    },
    handleDelEditProdInfo (editProdInfo) {
      commons.remove(this.editableProductInfo, editProdInfo)
    },
    handleProdIdExtChange (prodIdExt) {
      console.log(prodIdExt)
      this.productIdExtractionDisabled = !prodIdExt
    },
    // ## Methods to initiate the App Config page.
    setProductIdExtractionList (productIdExtractionList) {
      if (productIdExtractionList?.length < 1) {
        this.productIdExtractionDisabled = true
      }
      this.productIdExtractionList = [...productIdExtractionList]
    },
    setAddInfoConfigList (addInfoConfigList) {
      console.log(addInfoConfigList)
      if (addInfoConfigList !== '') {
        const sortedAddInfoConfigList = addInfoConfigList.sort(
          (configA, configB) => {
            if (configA.id > configB.id) {
              return 1
            } else if (configA.id < configB.id) {
              return -1
            } else {
              return 0
            }
          }
        )
        this.addInfoConfigList = [...sortedAddInfoConfigList].slice(0, 6)
      } else {
        return this.addInfoConfigList
      }
    },
    setEditableProductInfo (editableProductInfo) {
      this.editableProductInfo = [...editableProductInfo]
    },
    setActivateFeatures (activateFeatures) {
      console.log(activateFeatures)
      this.batchassignmenu.val = false
      this.popupunassignChange.val = false
      this.popupassignstart[0].val = true
      this.popupassignsuccess[0].val = true
    },
    setProductKeys (productKeys) {
      this.productKeys = [...productKeys]
    },
    async initAppConfig (user) {
      try {
        if (!user.productIdExtraction) {
          this.setProductIdExtractionList([])
          this.productIdExtractionDisabled = true
        } else {
          this.setProductIdExtractionList(user.productIdExtraction.list)
          this.productIdExtractionDisabled = !user.productIdExtraction.enable
        }
        this.setAddInfoConfigList(user.addInfoConfigList)
        this.setEditableProductInfo(user.editableProductInfo)
        this.setActivateFeatures(user.activateFeatures)

        this.batchMode = this.$store.state.dataStore.activateFeatures.batchMode
        this.assignSuccess = this.$store.state.dataStore.activateFeatures.assignSuccess
        this.assignStart = this.$store.state.dataStore.activateFeatures.assignStart
        this.unassign = this.$store.state.dataStore.activateFeatures.unassign

        if (this.batchMode === 'false' || this.batchMode === false) {
          this.batchMode = 'Off'
        } else {
          this.batchMode = 'On'
        }
        if (this.assignSuccess === 'false' || this.assignSuccess === false) {
          this.assignSuccess = 'Off'
        } else {
          this.assignSuccess = 'On'
        }
        if (this.assignStart === 'false' || this.assignStart === false) {
          this.assignStart = 'Off'
        } else {
          this.assignStart = 'On'
        }
        if (this.unassign === 'false' || this.unassign === false) {
          this.unassign = 'Off'
        } else {
          this.unassign = 'On'
        }
        this.getProductKeys(this.user)
        // this.setProductKeys(await this.getProductKeys(this.user))
        // Disable buttons
        this.$store.dispatch('auth/getDisabledBtn', '9300').then((flag) => {
          if (this.permissionkey === 'store' && this.selectedStore === '') {
            this.appConfigSaveDisabled = true
          } else if (this.permissionkey === 'company' && this.selectedCompany === '') {
            this.appConfigSaveDisabled = true
          } else if (this.permissionkey === 'city' && this.selectedCity === '') {
            this.appConfigSaveDisabled = true
          } else if (this.permissionkey === 'country' && this.selectedCountry === '') {
            this.appConfigSaveDisabled = true
          } else if (this.permissionkey === 'region' && this.selectedRegion === '') {
            this.appConfigSaveDisabled = true
          } else {
            this.appConfigSaveDisabled = flag
          }
          // this.appConfigSaveDisabled = flag
        })
      } catch (error) {
        console.debug(
					`Not enough data to initialize AppConfig. error: ${error}`
        )
      }
    },
    // # API requests
    // ## ProductID Extraction
    getProductIdExtractionList (user) {
      const config = { params: {} }
      if (this.cloudMode) config.params.company = user.company
      return this.$utils
        .callAxios(
          codes.requests.getProductIdExtractionList.method,
          codes.requests.getProductIdExtractionList.url,
          config
        )
        .then((res) => res.data.productIdExtraction)

        .catch((error) => {
          console.warn(
						`Could not find any productIdExtraction. error: ${error}`
          )
        })
    },
    buildProductIdExtraction (productIdExtraction, productIdExtractionDisabled) {
      const productIdExtractionEnable = productIdExtraction?.length > 0 ? !productIdExtractionDisabled : false
      console.log(productIdExtractionEnable)
      productIdExtraction = productIdExtraction.map((el) => {
        el.enable = productIdExtractionEnable
        return el
      })
      console.log(productIdExtraction)

      return [{ productIdExtraction }]
    },
    putProductIdExtractionList (productIdExtraction, user) {
      const config = { params: {} }
      if (this.cloudMode) config.params.company = user.company
      const reqBody = this.buildProductIdExtraction(
        productIdExtraction,
        this.productIdExtractionDisabled
      )
      return this.$utils
        .callAxiosWithBody(
          codes.requests.putProductIdExtractionList.method,
          codes.requests.putProductIdExtractionList.url,
          reqBody,
          config
        )
        .then((res) => res)
        .catch((error) => {
          console.warn(`Failed to update productIdExtraction. error: ${error}`)
          return false
        })
    },
    // ## Extra Info
    getAddInfo (user) {
      const config = { params: {} }
      if (this.cloudMode) config.params.company = user.company
      return this.$utils
        .callAxios(
          codes.requests.getAddInfo.method,
          codes.requests.getAddInfo.url,
          config
        )
        .then((res) => res.data.addInfoConfigList)
        .catch((error) => {
          console.warn(
						`Could not find any productIdExtraction. error: ${error}`
          )
        })
    },
    buildAddInfoConfigList (addInfoConfigList) {
      return { addInfoConfigList }
    },
    putAddInfo (addInfoConfigList, user) {
      const config = { params: {} }
      if (this.cloudMode) config.params.company = user.company
      const reqBody = this.buildAddInfoConfigList(addInfoConfigList)
      return this.$utils
        .callAxiosWithBody(
          codes.requests.putAddInfo.method,
          codes.requests.putAddInfo.url,
          reqBody,
          config
        )
        .then((res) => res)
        .catch((error) => {
          console.warn(`Failed to update addInfoConfigList. error: ${error}`)
          return false
        })
    },
    // ## Editable ProductInfo
    getEditableProductInfo (user) {
      const config = { params: {} }
      if (this.cloudMode) {
        config.params.company = user.company
      }
      return this.$utils
        .callAxios(
          codes.requests.getEditableProductInfo.method,
          codes.requests.getEditableProductInfo.url,
          config
        )
        .then((res) => res.data.editableProductInfo)
        .catch((error) => {
          console.warn(
						`Could not find any editableProductInfo keys for a given store. error: ${error}`
          )
        })
    },
    buildEditableProductInfo (editableProductInfo) {
      return { editableProductInfo }
    },
    putEditableProductInfo (editableProductInfo, user) {
      console.log('editable')
      const config = { params: {} }
      if (this.cloudMode) config.params.company = user.company
      const reqBody = this.buildEditableProductInfo(editableProductInfo)
      return this.$utils
        .callAxiosWithBody(
          codes.requests.putEditableProductInfo.method,
          codes.requests.putEditableProductInfo.url,
          reqBody,
          config
        )
        .then((res) => res)
        .catch((error) => {
          console.warn(`Failed to update editableProductInfo. error: ${error}`)
          return false
        })
    },
    getProductKeys (user) {
      const config = { params: {} }
      if (this.cloudMode) {
        config.params.company = user.company
      }
      this.$utils
        .callAxios(
          codes.requests.getArticleDataKeyList.method,
          codes.requests.getArticleDataKeyList.url,
          config
        )
        .then((res) => {
          this.productKeys = res.data.articleDataKeyList
        })

        .catch((error) => {
          console.warn(
						`Could not find any article keys for a given store. storeCode: ${config.params.store}, error: ${error}`
          )
        })
    },

    // New Changes 4.3.1 release
    // Save all app config settings

    Tosaveappsettings () {
      try {
        var config
        if (this.Customer !== 'LIDL') {
          if (this.selectedStore.length > 0) {
            console.log('iffff')
            config = {
              params: {
                areaId: null,
                stationCode: this.areaid
              }
            }
          }
          if (this.selectedStore.length === 0 || this.selectedStore.length === undefined) {
            console.log('elseeee')
            config = {
              params: {
                areaId: this.areaid,
                stationCode: null
              }
            }
          }
        } else if (this.Customer === 'LIDL') {
          if (typeof (this.areaid) !== 'number') {
            config = {
              params: {
                areaId: null,
                stationCode: this.areaid
              }
            }
          } else {
            config = {
              params: {
                areaId: this.areaid,
                stationCode: null
              }
            }
          }
        }
        var data
        if (this.cloudMode) config.params.company = this.user.company
        if (this.productIdExtractionDisabled === true) {
          data = false
        }
        if (this.productIdExtractionDisabled === false) {
          data = true
        }

        const reqBody = {
          productIdExtraction: this.productIdExtractionList,
          enable: data
        }
        const reqBody1 = this.buildAddInfoConfigList(this.addInfoConfigList)
        console.log(reqBody1)

        const reqBody2 = this.buildEditableProductInfo(
          this.editableProductInfo
        )

        console.log(reqBody2)
        // Activate Features
        if (this.assignSuccess === 'On') {
          this.assignSuccess = 'true'
        } else {
          this.assignSuccess = 'false'
        }
        if (this.batchMode === 'On') {
          this.batchMode = 'true'
        } else {
          this.batchMode = 'false'
        }
        if (this.assignStart === 'On') {
          this.assignStart = 'true'
        } else {
          this.assignStart = 'false'
        }
        if (this.unassign === 'On') {
          this.unassign = 'true'
        } else {
          this.unassign = 'false'
        }

        //  LiDL PDA Functions

        // Features
        const features = {}
        for (let i = 0; i < this.FeaturesList.length; i++) {
          features[this.FeaturesList[i].name] = this.FeaturesList[i].enable
        }

        // SpecialFunctions
        const specialfunction = {}

        for (let j = 0; j < this.SpecialFunctionsList.length; j++) {
          specialfunction[this.SpecialFunctionsList[j].name] = this.SpecialFunctionsList[j].enable
        }

        // LabelActions

        const array = []
        const splitenabled = {}
        const customerInfoEnabled = {}
        const customerInfoShortCutEnabled = {}
        const customerInfoShortCutEnabled1 = {}
        const customerInfoShortCutEnabled2 = {}
        const customerInfoShortCutEnabled3 = {}
        const additionalLabelEnabled = {}
        if (this.Customer === 'LIDL') {
          for (const key of this.LabelActionsList) {
            if (key.name === 'splitEnabled') {
              splitenabled.id = key.id
              splitenabled[key.name] = key.enable
              splitenabled.priority = key.priority
              array.push(splitenabled)
            }
            if (key.name === 'customerInfoEnabled') {
              customerInfoEnabled.id = key.id
              customerInfoEnabled[key.name] = key.enable
              customerInfoEnabled.priority = key.priority
              array.push(customerInfoEnabled)
            }
            if (key.name === 'customerInfoShortCutEnabled1') {
              // const description1 = key.description.description
              if (key.enable === false) {
                key.description = ''
              }
              customerInfoShortCutEnabled1.id = key.id
              customerInfoShortCutEnabled1[key.name] = key.enable
              customerInfoShortCutEnabled1.priority = key.priority
              customerInfoShortCutEnabled1.description = key.description
              array.push(customerInfoShortCutEnabled1)
            }
            if (key.name === 'customerInfoShortCutEnabled2') {
              // const description2 = key.description.description
              if (key.enable === false) {
                key.description = ''
              }
              customerInfoShortCutEnabled2.id = key.id
              customerInfoShortCutEnabled2[key.name] = key.enable
              customerInfoShortCutEnabled2.priority = key.priority
              customerInfoShortCutEnabled2.description = key.description
              array.push(customerInfoShortCutEnabled2)
            }
            if (key.name === 'customerInfoShortCutEnabled3') {
              if (key.enable === false) {
                key.description = ''
              }
              // const description3 = key.description.description
              customerInfoShortCutEnabled3.id = key.id
              customerInfoShortCutEnabled3[key.name] = key.enable
              customerInfoShortCutEnabled3.priority = key.priority
              customerInfoShortCutEnabled3.description = key.description
              array.push(customerInfoShortCutEnabled3)
            }
            if (key.name === 'additionalLabelEnabled') {
              additionalLabelEnabled.id = key.id
              additionalLabelEnabled[key.name] = key.enable
              additionalLabelEnabled.priority = key.priority
              array.push(additionalLabelEnabled)
            }
          }
        } else {
          for (const key of this.LabelActionsList) {
            if (key.name === 'splitEnabled') {
              splitenabled.id = key.id
              splitenabled[key.name] = key.enable
              splitenabled.priority = key.priority
              array.push(splitenabled)
            }
            if (key.name === 'customerInfoEnabled') {
              customerInfoEnabled.id = key.id
              customerInfoEnabled[key.name] = key.enable
              customerInfoEnabled.priority = key.priority
              array.push(customerInfoEnabled)
            }
            if (key.name === 'customerInfoShortCutEnabled') {
              customerInfoShortCutEnabled.id = key.id
              customerInfoShortCutEnabled[key.name] = key.enable
              customerInfoShortCutEnabled.priority = key.priority
              array.push(customerInfoShortCutEnabled)
            }
            if (key.name === 'additionalLabelEnabled') {
              additionalLabelEnabled.id = key.id
              additionalLabelEnabled[key.name] = key.enable
              additionalLabelEnabled.priority = key.priority
              array.push(additionalLabelEnabled)
            }
          }
        }

        // End Here
        console.log(array)

        const body = {
          'Add Info Config': reqBody1,
          'Editable Product Info': reqBody2,
          'Product ID Extraction': reqBody,
          'Activate Features': {
            batchMode: this.batchMode,
            assignSuccess: this.assignSuccess,
            assignStart: this.assignStart,
            unassign: this.unassign
          },
          'LiDL PDA Functions': {
            Features: features,
            LabelActions: array,
            SpecialFunctions: specialfunction
          }
        }
        const params = {
          assignSuccess: this.assignSuccess,
          batchMode: this.batchMode,
          assignStart: this.assignStart,
          unassign: this.unassign
        }

        this.$utils
          .callAxiosWithBody(
            codes.requests.updateappconfig.method,
            codes.requests.updateappconfig.url,
            body,
            config
          )
          .then((res) => {
            console.log(res)
            if (res.status === 200) {
              this.openAppConfigMsgBox()
              this.togetapiconfig(this.areaid)
              this.$store.dispatch('dataStore/updateActivateFeatures', params)
              this.addNewConfig = false
              this.getExistingConfig(this.areaparamlist[0].areaId, 1)
              // this.getExistingConfig(this.FinalAreaId, 1)
            } else {
              console.warn('Failed to update')
            }
          })
      } catch (error) {
        console.warn(`Failed to update. error: ${error}`)
        return false
      }
    },

    async togetapiconfig (id) {
      console.log(id)
      this.areaid = id
      try {
        var config
        if (this.Customer !== 'LIDL') {
          if (!this.changeStoreActionFlag) {
            if (this.permissionkey === 'store') {
              config = {
                params: {
                  areaId: null,
                  stationCode: id
                }
              }
            } else {
              if (this.permissionkey === null) {
                config = {
                  params: {
                    areaId: null,
                    stationCode: null
                  }
                }
              } else {
                config = {
                  params: {
                    areaId: id,
                    stationCode: null
                  }
                }
              }
            }
          } else {
            if (this.selectedStore.length > 0) {
              console.log('iffff')
              config = {
                params: {
                  areaId: null,
                  stationCode: id
                }
              }
            }
            if (this.selectedStore.length === 0 || this.selectedStore.length === undefined) {
              console.log('elseeee')
              config = {
                params: {
                  areaId: id,
                  stationCode: null
                }
              }
            }
          }
        } else if (this.Customer === 'LIDL') { // LIDL
          if (typeof (id) !== 'number') {
            console.log('iffff')
            config = {
              params: {
                areaId: null,
                stationCode: id
              }
            }
          } else {
            console.log('elseeeeyhjhjhjh')
            config = {
              params: {
                areaId: id,
                stationCode: null
              }
            }
          }
        }// END LIDL
        if (this.cloudMode) config.params.company = this.user.company
        console.log(config)
        // if(this.selectedStore)
        this.$utils
          .callAxios(
            codes.requests.getappconfig.method,
            codes.requests.getappconfig.url,
            config
          )
          .then((res) => {
            console.log(res)
            console.log(res.responseCode)

            if (res.status === 204) {
              // Lida Pda Features

              const LabelActionsList = [
                { id: 1, name: 'splitEnabled', enable: true, priority: '1' },
                { id: 2, name: 'customerInfoEnabled', enable: false, priority: '2' },
                { id: 3, name: 'additionalLabelEnabled', enable: false, priority: '3' },
                { id: 4, name: 'customerInfoShortCutEnabled', enable: false, priority: '4' }
              ]
              const LabelActionsListLIDL = [
                { id: 1, name: 'splitEnabled', enable: true, priority: '1', description: '' },
                { id: 2, name: 'customerInfoEnabled', enable: false, priority: '2', description: '' },
                { id: 3, name: 'additionalLabelEnabled', enable: false, priority: '3', description: '' },
                { id: 4, name: 'customerInfoShortCutEnabled1', enable: false, priority: '4', description: '' },
                { id: 5, name: 'customerInfoShortCutEnabled2', enable: false, priority: '5', description: '' },
                { id: 6, name: 'customerInfoShortCutEnabled3', enable: false, priority: '6', description: '' }
              ]
              if (this.Customer === 'LIDL') {
                this.LabelActionsList = LabelActionsListLIDL
              } else {
                this.LabelActionsList = LabelActionsList
              }

              const FeaturesList = [
                { name: 'deleteLabel', enable: true },
                { name: 'RMA', enable: false },
                { name: 'ScanWithoutConfirmation', enable: false }
              ]
              this.FeaturesList = FeaturesList

              const SpecialFunctionsList = [
                { name: 'SSCEnabled', enable: true },
                { name: 'RollingEnabled', enable: false },
                { name: 'WateringEnabled', enable: false },
                { name: 'FreshnessEnabled', enable: false }
              ]
              this.SpecialFunctionsList = SpecialFunctionsList

              // End

              this.batchMode = ''
              this.assignSuccess = ''
              this.assignStart = ''
              this.unassign = ''
              // // Editableifo
              const editProdInfor = ''
              this.$store.dispatch(
                'auth/updateEditableProductInfo',
                editProdInfor
              )
              // Addinfo
              const addinfo = ''
              this.$store.dispatch('auth/updateAddInfoConfigList', addinfo)
              const obj = [
                { id: 1, name: 'Arrow', enable: true },
                { id: 2, name: 'Below', enable: false },
                { id: 3, name: '', enable: false },
                { id: 4, name: '', enable: false },
                { id: 5, name: '', enable: false }

              ]
              const sortedAddInfoConfigList = obj.sort(
                (configA, configB) => {
                  if (configA.id > configB.id) {
                    return 1
                  } else if (configA.id < configB.id) {
                    return -1
                  } else {
                    return 0
                  }
                }
              )
              this.addInfoConfigList = [...sortedAddInfoConfigList].slice(0, 6)

              // productIDextraction
              const productIdExtractiondata = ''
              this.$store.dispatch(
                'auth/updateProductIdExtractionList',
                productIdExtractiondata
              )
              this.setProductIdExtractionList(productIdExtractiondata)
              this.setAddInfoConfigList(addinfo)
              // const updatedAddInfoConfigList = this.getAddInfo(this.user)
              // this.setAddInfoConfigList(updatedAddInfoConfigList)
              this.setEditableProductInfo(editProdInfor)
            } else {
              // Lidl PDA Functions
              if (res.data['LiDL PDA Functions'] !== undefined) {
                console.log(res.data['LiDL PDA Functions'].LabelActions)
                // Label Actios
                if (res.data['LiDL PDA Functions'].LabelActions !== undefined || res.data['LiDL PDA Functions'].LabelActions !== []) {
                  const LabelAction = res.data['LiDL PDA Functions'].LabelActions
                  const array = [
                    { id: 4, customerInfoShortCutEnabled1: false, priority: '4', description: '' },
                    { id: 5, customerInfoShortCutEnabled2: false, priority: '5', description: '' },
                    { id: 6, customerInfoShortCutEnabled3: false, priority: '6', description: '' }
                  ]
                  if (this.Customer === 'LIDL') {
                    if (LabelAction.length <= 4) {
                      LabelAction.push(...array)
                    }
                  }
                  const labelactionobj = Object.entries(LabelAction).map(([k, v]) => {
                    const obj1 = Object.keys(v)
                    const obj2 = Object.values(v)
                    var obj = {}
                    if (this.Customer === 'LIDL') {
                      obj =
												{ id: v.id, name: obj1[1], enable: JSON.parse(String(obj2[1]).toLowerCase()), priority: v.priority, description: v.description }
                    } else {
                      obj =
												{ id: v.id, name: obj1[1], enable: obj2[1], priority: v.priority }
                    }
                    return obj
                  })
                  if (labelactionobj !== '') {
                    this.LabelActionsList = labelactionobj
                  }
                }
                //  SpecialFunctions
                if (res.data['LiDL PDA Functions'].SpecialFunctions !== undefined) {
                  const Specialfuntios = res.data['LiDL PDA Functions'].SpecialFunctions
                  const specialfunobj = Object.entries(Specialfuntios).map(([k, v]) => {
                    var obj = {
                      name: k,
                      enable: JSON.parse(String(v).toLowerCase())
                    }
                    return obj
                  })
                  this.SpecialFunctionsList = specialfunobj
                }

                // Features
                if (res.data['LiDL PDA Functions'].Features !== undefined) {
                  var FeaturesList = res.data['LiDL PDA Functions'].Features

                  // LiDL Features added
                  if (!('ScanWithoutConfirmation' in FeaturesList)) {
                    if (this.Customer === 'LIDL') {
                      FeaturesList.ScanWithoutConfirmation = false
                    }
                  }

                  const FeaturesObj = Object.entries(FeaturesList).map(([k, v]) => {
                    var obj = {
                      name: k,
                      enable: JSON.parse(String(v).toLowerCase())
                    }
                    return obj
                  })
                  this.FeaturesList = FeaturesObj
                }
              }
              // End

              if (res.data['Product ID Extraction'] !== undefined) {
                console.log(res.data['Product ID Extraction'].productIdExtraction)
                console.log(res.data['Product ID Extraction'].enable)
                const productIdExtractiondata = res.data['Product ID Extraction'].productIdExtraction
                this.productIdExtractionDisabled = !res.data['Product ID Extraction'].enable
                // productIDextraction
                this.$store.dispatch(
                  'auth/updateProductIdExtractionList',
                  productIdExtractiondata
                )
                if (
                  !productIdExtractiondata ||
									productIdExtractiondata.length < 1
                ) {
                  // 저장하려는 Product ID extraction list가 없거나 빈 경우에는 disable.
                  this.$store.dispatch(
                    'auth/updateProductIdExtractionEnable',
                    false
                  )
                } else {
                  // 그 외에는 사용자가 설정한 대로 enable 혹은 disable.
                  this.$store.dispatch(
                    'auth/updateProductIdExtractionEnable',
                    !this.productIdExtractionDisabled
                  )
                }
                this.setProductIdExtractionList(productIdExtractiondata)
              } else {
                // productIDextraction
                const productIdExtractiondata = ''
                this.$store.dispatch(
                  'auth/updateProductIdExtractionList',
                  productIdExtractiondata
                )
                this.setProductIdExtractionList(productIdExtractiondata)
              }

              /// End

              // Editableifo
              const editProdInfor =
								res.data['Editable Product Info'].editableProductInfo
              this.$store.dispatch(
                'auth/updateEditableProductInfo',
                editProdInfor
              )
              // end
              // Addinfo
              const addinfo = res.data['Add Info Config'].addInfoConfigList
              this.$store.dispatch('auth/updateAddInfoConfigList', addinfo)
              // end

              // Actvate features

              this.batchMode = res.data['Activate Features'].batchMode
              this.assignSuccess = res.data['Activate Features'].assignSuccess
              this.assignStart = res.data['Activate Features'].assignStart
              this.unassign = res.data['Activate Features'].unassign
              if (this.batchMode === 'false' || this.batchMode === false) {
                this.batchMode = 'Off'
              } else {
                this.batchMode = 'On'
              }
              if (
                this.assignSuccess === 'false' ||
								this.assignSuccess === false
              ) {
                this.assignSuccess = 'Off'
              } else {
                this.assignSuccess = 'On'
              }
              if (
                this.assignStart === 'false' ||
								this.assignStart === false
              ) {
                this.assignStart = 'Off'
              } else {
                this.assignStart = 'On'
              }
              if (
                this.unassign === 'false' ||
								this.unassign === false
              ) {
                this.unassign = 'Off'
              } else {
                this.unassign = 'On'
              }

              // end

              this.setAddInfoConfigList(addinfo)
              this.setEditableProductInfo(editProdInfor)
            }
          })
      } catch (error) {
        console.warn(`Could not find any productIdExtraction. error: ${error}`)
      }
    },

    togetareaparam () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          codes.requests.getAreas.method,
          codes.requests.getAreas.url,
          config
        )
        .then((res) => {
          console.log(res)
          this.areaparamlist = res.data.areaParamList
          console.log(this.areaparamlist)
          this.changeedCompany()
          if (this.permissionkey === 'store') {
            this.changeedCompany()
            this.changeedCountry()
            this.changeedRegion()
            this.changeedCity()
            this.changeedStore(this.storeItems[0].code)
          }
          if (this.permissionkey === 'country') {
            this.changeedCompany()
            this.changeedCountry()
          }
          if (this.permissionkey === 'region') {
            this.changeedCompany()
            this.changeedCountry()
            this.changeedRegion()
          }
          if (this.permissionkey === 'city') {
            this.changeedCompany()
            this.changeedCountry()
            this.changeedRegion()
            this.changeedCity()
          }
          if (this.Customer === 'LIDL') {
            // LIDL Requirements
            // to get Existing Config

            // this.getExistingConfig(this.areaparamlist[0].areaId, this.page)
            this.getExistingConfig(this.FinalAreaId, this.page)

            //
          }
        })
    },
    Flagchange (data) {
      this.flagdata = data
      if (data === 'country') {
        this.selectedRegion = ''
        this.regionItems = []
        this.setRegion()
      }
      if (data === 'region') {
        this.selectedCity = ''
        this.cityItems = []
        this.setCity()
      }
      if (data === 'city') {
        this.selectedStore = ''
        this.storeItems = []
        this.setStore()
      }
    },
    changeedCompany () {
      const obj = [
        {
          city: '',
          company: this.selectedCompany,
          country: '',
          region: ''
        }
      ]
      this.countrylist = obj
      const result1 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
						o1.country === o2.country &&
						o1.region === o2.region &&
						o1.city === o2.city
          ) // id is unnique both array object
        })
      })
      console.log(result1.length)
      this.areaidforcompany = result1[0].areaId
      if (this.permissionkey === 'company') {
        if (result1.length !== 0) {
          if (this.Customer !== 'LIDL') {
            this.togetapiconfig(this.areaidforcompany)
          }
        } else {
          if (this.Customer !== 'LIDL') {
            this.togetapiconfig(this.areaidforcompany)
          }
        }
      }

      // LIDL
      if (result1.length !== 0) {
        this.FinalAreaId = this.areaidforcompany
      } else {
        this.FinalAreaId = this.areaidforcompany
      }
      console.log(result1)
      console.log(this.selectedCompany)
      console.log(this.areaidforcompany)
      console.log(this.FinalAreaId)
      // END
      this.selectedCountry = ''
      this.selectedRegion = ''
      this.selectedCity = ''
      this.selectedStore = ''
      this.regionItems = []
      this.cityItems = []
      this.storeItems = []
      this.setCountry()
    },
    changeedCountry () {
      if (this.btndisabled) {
        this.appConfigSaveDisabled = this.btndisabled
      } else {
        if (this.permissionkey === 'country' && this.selectedCountry !== '' && this.selectedCountry !== undefined) {
          this.appConfigSaveDisabled = false
        } else {
          if (this.permissionkey === 'company') {
            this.appConfigSaveDisabled = false
          } else {
            this.appConfigSaveDisabled = true
          }
        }
      }
      console.log(this.selectedCompany)
      console.log(this.selectedCountry)
      const obj = [
        {
          city: '',
          company: this.selectedCompany,
          country: this.selectedCountry,
          region: ''
        }
      ]
      this.countrylist = obj
      console.log(this.countrylist)
      console.log(this.areaparamlist)
      const result1 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
						o1.country === o2.country &&
						o1.region === o2.region &&
						o1.city === o2.city
          ) // id is unnique both array object
        })
      })
      if (result1.length !== 0) {
        this.areaidforcountry = result1[0].areaId
        console.log(this.areaidforcountry)
        if (this.Customer !== 'LIDL') {
          this.togetapiconfig(this.areaidforcountry)
        }
      } else {
        if (this.Customer !== 'LIDL') {
          this.togetapiconfig(this.areaidforcompany)
        }
      }
      // LIDL
      if (result1.length !== 0) {
        this.FinalAreaId = this.areaidforcountry
      } else {
        this.FinalAreaId = this.areaidforcompany
      }
      // END
      this.selectedRegion = ''
      this.selectedCity = ''
      this.selectedStore = ''
      this.regionItems = []
      this.cityItems = []
      this.storeItems = []
      this.setRegion()
    },
    changeedRegion () {
      console.log(this.selectedCompany)
      console.log(this.selectedCountry)
      console.log(this.selectedRegion)
      if (this.btndisabled) {
        this.appConfigSaveDisabled = this.btndisabled
      } else {
        if (this.permissionkey === 'region' && this.selectedRegion !== '' && this.selectedRegion !== undefined) {
          this.appConfigSaveDisabled = false
        } else {
          if (this.permissionkey === 'company' || this.permissionkey === 'country') {
            this.appConfigSaveDisabled = false
          } else {
            this.appConfigSaveDisabled = true
          }
        }
      }
      const obj = [
        {
          city: '',
          company: this.selectedCompany,
          country: this.selectedCountry,
          region: this.selectedRegion
        }
      ]

      this.countrylist = obj
      console.log(this.countrylist)
      console.log(this.areaparamlist)
      const result2 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
						o1.country === o2.country &&
						o1.region === o2.region &&
						o1.city === o2.city
          ) // id is unnique both array object
        })
      })
      // if (this.permissionkey === 'region') {
      if (result2.length !== 0) {
        console.log(result2)
        this.areaidforregion = result2[0].areaId
        console.log(this.areaidforregion)
        if (this.Customer !== 'LIDL') {
          this.togetapiconfig(this.areaidforregion)
        }
      } else {
        if (this.Customer !== 'LIDL') {
          this.togetapiconfig(this.areaidforcountry)
        }
      }
      // }
      // LIDL
      if (result2.length !== 0) {
        this.FinalAreaId = this.areaidforregion
      } else {
        this.FinalAreaId = this.areaidforcountry
      }
      // END

      this.selectedCity = ''
      this.selectedStore = ''
      this.cityItems = []
      this.storeItems = []
      this.setCity()
    },
    changeedCity () {
      console.log(this.selectedCompany)
      console.log(this.selectedCountry)
      if (this.btndisabled) {
        this.appConfigSaveDisabled = this.btndisabled
      } else {
        if (this.permissionkey === 'city' && this.selectedCity !== '' && this.selectedCity !== undefined) {
          this.appConfigSaveDisabled = false
        } else {
          console.log('this.permissionkey' + this.permissionkey)
          if (this.permissionkey === 'company' || this.permissionkey === 'region' || this.permissionkey === 'country') {
            this.appConfigSaveDisabled = false
          } else {
            this.appConfigSaveDisabled = true
          }
        }
      }

      const obj = [
        {
          city: this.selectedCity,
          company: this.selectedCompany,
          country: this.selectedCountry,
          region: this.selectedRegion
        }
      ]

      this.countrylist = obj
      console.log(this.countrylist)
      console.log(this.areaparamlist)
      const result3 = this.areaparamlist.filter(function (o1) {
        return obj.some(function (o2) {
          return (
            o1.company === o2.company &&
						o1.country === o2.country &&
						o1.region === o2.region &&
						o1.city === o2.city
          ) // id is unnique both array object
        })
      })

      if (result3.length !== 0) {
        console.log(result3)
        this.areaidforcity = result3[0].areaId
        console.log(this.areaidforcity)
        if (this.Customer !== 'LIDL') {
          this.togetapiconfig(this.areaidforcity)
        }
      } else {
        if (this.Customer !== 'LIDL') {
          this.togetapiconfig(this.areaidforregion)
        }
      }
      if (result3.length !== 0) {
        this.FinalAreaId = this.areaidforcity
      } else {
        this.FinalAreaId = this.areaidforregion
      }
      // END
      this.selectedStore = ''
      this.storeItems = []
      this.setStore()
    },
    changeedStore (code) {
      this.changeStoreActionFlag = true
      this.selectedStore = code
      if (this.btndisabled) {
        this.appConfigSaveDisabled = this.btndisabled
      } else {
        if (this.permissionkey === 'store' && this.selectedStore !== '' && this.selectedStore !== undefined) {
          this.appConfigSaveDisabled = false
        } else {
          if (this.permissionkey === 'company' || this.permissionkey === 'region' || this.permissionkey === 'city' || this.permissionkey === 'country') {
            this.appConfigSaveDisabled = false
          } else {
            this.appConfigSaveDisabled = true
          }
        }
      }
      if (this.Customer !== 'LIDL') {
        this.togetapiconfig(this.selectedStore)
      }
      // LIDL
      this.FinalAreaId = this.selectedStore
      // END
    },
    saveRequestConfig: function (config) {
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: config.params
      }
      this.requestConfig = requestConfig
    },
    async setCompany () {
      this.searchStoreItems = this.$store.state.dataStore.managedStores
      console.log(this.searchStoreItems)

      this.searchStoreItems.forEach((store) => {
        if (this.companyItems.indexOf(store.company) === -1) {
          this.companyItems.push(store.company)
        }
      })
      if (!commons.isNull(this.companyItems) && this.companyItems.length > 0) {
        this.selectedCompany = this.companyItems.filter(
          (comp) => !commons.isNull(comp)
        )[0]
      } else {
        let fallBackCompany = this.$store.state.auth.user.company
        this.companyItems.push(fallBackCompany)
        this.selectedCompany = fallBackCompany
        if (commons.isNull(fallBackCompany)) {
          fallBackCompany = await this.$utils.getCompany(
            this.$store.state.auth.user.company
          )
        }
        this.companyItems.push(fallBackCompany)
        this.selectedCompany = fallBackCompany
        console.log(this.selectedCompany)
      }
    },
    setCountry () {
      this.countryItems = []
      this.searchStoreItems.forEach((row) => {
        if (this.countryItems.indexOf(row.country) === -1) {
          if (this.selectedCompany === row.company) {
            this.countryItems.push(row.country)
          }
        }
      })

      // Store
      if (this.permissionkey !== 'company') {
        if (!commons.isNull(this.countryItems) && this.countryItems.length > 0) {
          this.selectedCountry = this.countryItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
    },
    setRegion () {
      this.searchStoreItems.forEach((row) => {
        if (this.regionItems.indexOf(row.region) === -1) {
          if (this.selectedCountry === row.country) {
            this.regionItems.push(row.region)
          }
        }
      })

      // Store
      if (this.permissionkey !== 'country') {
        if (!commons.isNull(this.regionItems) && this.regionItems.length > 0) {
          this.selectedRegion = this.regionItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
      if (this.flagdata === 'country') {
        this.selectedRegion = ''
      }
    },
    setCity () {
      this.searchStoreItems.forEach((row) => {
        if (this.cityItems.indexOf(row.city) === -1) {
          if (this.selectedRegion === row.region) {
            this.cityItems.push(row.city)
          }
        }
      })

      // Store
      if (this.permissionkey !== 'region') {
        if (!commons.isNull(this.cityItems) && this.cityItems.length > 0) {
          this.selectedCity = this.cityItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
      if (this.flagdata === 'region') {
        this.selectedCity = ''
      }
    },
    setStore () {
      this.searchStoreItems.forEach((row) => {
        if (this.selectedCity.indexOf(row.city) !== -1) {
          this.storeItems.push({ code: row.code, name: row.name })
        }
        console.log(this.storeItems)
      })

      // Store
      if (this.permissionkey === 'store') {
        if (!commons.isNull(this.storeItems) && this.storeItems.length > 0) {
          this.selectedStore = this.storeItems.filter(
            (comp) => !commons.isNull(comp)
          )[0]
        }
      }
      if (this.flagdata === 'city') {
        this.selectedStore = ''
      }
    },

    deleteappsettings () {
      try {
        var config
        if (this.selectedStore.length > 0) {
          console.log('iffff')
          config = {
            params: {
              areaId: null,
              stationCode: this.areaid
            }
          }
        }
        if (this.selectedStore.length === 0 || this.selectedStore.length === undefined) {
          console.log('elseeee')
          config = {
            params: {
              areaId: this.areaid,
              stationCode: null
            }
          }
        }
        console.log(this.areaid)
        this.todeletedcustomerinfo(this.areaid)
        this.todeletefavouritebutton(this.areaid)
        this.$utils
          .callAxios(
            codes.requests.deleteappconfig.method,
            codes.requests.deleteappconfig.url,
            config
          )
          .then((res) => {
            console.log(res)
            if (res.status === 200) {
              this.openAppConfigMsgBox()
              this.togetapiconfig(this.areaid)
            } else {
              console.warn('Failed to update')
            }
          })
      } catch (error) {
        console.warn(`Failed to update. error: ${error}`)
        return false
      }
    },
    async todeletedcustomerinfo (data) {
      // const = []
      const Id = JSON.parse(localStorage.getItem('customerinfoids'))
      const Ids = Id.map(id => id)
      const config = { params: {} }
      if (Ids !== '' || Ids !== undefined) {
        for await (const id of Ids) {
          const url = `${codes.requests.deleteCustomerInfo.url}/${id}`
          this.$utils
            .callAxios(codes.requests.deleteCustomerInfo.method, url, config)
            .then(res => {
              console.log(res)
              this.reloadcust = true
            })
            .catch(error => {
              EventBus.$emit(
                'messageAlert',
                this.$t(error.response.data.responseMessage)
              )
            })
        }
      }
    },

    async todeletefavouritebutton (data) {
      const Id = JSON.parse(localStorage.getItem('favoriteids'))
      const Ids = Id.map(id => id)
      const config = { params: {} }
      if (Ids !== '' || Ids !== undefined) {
        for await (const id of Ids) {
          const url = `${codes.requests.deleteFavoriteButtonList.url}/${id}`
          this.$utils
            .callAxios(codes.requests.deleteFavoriteButtonList.method, url, config)
            .then(res => {
              console.log(res)
              this.reloadfav = true
              // this.togetapiconfig(this.areaid)
            })
            .catch(error => {
              EventBus.$emit(
                'messageAlert',
                this.$t(error.response.data.responseMessage)
              )
            })
        }
      }
    },
    AddNewConfigpopup () {
      this.toSelectLevel = true
      this.changeedCompany()
      if (this.permissionkey === 'store') {
        this.changeedCompany()
        this.changeedCountry()
        this.changeedRegion()
        this.changeedCity()
        this.changeedStore(this.storeItems[0].code)
      }
      if (this.permissionkey === 'country') {
        this.changeedCompany()
        this.changeedCountry()
      }
      if (this.permissionkey === 'region') {
        this.changeedCompany()
        this.changeedCountry()
        this.changeedRegion()
      }
      if (this.permissionkey === 'city') {
        this.changeedCompany()
        this.changeedCountry()
        this.changeedRegion()
        this.changeedCity()
      }
    },

    // To delete Existing Config
    deleteExistingConfig () {
      console.log(this.selectedconfig)
      var forDeleteConfig = []
      var obj = {}
      for (let i = 0; i < this.selectedconfig.length; i++) {
        for (const prop in this.selectedconfig[i]) {
          if (this.selectedconfig[i][prop] !== '' && this.selectedconfig[i].stationcode === '*') {
            obj = {
              areaId: this.selectedconfig[i].id,
              stationCode: null
            }
          } else {
            obj = {
              areaId: null,
              stationCode: this.selectedconfig[i].stationcode
            }
          }
        }
        forDeleteConfig.push(obj)
      }

      const config = {
        data: forDeleteConfig,
        params: {}
      }
      this.$utils
        .callAxios(
          deleteexistConfig.method,
          deleteexistConfig.url,
          config
        )
        .then((res) => {
          console.log(res)
          EventBus.$emit(
            'messageAlert',
            this.$t(res.data.responseMessage)
          )
          this.selectedconfig = []
          // this.getExistingConfig(this.areaparamlist[0].areaId, this.page)
          this.getExistingConfig(this.FinalAreaId, this.page)
        }).catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage)
          )
          this.selectedconfig = []
          // this.getExistingConfig(this.areaparamlist[0].areaId, this.page)
          this.getExistingConfig(this.FinalAreaId, this.page)
        })
    },
    closeSelectareaPopup () {
      this.toSelectLevel = false
    },
    toSelectareaLevel () {
      console.log(this.FinalAreaId)
      this.togetapiconfig(this.FinalAreaId)
      this.getCustomerInfo(this.FinalAreaId)
      this.reloadfav = true
      this.toSelectLevel = false
      this.addNewConfig = true
    },
    getExistingConfig (value, page) {
      var valuetype = typeof (value)
      var config
      if (valuetype === 'number') {
        config = {
          params: {
            areaId: value,
            page: page - 1,
            size: this.rowPerPage,
            sort: 'area_id,ASC'
          }
        }
      } else {
        config = {
          params: {
            stationCode: value,
            page: page - 1,
            size: this.rowPerPage,
            sort: 'area_id,ASC'
          }
        }
      }

      this.$utils
        .callAxios(
          getexistConfig.method,
          getexistConfig.url,
          config
        )
        .then((res) => {
          console.log(res.status)
          if (res.status === 200) {
            this.existingConfig = res.data
            for (let i = 0; i < this.existingConfig.length; i++) {
              for (const prop in this.existingConfig[i]) {
                if (this.existingConfig[i][prop] === null || this.existingConfig[i][prop] === '') {
                  this.existingConfig[i][prop] = '*'
                  this.existingConfig[i].indexVal = i
                }
              }
            }
            this.pageInfoText = commons.getPageInfoText(res.headers)
            this.totalPages = Number(res.headers['x-totalpages'])
            this.pageIndex = res.headers['x-number'] * 1 + 1
            this.pageCount = res.headers['x-totalpages'] * 1
          } else {
            this.existingConfig = []
            const headers = {}
            headers['x-totalelements'] = 0
            this.pageInfoText = commons.getPageInfoText(headers)
            this.totalPages = 0
          }
        }).catch(error => {
          if (error.response.data.status === 404) {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.error)
            )
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
          }
        })
    },
    paging (page) {
      // this.getExistingConfig(this.areaparamlist[0].areaId, page)
      this.getExistingConfig(this.FinalAreaId, page)
    },
    updatePage (event) {
      this.rowPerPage = event.toString()
      this.paging(1)
    },
    editExistConfig (row) {
      // Lida Pda Features
      const LabelActionsList = [
        { id: 1, name: 'splitEnabled', enable: true, priority: '1' },
        { id: 2, name: 'customerInfoEnabled', enable: false, priority: '2' },
        { id: 3, name: 'additionalLabelEnabled', enable: false, priority: '3' },
        { id: 4, name: 'customerInfoShortCutEnabled', enable: false, priority: '4' }
      ]
      const LabelActionsListLIDL = [
        { id: 1, name: 'splitEnabled', enable: true, priority: '1', description: '' },
        { id: 2, name: 'customerInfoEnabled', enable: false, priority: '2', description: '' },
        { id: 3, name: 'additionalLabelEnabled', enable: false, priority: '3', description: '' },
        { id: 4, name: 'customerInfoShortCutEnabled1', enable: false, priority: '4', description: '' },
        { id: 5, name: 'customerInfoShortCutEnabled2', enable: false, priority: '5', description: '' },
        { id: 6, name: 'customerInfoShortCutEnabled3', enable: false, priority: '6', description: '' }
      ]
      if (this.Customer === 'LIDL') {
        this.LabelActionsList = LabelActionsListLIDL
      } else {
        this.LabelActionsList = LabelActionsList
      }
      const FeaturesList = [
        { name: 'deleteLabel', enable: true },
        { name: 'RMA', enable: false },
        { name: 'ScanWithoutConfirmation', enable: false }
      ]
      this.FeaturesList = FeaturesList
      const SpecialFunctionsList = [
        { name: 'SSCEnabled', enable: true },
        { name: 'RollingEnabled', enable: false },
        { name: 'WateringEnabled', enable: false },
        { name: 'FreshnessEnabled', enable: false }
      ]
      this.SpecialFunctionsList = SpecialFunctionsList

      if (row.stationcode !== '*') {
        this.togetapiconfig(row.stationcode)
        this.getCustomerInfo(row.stationcode)
      } else {
        this.togetapiconfig(row.id)
        this.getCustomerInfo(row.id)
      }
      this.toSelectLevel = false
      this.addNewConfig = true
    },
    //  TO Get CUSTOMER INFO API
    getCustomerInfo (data) {
      console.log(typeof (data))
      const valuetype = typeof (data)
      console.log(valuetype)
      var config
      if (valuetype === 'number' || valuetype === undefined) {
        config = {
          params: {
            // usage, // Default || Bakery
            contents: 'true',
            stationCode: null,
            areaId: data

          }
        }
      } else {
        config = {
          params: {
            // usage, // Default || Bakery
            contents: 'true',
            stationCode: data,
            areaId: null

          }
        }
      }

      return this.$utils
        .callAxios(
          codes.requests.getCustomerInfo.method,
					`${codes.requests.getCustomerInfo.url}`,
					config
        )
        .then((res) => {
          console.log(res.data)
          this.customerInfoLabelActions = res.data.customerInfoList
          console.log(this.customerInfoLabelActions)

          // return res.data.customerInfoList
        })
        .catch((error) => {
          console.debug(`Could not find customer information. error: ${error}`)
        })
    }
  }
}
</script>

<style scoped>
	.flexBox {
		display: flex;
		justify-content: space-between;
		max-width: 970px;
	}

	.flexBox > div {
		width: calc((100% - 40px) / 2);
	}

	.select_store_box {
		display: flex;
		min-height: 40px;
		border: none !important;
		overflow: hidden;
	}

	.select_store_box .select_article {
		display: flex;
		justify-content: start !important;
		align-items: center;
		width: 27% !important;
		font-size: var(--font17);
		background: transparent !important;
		color: #4d4f5c;
		text-align: center;
		font-weight: bold !important;
	}

	.select_store_box .forms-area {
		display: flex;
		/* align-items: center; */
		width: 60%;
		font-size: var(--font16);
		border: 1px solid #808495;
		border-radius: 5px;
		color: #808495;
		overflow-y: auto;
	}

	.select_store_box .select_article.is-complete {
		/* background: #001e38; */
		/* color: #fff; */
	}

	.v-list-item-group .v-list-item--active {
		color: white;
		background: #001e38;
		width: 200px;
    text-align: left !important;
	}
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
    text-align: left !important;
}

	.tableclass tr.selectedRow {
		color: black !important;
		background: #ececec;
		width: 200px;
	}

	.tableclass tr.selectedRow:hover {
		color: black !important;
		background: #ececec;
	}

	.className {
		pointer-events: none;
		opacity: 0.5;
	}

	.pageAlig {
		margin-top: 0px !important;
	}
</style>
<style>
.LedPatternClass + input{
  display: contents !important;
}
</style>
