var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("App Settings")) + " ")
      ]),
      _vm.Customer !== "LIDL"
        ? _c(
            "v-row",
            { staticClass: "mt-12" },
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c(
                  "h3",
                  {
                    staticClass: "page-sub-title-bar",
                    staticStyle: { "font-weight": "bold" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("AREA LIST")) + " ")]
                ),
                _c("div", { staticClass: "select_store_box mt-5" }, [
                  _c("h4", { staticClass: "select_article is-complete" }, [
                    _vm._v(_vm._s(_vm.$t("Company")))
                  ]),
                  _c(
                    "div",
                    { staticClass: "forms-area" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.companyItems,
                          label: "" + _vm.$t("Select the company"),
                          "item-text": "companyItems.company",
                          "item-value": "companyItems.company",
                          disabled: _vm.disabledCompany,
                          "clearable:false": "",
                          dense: "",
                          flat: "",
                          "hide-details": "",
                          solo: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeedCompany()
                          }
                        },
                        model: {
                          value: _vm.selectedCompany,
                          callback: function($$v) {
                            _vm.selectedCompany = $$v
                          },
                          expression: "selectedCompany"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "select_store_box mt-5" }, [
                  _c("h4", { ref: "country", staticClass: "select_article" }, [
                    _vm._v(_vm._s(_vm.$t("Country")) + " "),
                    _vm.regionItems.length === 0
                      ? _c("span", [
                          _vm._v("(" + _vm._s(_vm.countryItems.length) + ")")
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "forms-area" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.countryItems,
                          label: "" + _vm.$t("Select the country"),
                          disabled: _vm.countryInputDisabled,
                          "clearable:false": "",
                          dense: "",
                          flat: "",
                          "hide-details": "",
                          solo: "",
                          clearable: ""
                        },
                        on: {
                          change: function($event) {
                            _vm.changeedCountry()
                            _vm.Flagchange("country")
                          }
                        },
                        model: {
                          value: _vm.selectedCountry,
                          callback: function($$v) {
                            _vm.selectedCountry = $$v
                          },
                          expression: "selectedCountry"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "select_store_box" }, [
                  _c("h4", { ref: "region", staticClass: "select_article" }, [
                    _vm._v(_vm._s(_vm.$t("Region"))),
                    _vm.cityItems.length === 0
                      ? _c("span", [
                          _vm._v("(" + _vm._s(_vm.regionItems.length) + ")")
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "forms-area" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.regionItems,
                          label: "" + _vm.$t("Select the region"),
                          "clearable:false": "",
                          disabled: _vm.regionInputDisabled,
                          dense: "",
                          flat: "",
                          "hide-details": "",
                          solo: "",
                          clearable: ""
                        },
                        on: {
                          change: function($event) {
                            _vm.changeedRegion()
                            _vm.Flagchange("region")
                          }
                        },
                        model: {
                          value: _vm.selectedRegion,
                          callback: function($$v) {
                            _vm.selectedRegion = $$v
                          },
                          expression: " selectedRegion "
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "select_store_box" }, [
                  _c("h4", { ref: "city", staticClass: "select_article" }, [
                    _vm._v(_vm._s(_vm.$t("City")) + " "),
                    _vm.storeItems.length === 0
                      ? _c("span", [
                          _vm._v("(" + _vm._s(_vm.cityItems.length) + ")")
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "forms-area" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.cityItems,
                          label: "" + _vm.$t("Select the city"),
                          "clearable:false": "",
                          disabled: _vm.cityInputDisabled,
                          dense: "",
                          flat: "",
                          "hide-details": "",
                          solo: "",
                          clearable: ""
                        },
                        on: {
                          change: function($event) {
                            _vm.changeedCity()
                            _vm.Flagchange("city")
                          }
                        },
                        model: {
                          value: _vm.selectedCity,
                          callback: function($$v) {
                            _vm.selectedCity = $$v
                          },
                          expression: " selectedCity "
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "select_store_box" }, [
                  _c("h4", { ref: "store", staticClass: "select_article" }, [
                    _vm._v(
                      _vm._s(_vm.$t("Store")) +
                        " (" +
                        _vm._s(_vm.storeItems.length) +
                        ")"
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "forms-area list" },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: { "active-class": "list-select" },
                              model: {
                                value: _vm.selectedStore,
                                callback: function($$v) {
                                  _vm.selectedStore = $$v
                                },
                                expression: " selectedStore "
                              }
                            },
                            _vm._l(_vm.storeItems, function(item, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: i,
                                  attrs: { value: item.code + "," + item.name },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeedStore(item.code)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            item.name + "(" + item.code + ")"
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "div",
                    {
                      class: {
                        className: _vm.disableappsettingsbutton === true
                      }
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "page-sub-title-bar",
                          staticStyle: { "font-weight": "bold" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("DETAIL SETTING")) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-5 custom-scrollBox",
                          staticStyle: {
                            height: "500px",
                            "overflow-y": "scroll",
                            background: "#fff !important",
                            border: "1px solid #f5f5f5",
                            "box-shadow": "0 1px 5px rgb(220 212 212 / 10)",
                            padding: "30px",
                            "border-radius": "5px"
                          }
                        },
                        [
                          _c("div", [
                            _c(
                              "h3",
                              {
                                staticClass: "tit-group-bg mt-0",
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "#001e38"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("ASSIGN")) +
                                    " : " +
                                    _vm._s(_vm.$t("Product ID Extraction")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "form-group mt-5" },
                              [
                                _c("span", { staticClass: "label-txt ml-5" }, [
                                  _vm._v(_vm._s(_vm.$t("Function On/Off")))
                                ]),
                                _c("v-select", {
                                  staticClass: "form-select ml-7",
                                  staticStyle: { width: "82px" },
                                  attrs: {
                                    value: !_vm.productIdExtractionDisabled,
                                    items: _vm.productIdExtractionEnableItems,
                                    "item-text": "txt",
                                    "item-value": "val",
                                    placeholder:
                                      _vm.productIdExtractionEnableItems[0].txt,
                                    outlined: "",
                                    dense: "",
                                    dark: "",
                                    "hide-details": "",
                                    "background-color": "#001e38",
                                    solo: ""
                                  },
                                  on: { change: _vm.handleProdIdExtChange },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "0.8125rem"
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "selection",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "LedPatternClass",
                                                staticStyle: {
                                                  display: "contents !important"
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.$t(item.txt)))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3872833217
                                  )
                                })
                              ],
                              1
                            ),
                            _c(
                              "table",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.productIdExtractionDisabled,
                                    expression: " !productIdExtractionDisabled "
                                  }
                                ],
                                staticClass: "tbl-custom-type02 mt-5"
                              },
                              [
                                _c("colgroup", [
                                  _c("col", { staticStyle: { width: "20%" } }),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                  _c("col", { staticStyle: { width: "20%" } }),
                                  _c("col")
                                ]),
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("PREFIX")))
                                    ]),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("VALID DIGITS")))
                                    ]),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("PADDING VALUE")))
                                    ]),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("TOTAL DIGITS")))
                                    ]),
                                    _c("th")
                                  ])
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.productIdExtractionList.filter(function(
                                      x
                                    ) {
                                      return (
                                        x.enable || !x.hasOwnProperty("enable")
                                      )
                                    }),
                                    function(productIdExtraction) {
                                      return _c(
                                        "tr",
                                        { key: productIdExtraction.prefix },
                                        [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(productIdExtraction.prefix)
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                productIdExtraction.validDigits
                                              )
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                productIdExtraction.paddingValue
                                              )
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                productIdExtraction.totalDigits
                                              )
                                            )
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c("app-config-warning-msg-box", {
                                                attrs: {
                                                  productIdExtraction: productIdExtraction
                                                },
                                                on: {
                                                  deleteExtraction:
                                                    _vm.deleteExtraction
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-5" },
                              [
                                _c("app-config-new-extraction", {
                                  attrs: {
                                    productIdExtractionDisabled:
                                      _vm.productIdExtractionDisabled ||
                                      _vm.appConfigSaveDisabled
                                  },
                                  on: { addNewExtraction: _vm.addNewExtraction }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", [
                            _c("div", [
                              _c(
                                "h3",
                                {
                                  staticClass: "tit-group-bg mt-10",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "#001e38"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("ASSIGN")) +
                                      " : " +
                                      _vm._s(_vm.$t("Extra Info")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "table",
                                {
                                  staticClass: "tbl-custom-type02 center-1 mt-5"
                                },
                                [
                                  _c("colgroup", [
                                    _c("col", {
                                      staticStyle: { width: "30%" }
                                    }),
                                    _c("col")
                                  ]),
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("On/Off")))
                                      ]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Category")))
                                      ])
                                    ])
                                  ]),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c("v-switch", {
                                            staticClass: "form-switch center",
                                            attrs: {
                                              inset: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[0].enable,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[0],
                                                  "enable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[0].enable "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.addInfoConfigList[0].name)
                                        )
                                      ])
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c("v-switch", {
                                            staticClass: "form-switch center",
                                            attrs: {
                                              inset: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[1].enable,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[1],
                                                  "enable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[1].enable "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "form-input",
                                            attrs: {
                                              disabled: !_vm
                                                .addInfoConfigList[1].enable,
                                              placeholder:
                                                "Input the custom info",
                                              dense: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[1].name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[1],
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[1].name "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c("v-switch", {
                                            staticClass: "form-switch center",
                                            attrs: {
                                              inset: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[2].enable,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[2],
                                                  "enable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[2].enable "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "form-input",
                                            attrs: {
                                              disabled: !_vm
                                                .addInfoConfigList[2].enable,
                                              placeholder:
                                                "Input the custom info",
                                              dense: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[2].name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[2],
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[2].name "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c("v-switch", {
                                            staticClass: "form-switch center",
                                            attrs: {
                                              inset: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[3].enable,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[3],
                                                  "enable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[3].enable "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "form-input",
                                            attrs: {
                                              disabled: !_vm
                                                .addInfoConfigList[3].enable,
                                              placeholder:
                                                "Input the custom info",
                                              dense: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[3].name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[3],
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[3].name "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c("v-switch", {
                                            staticClass: "form-switch center",
                                            attrs: {
                                              inset: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[4].enable,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[4],
                                                  "enable",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[4].enable "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "form-input",
                                            attrs: {
                                              disabled: !_vm
                                                .addInfoConfigList[4].enable,
                                              placeholder:
                                                "Input the custom info",
                                              dense: "",
                                              "hide-details": "",
                                              solo: ""
                                            },
                                            model: {
                                              value:
                                                _vm.addInfoConfigList[4].name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.addInfoConfigList[4],
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                " addInfoConfigList[4].name "
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ]),
                            _c("div", [
                              _c(
                                "h3",
                                {
                                  staticClass: "tit-group-bg mt-10",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "#001e38"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Editable ProductInfo")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "table",
                                {
                                  staticClass: "tbl-custom-type02 center-1 mt-5"
                                },
                                [
                                  _c("colgroup", [
                                    _c("col", {
                                      staticStyle: { width: "30%" }
                                    }),
                                    _c("col")
                                  ]),
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("#")]),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("Field")))
                                      ]),
                                      _c("th")
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.editableProductInfo, function(
                                      editProdInfo,
                                      idx
                                    ) {
                                      return _c("tr", { key: idx }, [
                                        _c("td", [_vm._v(_vm._s(idx))]),
                                        _c(
                                          "td",
                                          [
                                            _c("v-select", {
                                              staticClass: "form-select",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                items: _vm.productKeys,
                                                placeholder:
                                                  "---- Not selected ----",
                                                dense: "",
                                                "hide-details": "",
                                                solo: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.editableProductInfo[idx],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.editableProductInfo,
                                                    idx,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  " editableProductInfo[idx] "
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.handleDelEditProdInfo(
                                                  editProdInfo
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "v-input__append-inner"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "v-input__icon v-input__icon--clear"
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        attrs: {
                                                          type: "button"
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/img/ico-delete3.png"),
                                                            alt: "삭제"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-5" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-s",
                                      attrs: {
                                        disabled: _vm.appConfigSaveDisabled,
                                        text: ""
                                      },
                                      on: { click: _vm.handleAddEditProdInfo }
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")]
                                  )
                                ],
                                1
                              )
                            ]),
                            _c("div", [
                              _c(
                                "h3",
                                {
                                  staticClass: "tit-group-bg mt-10",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "#001e38"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("Activate Features")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mt-5" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "label-txt ml-11" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Batch_Assign Menu"))
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      staticClass: "form-select ml-7",
                                      staticStyle: { width: "82px" },
                                      attrs: {
                                        items: _vm.selectedItems,
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        dark: "",
                                        "background-color": "#001e38"
                                      },
                                      model: {
                                        value: _vm.batchMode,
                                        callback: function($$v) {
                                          _vm.batchMode = $$v
                                        },
                                        expression: " batchMode "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group mt-5" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "label-txt ml-7" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Popup_Assign Success"))
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      staticClass: "form-select ml-7",
                                      staticStyle: { width: "82px" },
                                      attrs: {
                                        items: _vm.selectedItems,
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        dark: "",
                                        "background-color": "#001e38"
                                      },
                                      model: {
                                        value: _vm.assignSuccess,
                                        callback: function($$v) {
                                          _vm.assignSuccess = $$v
                                        },
                                        expression: " assignSuccess "
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group mt-5" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "label-txt ml-11" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("Popup_Assign Start"))
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      staticClass: "form-select ml-7",
                                      staticStyle: { width: "82px" },
                                      attrs: {
                                        items: _vm.selectedItems,
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        dark: "",
                                        "background-color": "#001e38"
                                      },
                                      model: {
                                        value: _vm.assignStart,
                                        callback: function($$v) {
                                          _vm.assignStart = $$v
                                        },
                                        expression: " assignStart "
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group mt-5" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "label-txt ml-7" },
                                      [_vm._v(_vm._s(_vm.$t("Popup_Unassign")))]
                                    ),
                                    _c("v-select", {
                                      staticClass: "form-select ml-16",
                                      staticStyle: { width: "82px" },
                                      attrs: {
                                        items: _vm.selectedItems,
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        solo: "",
                                        dark: "",
                                        "background-color": "#001e38"
                                      },
                                      model: {
                                        value: _vm.unassign,
                                        callback: function($$v) {
                                          _vm.unassign = $$v
                                        },
                                        expression: " unassign "
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ]),
                          _vm.lidlInformationEnabled
                            ? _c(
                                "div",
                                { staticStyle: { "max-width": "100%" } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "tit-group-bg mt-10",
                                      staticStyle: {
                                        "font-weight": "bold",
                                        color: "#001e38"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Label Actions")))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "customInfo mt-7",
                                      staticStyle: { padding: "5px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "info-list",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "tableclass",
                                            attrs: {
                                              headers: _vm.LabelActionsHeaders,
                                              items: _vm.LabelActionsList,
                                              "hide-default-footer": true
                                            },
                                            on: {
                                              "page-count": function($event) {
                                                _vm.pageCount = $event
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "tr",
                                                        {
                                                          key: item.name,
                                                          class: {
                                                            selectedRow:
                                                              item ===
                                                              _vm.selectedItem
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.selectItem(
                                                                item
                                                              ),
                                                                _vm.selectCustomerInfo(
                                                                  item,
                                                                  _vm.LabelActionsList
                                                                )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.priority
                                                              )
                                                            )
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "capitalize"
                                                                )(item.name)
                                                              )
                                                            )
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                display: "block"
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                key: item.name,
                                                                staticClass:
                                                                  "form-switch center",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "10px"
                                                                },
                                                                attrs: {
                                                                  inset: "",
                                                                  "hide-details":
                                                                    "",
                                                                  solo: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.enable,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "enable",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    " item.enable "
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2384512302
                                            )
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "mt-5" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.moveCustomerInfo(
                                                        _vm.moveType.start,
                                                        _vm.LabelActionsList,
                                                        _vm.selectedLabelActions
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/ico-move-top.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.moveCustomerInfo(
                                                        _vm.moveType.up,
                                                        _vm.LabelActionsList,
                                                        _vm.selectedLabelActions
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/ico-move-up.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.moveCustomerInfo(
                                                        _vm.moveType.down,
                                                        _vm.LabelActionsList,
                                                        _vm.selectedLabelActions
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/ico-move-down.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.moveCustomerInfo(
                                                        _vm.moveType.end,
                                                        _vm.LabelActionsList,
                                                        _vm.selectedLabelActions
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("@/assets/img/ico-move-bottom.png"),
                                                      alt: ""
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.lidlInformationEnabled
                            ? _c(
                                "div",
                                { staticStyle: { "max-width": "100%" } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "tit-group-bg mt-10",
                                      staticStyle: {
                                        "font-weight": "bold",
                                        color: "#001e38"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Features")) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "customInfo mt-7",
                                      staticStyle: { padding: "5px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "info-list",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "tbl-type04",
                                            attrs: {
                                              headers: _vm.FeaturesHeaders,
                                              items: _vm.FeaturesList,
                                              "hide-default-footer": true
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "capitalize"
                                                              )(item.name)
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              display: "block"
                                                            }
                                                          },
                                                          [
                                                            _c("v-switch", {
                                                              key: item.name,
                                                              staticClass:
                                                                "form-switch center",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "10px"
                                                              },
                                                              attrs: {
                                                                inset: "",
                                                                "hide-details":
                                                                  "",
                                                                solo: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  item.enable,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "enable",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  " item.enable "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2899725343
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.lidlInformationEnabled
                            ? _c(
                                "div",
                                { staticStyle: { "max-width": "100%" } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "tit-group-bg mt-10",
                                      staticStyle: {
                                        "font-weight": "bold",
                                        color: "#001e38"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("SpecialFunctions")))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "customInfo mt-7",
                                      staticStyle: { padding: "5px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "info-list",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c("v-data-table", {
                                            staticClass: "tbl-type04",
                                            attrs: {
                                              headers:
                                                _vm.SpecialFunctionsHeaders,
                                              items: _vm.SpecialFunctionsList,
                                              "hide-default-footer": true
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("tr", [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "capitalize"
                                                              )(item.name)
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              display: "block"
                                                            }
                                                          },
                                                          [
                                                            _c("v-switch", {
                                                              key: item.name,
                                                              staticClass:
                                                                "form-switch",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "10px"
                                                              },
                                                              attrs: {
                                                                inset: "",
                                                                "hide-details":
                                                                  "",
                                                                solo: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  item.enable,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "enable",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  " item.enable "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              554743924
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("customer-information", {
                            attrs: {
                              customerInformationEnabled:
                                _vm.customerInformationEnabled,
                              selectedareaid: _vm.areaid,
                              reloaddatacust: _vm.reloadcust,
                              appConfigSaveDisabled1: _vm.appConfigSaveDisabled
                            }
                          }),
                          _c("favorite-menu", {
                            attrs: {
                              favoriteButtonEnabled: _vm.favoriteButtonEnabled,
                              selectedareaid: _vm.areaid,
                              reloaddatafav: _vm.reloadfav,
                              appConfigSaveDisabled1: _vm.appConfigSaveDisabled
                            }
                          }),
                          _c("app-config-msg-box", {
                            attrs: { msgModalVisible: _vm.msgModalVisible },
                            on: {
                              closeAppConfigMsgBox: _vm.closeAppConfigMsgBox
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-s mt-5",
                          attrs: {
                            disabled: _vm.appConfigSaveDisabled,
                            text: ""
                          },
                          on: { click: _vm.Tosaveappsettings }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.disableappsettingsbutton &&
                            _vm.selectedCountry !== "" &&
                            _vm.selectedCountry !== "undefined",
                          expression:
                            " !disableappsettingsbutton && selectedCountry !== '' && selectedCountry !== 'undefined' "
                        }
                      ],
                      staticClass: "btn btn-danger",
                      staticStyle: {
                        width: "128px",
                        float: "right",
                        "margin-top": "-33px !important",
                        background: "red",
                        "border-radius": "5px",
                        color: "white",
                        padding: "3px"
                      },
                      attrs: { disabled: _vm.appConfigSaveDisabled },
                      on: { click: _vm.deleteappsettings }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Delete Config")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.Customer === "LIDL"
        ? _c(
            "v-row",
            { staticClass: "mt-12 ml-2" },
            [
              _c(
                "h3",
                {
                  staticClass: "page-sub-title-bar",
                  staticStyle: { "font-weight": "bold" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("EXISTING CONFIGRATION")) + " ")]
              ),
              _c(
                "div",
                { staticClass: "col-12", staticStyle: { padding: "0" } },
                [
                  _c(
                    "v-data-table",
                    {
                      staticClass: "tbl-type01 mt-10",
                      attrs: {
                        headers: _vm.existingConfigHeaders,
                        items: _vm.existingConfig,
                        "item-key": "indexVal",
                        options: _vm.options,
                        "server-items-length": 10,
                        "show-select": "",
                        "hide-default-footer": true
                      },
                      on: {
                        "update:options": function($event) {
                          _vm.options = $event
                        },
                        "click:row": _vm.editExistConfig
                      },
                      model: {
                        value: _vm.selectedconfig,
                        callback: function($$v) {
                          _vm.selectedconfig = $$v
                        },
                        expression: " selectedconfig "
                      }
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("No data available")) + " "
                          )
                        ])
                      ])
                    ],
                    2
                  ),
                  _c("div", { staticClass: "table-options" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn  ml-2",
                            attrs: {
                              text: "",
                              disabled: _vm.appConfigSaveDisabled
                            },
                            on: { click: _vm.AddNewConfigpopup }
                          },
                          [_vm._v(_vm._s(_vm.$t("Add")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn  ml-2",
                            attrs: {
                              disabled:
                                _vm.selectedconfig.length === 0 ||
                                _vm.appConfigSaveDisabled,
                              text: ""
                            },
                            on: { click: _vm.deleteExistingConfig }
                          },
                          [_vm._v(_vm._s(_vm.$t("Delete")))]
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 pr-0",
                      staticStyle: { padding: "0px" }
                    },
                    [
                      _c("Pagination", {
                        attrs: {
                          pageInfoText: _vm.pageInfoText,
                          pageIndex: _vm.pageIndex,
                          rowPerPage: _vm.rowPerPage,
                          goToPageInput: _vm.goToPageInput,
                          totalPages: _vm.totalPages
                        },
                        on: {
                          paging: function($event) {
                            return _vm.paging($event)
                          },
                          updatePage: function($event) {
                            return _vm.updatePage($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c("a", { ref: "link", style: { display: "none" } })
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { width: "530" },
                  on: { "click:outside": _vm.closeAppConfigMsgBox },
                  model: {
                    value: _vm.toSelectLevel,
                    callback: function($$v) {
                      _vm.toSelectLevel = $$v
                    },
                    expression: " toSelectLevel "
                  }
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "popup-success" },
                    [
                      _c(
                        "div",
                        { staticClass: "msg" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("h3", { staticClass: "page-title-bar" }, [
                              _c("i", { staticClass: "ico ico-overview" }),
                              _vm._v(_vm._s(_vm.$t("Select the store")) + " ")
                            ]),
                            _c(
                              "div",
                              { staticClass: "select_store_box mt-5" },
                              [
                                _c(
                                  "h4",
                                  { staticClass: "select_article is-complete" },
                                  [_vm._v(_vm._s(_vm.$t("Company")))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "forms-area" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.companyItems,
                                        label:
                                          "" + _vm.$t("Select the company"),
                                        "item-text": "companyItems.company",
                                        "item-value": "companyItems.company",
                                        disabled: _vm.disabledCompany,
                                        "clearable:false": "",
                                        dense: "",
                                        flat: "",
                                        "hide-details": "",
                                        solo: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeedCompany()
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedCompany,
                                        callback: function($$v) {
                                          _vm.selectedCompany = $$v
                                        },
                                        expression: " selectedCompany "
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "select_store_box mt-5" },
                              [
                                _c(
                                  "h4",
                                  {
                                    ref: "country",
                                    staticClass: "select_article"
                                  },
                                  [
                                    _vm._v(_vm._s(_vm.$t("Country")) + " "),
                                    _vm.regionItems.length === 0
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(_vm.countryItems.length) +
                                              ")"
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "forms-area" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.countryItems,
                                        label:
                                          "" + _vm.$t("Select the country"),
                                        "clearable:false": "",
                                        disabled: _vm.countryInputDisabled,
                                        dense: "",
                                        flat: "",
                                        "hide-details": "",
                                        solo: "",
                                        clearable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.changeedCountry()
                                          _vm.Flagchange("country")
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedCountry,
                                        callback: function($$v) {
                                          _vm.selectedCountry = $$v
                                        },
                                        expression: " selectedCountry "
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("div", { staticClass: "select_store_box" }, [
                              _c(
                                "h4",
                                {
                                  ref: "region",
                                  staticClass: "select_article"
                                },
                                [
                                  _vm._v(_vm._s(_vm.$t("Region"))),
                                  _vm.cityItems.length === 0
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.regionItems.length) +
                                            ")"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "forms-area" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.regionItems,
                                      label: "" + _vm.$t("Select the region"),
                                      "clearable:false": "",
                                      disabled: _vm.regionInputDisabled,
                                      dense: "",
                                      flat: "",
                                      "hide-details": "",
                                      solo: "",
                                      clearable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.changeedRegion()
                                        _vm.Flagchange("region")
                                      }
                                    },
                                    model: {
                                      value: _vm.selectedRegion,
                                      callback: function($$v) {
                                        _vm.selectedRegion = $$v
                                      },
                                      expression: " selectedRegion "
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "select_store_box" }, [
                              _c(
                                "h4",
                                { ref: "city", staticClass: "select_article" },
                                [
                                  _vm._v(_vm._s(_vm.$t("City")) + " "),
                                  _vm.storeItems.length === 0
                                    ? _c("span", [
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.cityItems.length) +
                                            ")"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "forms-area" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.cityItems,
                                      label: "" + _vm.$t("Select the city"),
                                      "clearable:false": "",
                                      disabled: _vm.cityInputDisabled,
                                      dense: "",
                                      flat: "",
                                      "hide-details": "",
                                      solo: "",
                                      clearable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.changeedCity()
                                        _vm.Flagchange("city")
                                      }
                                    },
                                    model: {
                                      value: _vm.selectedCity,
                                      callback: function($$v) {
                                        _vm.selectedCity = $$v
                                      },
                                      expression: " selectedCity "
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _c("div", { staticClass: "select_store_box" }, [
                              _c(
                                "h4",
                                { ref: "store", staticClass: "select_article" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("Store")) +
                                      " (" +
                                      _vm._s(_vm.storeItems.length) +
                                      ")"
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "forms-area list" },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item-group",
                                        {
                                          attrs: {
                                            "active-class": "list-select"
                                          },
                                          model: {
                                            value: _vm.selectedStore,
                                            callback: function($$v) {
                                              _vm.selectedStore = $$v
                                            },
                                            expression: " selectedStore "
                                          }
                                        },
                                        _vm._l(_vm.storeItems, function(
                                          item,
                                          i
                                        ) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: i,
                                              attrs: {
                                                value:
                                                  item.code + "," + item.name
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.changeedStore(
                                                    item.code
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", {
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.name +
                                                          "(" +
                                                          item.code +
                                                          ")"
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-center btnArea" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { text: "", icon: "" },
                              on: { click: _vm.toSelectareaLevel }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Select")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn",
                              attrs: { text: "", icon: "" },
                              on: { click: _vm.closeSelectareaPopup }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { width: "45%" },
                  on: { "click:outside": _vm.closeAppConfigMsgBox },
                  model: {
                    value: _vm.addNewConfig,
                    callback: function($$v) {
                      _vm.addNewConfig = $$v
                    },
                    expression: " addNewConfig "
                  }
                },
                [
                  _c("v-card", { staticClass: "popup-success" }, [
                    _c(
                      "div",
                      { staticClass: "msg" },
                      [
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c(
                            "div",
                            {
                              class: {
                                className: _vm.disableappsettingsbutton === true
                              }
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass: "page-title-bar",
                                  staticStyle: { "font-weight": "bold" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("DETAIL SETTING")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mt-5 custom-scrollBox",
                                  staticStyle: {
                                    height: "500px",
                                    "overflow-y": "scroll",
                                    background: "#fff !important",
                                    border: "1px solid #f5f5f5",
                                    "box-shadow":
                                      "0 1px 5px rgb(220 212 212 / 10)",
                                    padding: "30px",
                                    "border-radius": "5px"
                                  }
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "tit-group-bg mt-0",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          color: "#001e38"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("ASSIGN")) +
                                            " : " +
                                            _vm._s(
                                              _vm.$t("Product ID Extraction")
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group mt-5" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "label-txt ml-5" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("Function On/Off"))
                                            )
                                          ]
                                        ),
                                        _c("v-select", {
                                          staticClass: "form-select ml-7",
                                          staticStyle: { width: "82px" },
                                          attrs: {
                                            value: !_vm.productIdExtractionDisabled,
                                            items:
                                              _vm.productIdExtractionEnableItems,
                                            "item-text": "txt",
                                            "item-value": "val",
                                            placeholder:
                                              _vm
                                                .productIdExtractionEnableItems[0]
                                                .txt,
                                            outlined: "",
                                            dense: "",
                                            dark: "",
                                            "hide-details": "",
                                            "background-color": "#001e38",
                                            solo: ""
                                          },
                                          on: {
                                            change: _vm.handleProdIdExtChange
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "table",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.productIdExtractionDisabled,
                                            expression:
                                              " !productIdExtractionDisabled "
                                          }
                                        ],
                                        staticClass: "tbl-custom-type02 mt-5"
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "20%" }
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "20%" }
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "20%" }
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "20%" }
                                          }),
                                          _c("col")
                                        ]),
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", [
                                              _vm._v(_vm._s(_vm.$t("PREFIX")))
                                            ]),
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(_vm.$t("VALID DIGITS"))
                                              )
                                            ]),
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(_vm.$t("PADDING VALUE"))
                                              )
                                            ]),
                                            _c("th", [
                                              _vm._v(
                                                _vm._s(_vm.$t("TOTAL DIGITS"))
                                              )
                                            ]),
                                            _c("th")
                                          ])
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.productIdExtractionList.filter(
                                              function(x) {
                                                return (
                                                  x.enable ||
                                                  !x.hasOwnProperty("enable")
                                                )
                                              }
                                            ),
                                            function(productIdExtraction) {
                                              return _c(
                                                "tr",
                                                {
                                                  key:
                                                    productIdExtraction.prefix
                                                },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        productIdExtraction.prefix
                                                      )
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        productIdExtraction.validDigits
                                                      )
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        productIdExtraction.paddingValue
                                                      )
                                                    )
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        productIdExtraction.totalDigits
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "app-config-warning-msg-box",
                                                        {
                                                          attrs: {
                                                            productIdExtraction: productIdExtraction
                                                          },
                                                          on: {
                                                            deleteExtraction:
                                                              _vm.deleteExtraction
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "mt-5" },
                                      [
                                        _c("app-config-new-extraction", {
                                          attrs: {
                                            productIdExtractionDisabled:
                                              _vm.productIdExtractionDisabled ||
                                              _vm.appConfigSaveDisabled
                                          },
                                          on: {
                                            addNewExtraction:
                                              _vm.addNewExtraction
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("div", [
                                    _c("div", [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "tit-group-bg mt-10",
                                          staticStyle: {
                                            "font-weight": "bold",
                                            color: "#001e38"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("ASSIGN")) +
                                              " : " +
                                              _vm._s(_vm.$t("Extra Info")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "tbl-custom-type02 center-1 mt-5"
                                        },
                                        [
                                          _c("colgroup", [
                                            _c("col", {
                                              staticStyle: { width: "30%" }
                                            }),
                                            _c("col")
                                          ]),
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [
                                                _vm._v(_vm._s(_vm.$t("On/Off")))
                                              ]),
                                              _c("th", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Category"))
                                                )
                                              ])
                                            ])
                                          ]),
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "form-switch center",
                                                    attrs: {
                                                      inset: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[0]
                                                          .enable,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[0],
                                                          "enable",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[0].enable "
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.addInfoConfigList[0]
                                                      .name
                                                  )
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "form-switch center",
                                                    attrs: {
                                                      inset: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[1]
                                                          .enable,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[1],
                                                          "enable",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[1].enable "
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "form-input",
                                                    attrs: {
                                                      disabled: !_vm
                                                        .addInfoConfigList[1]
                                                        .enable,
                                                      placeholder:
                                                        "Input the custom info",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[1]
                                                          .name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[1],
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[1].name "
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "form-switch center",
                                                    attrs: {
                                                      inset: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[2]
                                                          .enable,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[2],
                                                          "enable",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[2].enable "
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "form-input",
                                                    attrs: {
                                                      disabled: !_vm
                                                        .addInfoConfigList[2]
                                                        .enable,
                                                      placeholder:
                                                        "Input the custom info",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[2]
                                                          .name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[2],
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[2].name "
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "form-switch center",
                                                    attrs: {
                                                      inset: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[3]
                                                          .enable,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[3],
                                                          "enable",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[3].enable "
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "form-input",
                                                    attrs: {
                                                      disabled: !_vm
                                                        .addInfoConfigList[3]
                                                        .enable,
                                                      placeholder:
                                                        "Input the custom info",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[3]
                                                          .name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[3],
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[3].name "
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "form-switch center",
                                                    attrs: {
                                                      inset: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[4]
                                                          .enable,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[4],
                                                          "enable",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[4].enable "
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "form-input",
                                                    attrs: {
                                                      disabled: !_vm
                                                        .addInfoConfigList[4]
                                                        .enable,
                                                      placeholder:
                                                        "Input the custom info",
                                                      dense: "",
                                                      "hide-details": "",
                                                      solo: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addInfoConfigList[4]
                                                          .name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .addInfoConfigList[4],
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        " addInfoConfigList[4].name "
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          ])
                                        ]
                                      )
                                    ]),
                                    _c("div", [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "tit-group-bg mt-10",
                                          staticStyle: {
                                            "font-weight": "bold",
                                            color: "#001e38"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Editable ProductInfo")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "tbl-custom-type02 center-1 mt-5"
                                        },
                                        [
                                          _c("colgroup", [
                                            _c("col", {
                                              staticStyle: { width: "30%" }
                                            }),
                                            _c("col")
                                          ]),
                                          _c("thead", [
                                            _c("tr", [
                                              _c("th", [_vm._v("#")]),
                                              _c("th", [
                                                _vm._v(_vm._s(_vm.$t("Field")))
                                              ]),
                                              _c("th")
                                            ])
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.editableProductInfo,
                                              function(editProdInfo, idx) {
                                                return _c("tr", { key: idx }, [
                                                  _c("td", [
                                                    _vm._v(_vm._s(idx))
                                                  ]),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c("v-select", {
                                                        staticClass:
                                                          "form-select",
                                                        staticStyle: {
                                                          width: "100%"
                                                        },
                                                        attrs: {
                                                          items:
                                                            _vm.productKeys,
                                                          placeholder:
                                                            "---- Not selected ----",
                                                          dense: "",
                                                          "hide-details": "",
                                                          solo: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .editableProductInfo[
                                                              idx
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.editableProductInfo,
                                                              idx,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            " editableProductInfo[idx] "
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleDelEditProdInfo(
                                                            editProdInfo
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "v-input__append-inner"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "v-input__icon v-input__icon--clear"
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "button"
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: require("@/assets/img/ico-delete3.png"),
                                                                      alt:
                                                                        "삭제"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "mt-5" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-s",
                                              attrs: {
                                                disabled:
                                                  _vm.appConfigSaveDisabled,
                                                text: ""
                                              },
                                              on: {
                                                click: _vm.handleAddEditProdInfo
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("Add")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]),
                                    _c("div", [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "tit-group-bg mt-10",
                                          staticStyle: {
                                            "font-weight": "bold",
                                            color: "#001e38"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Activate Features")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group mt-5" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "label-txt ml-11"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Batch_Assign Menu")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("v-select", {
                                              staticClass: "form-select ml-7",
                                              staticStyle: { width: "82px" },
                                              attrs: {
                                                items: _vm.selectedItems,
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                solo: "",
                                                dark: "",
                                                "background-color": "#001e38"
                                              },
                                              model: {
                                                value: _vm.batchMode,
                                                callback: function($$v) {
                                                  _vm.batchMode = $$v
                                                },
                                                expression: " batchMode "
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-group mt-5" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "label-txt ml-7" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "Popup_Assign Success"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("v-select", {
                                              staticClass: "form-select ml-7",
                                              staticStyle: { width: "82px" },
                                              attrs: {
                                                items: _vm.selectedItems,
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                solo: "",
                                                dark: "",
                                                "background-color": "#001e38"
                                              },
                                              model: {
                                                value: _vm.assignSuccess,
                                                callback: function($$v) {
                                                  _vm.assignSuccess = $$v
                                                },
                                                expression: " assignSuccess "
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group mt-5" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "label-txt ml-11"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Popup_Assign Start")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("v-select", {
                                              staticClass: "form-select ml-7",
                                              staticStyle: { width: "82px" },
                                              attrs: {
                                                items: _vm.selectedItems,
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                solo: "",
                                                dark: "",
                                                "background-color": "#001e38"
                                              },
                                              model: {
                                                value: _vm.assignStart,
                                                callback: function($$v) {
                                                  _vm.assignStart = $$v
                                                },
                                                expression: " assignStart "
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-group mt-5" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "label-txt ml-7" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Popup_Unassign")
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("v-select", {
                                              staticClass: "form-select ml-16",
                                              staticStyle: { width: "82px" },
                                              attrs: {
                                                items: _vm.selectedItems,
                                                outlined: "",
                                                dense: "",
                                                "hide-details": "",
                                                solo: "",
                                                dark: "",
                                                "background-color": "#001e38"
                                              },
                                              model: {
                                                value: _vm.unassign,
                                                callback: function($$v) {
                                                  _vm.unassign = $$v
                                                },
                                                expression: " unassign "
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ]),
                                  _vm.lidlInformationEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "max-width": "100%" }
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass: "tit-group-bg mt-10",
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "#001e38"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Label Actions"))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "customInfo mt-7",
                                              staticStyle: { padding: "5px" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "info-list",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c("v-data-table", {
                                                    staticClass: "tableclass",
                                                    attrs: {
                                                      headers:
                                                        _vm.LabelActionsListForLiDL,
                                                      items:
                                                        _vm.LabelActionsList,
                                                      "hide-default-footer": true
                                                    },
                                                    on: {
                                                      "page-count": function(
                                                        $event
                                                      ) {
                                                        _vm.pageCount = $event
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function(ref) {
                                                            var item = ref.item
                                                            return [
                                                              _c(
                                                                "tr",
                                                                {
                                                                  key:
                                                                    item.name,
                                                                  class: {
                                                                    selectedRow:
                                                                      item ===
                                                                      _vm.selectedItem
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.selectItem(
                                                                        item
                                                                      ),
                                                                        _vm.selectCustomerInfo(
                                                                          item,
                                                                          _vm.LabelActionsList
                                                                        )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.priority
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm._f(
                                                                          "capitalize"
                                                                        )(
                                                                          item.name
                                                                        )
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticStyle: {
                                                                        display:
                                                                          "block"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          key:
                                                                            item.name,
                                                                          staticClass:
                                                                            "form-switch center",
                                                                          staticStyle: {
                                                                            "margin-top":
                                                                              "10px"
                                                                          },
                                                                          attrs: {
                                                                            inset:
                                                                              "",
                                                                            "hide-details":
                                                                              "",
                                                                            solo:
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item.enable,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item,
                                                                                "enable",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              " item.enable "
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex"
                                                                      },
                                                                      [
                                                                        item.name.includes(
                                                                          "customerInfoShortCutEnabled"
                                                                        ) &&
                                                                        item.enable ==
                                                                          true
                                                                          ? _c(
                                                                              "v-select",
                                                                              {
                                                                                ref:
                                                                                  "clearCIInput",
                                                                                staticClass:
                                                                                  "label_details",
                                                                                staticStyle: {
                                                                                  width:
                                                                                    "15%"
                                                                                },
                                                                                attrs: {
                                                                                  items:
                                                                                    _vm.customerInfoLabelActions,
                                                                                  label:
                                                                                    "" +
                                                                                    _vm.$t(
                                                                                      "Select the Customer Information"
                                                                                    ),
                                                                                  "item-text":
                                                                                    "description",
                                                                                  "item-value":
                                                                                    "description",
                                                                                  "clearable:false":
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  flat:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                  solo:
                                                                                    ""
                                                                                },
                                                                                scopedSlots: _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key:
                                                                                        "selection",
                                                                                      fn: function(
                                                                                        ref
                                                                                      ) {
                                                                                        var item =
                                                                                          ref.item
                                                                                        return [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                top:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function(
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      var attrs =
                                                                                                        ref.attrs
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          _vm._g(
                                                                                                            _vm._b(
                                                                                                              {},
                                                                                                              "span",
                                                                                                              attrs,
                                                                                                              false
                                                                                                            ),
                                                                                                            on
                                                                                                          ),
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                item.description
                                                                                                              )
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    }
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              )
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      item.description
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      }
                                                                                    }
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                                model: {
                                                                                  value:
                                                                                    item.description,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item,
                                                                                      "description",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    " item.description "
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1492967847
                                                    )
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mt-5" },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.moveCustomerInfo(
                                                                _vm.moveType
                                                                  .start,
                                                                _vm.LabelActionsList,
                                                                _vm.selectedLabelActions
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico-move-top.png"),
                                                              alt: ""
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.moveCustomerInfo(
                                                                _vm.moveType.up,
                                                                _vm.LabelActionsList,
                                                                _vm.selectedLabelActions
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico-move-up.png"),
                                                              alt: ""
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.moveCustomerInfo(
                                                                _vm.moveType
                                                                  .down,
                                                                _vm.LabelActionsList,
                                                                _vm.selectedLabelActions
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico-move-down.png"),
                                                              alt: ""
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.moveCustomerInfo(
                                                                _vm.moveType
                                                                  .end,
                                                                _vm.LabelActionsList,
                                                                _vm.selectedLabelActions
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: require("@/assets/img/ico-move-bottom.png"),
                                                              alt: ""
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.lidlInformationEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "max-width": "100%" }
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass: "tit-group-bg mt-10",
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "#001e38"
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("Features")))]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "customInfo mt-7",
                                              staticStyle: { padding: "5px" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "info-list",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c("v-data-table", {
                                                    staticClass: "tbl-type04",
                                                    attrs: {
                                                      headers:
                                                        _vm.FeaturesHeaders,
                                                      items: _vm.FeaturesList,
                                                      "hide-default-footer": true
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function(ref) {
                                                            var item = ref.item
                                                            return [
                                                              _c("tr", [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "capitalize"
                                                                      )(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticStyle: {
                                                                      display:
                                                                        "block"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-switch",
                                                                      {
                                                                        key:
                                                                          item.name,
                                                                        staticClass:
                                                                          "form-switch center",
                                                                        staticStyle: {
                                                                          "margin-top":
                                                                            "10px"
                                                                        },
                                                                        attrs: {
                                                                          inset:
                                                                            "",
                                                                          "hide-details":
                                                                            "",
                                                                          solo:
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.enable,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "enable",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            " item.enable "
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2899725343
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.lidlInformationEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "max-width": "100%" }
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass: "tit-group-bg mt-10",
                                              staticStyle: {
                                                "font-weight": "bold",
                                                color: "#001e38"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("SpecialFunctions")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "customInfo mt-7",
                                              staticStyle: { padding: "5px" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "info-list",
                                                  staticStyle: { width: "100%" }
                                                },
                                                [
                                                  _c("v-data-table", {
                                                    staticClass: "tbl-type04",
                                                    attrs: {
                                                      headers:
                                                        _vm.SpecialFunctionsHeaders,
                                                      items:
                                                        _vm.SpecialFunctionsList,
                                                      "hide-default-footer": true
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function(ref) {
                                                            var item = ref.item
                                                            return [
                                                              _c("tr", [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "capitalize"
                                                                      )(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticStyle: {
                                                                      display:
                                                                        "block"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-switch",
                                                                      {
                                                                        key:
                                                                          item.name,
                                                                        staticClass:
                                                                          "form-switch",
                                                                        staticStyle: {
                                                                          "margin-top":
                                                                            "10px"
                                                                        },
                                                                        attrs: {
                                                                          inset:
                                                                            "",
                                                                          "hide-details":
                                                                            "",
                                                                          solo:
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.enable,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "enable",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            " item.enable "
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      554743924
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("customer-information", {
                                    attrs: {
                                      customerInformationEnabled:
                                        _vm.customerInformationEnabled,
                                      selectedareaid: _vm.areaid,
                                      reloaddatacust: _vm.reloadcust,
                                      appConfigSaveDisabled1:
                                        _vm.appConfigSaveDisabled
                                    }
                                  }),
                                  _c("favorite-menu", {
                                    attrs: {
                                      favoriteButtonEnabled:
                                        _vm.favoriteButtonEnabled,
                                      selectedareaid: _vm.areaid,
                                      reloaddatafav: _vm.reloadfav,
                                      appConfigSaveDisabled1:
                                        _vm.appConfigSaveDisabled
                                    }
                                  }),
                                  _c("app-config-msg-box", {
                                    attrs: {
                                      msgModalVisible: _vm.msgModalVisible
                                    },
                                    on: {
                                      closeAppConfigMsgBox:
                                        _vm.closeAppConfigMsgBox
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-s mt-5",
                                  attrs: {
                                    disabled: _vm.appConfigSaveDisabled,
                                    text: ""
                                  },
                                  on: { click: _vm.Tosaveappsettings }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-s mt-5 ml-2",
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.addNewConfig = false
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }