<template>
  <v-dialog v-model="dialog_msg" width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="handleDeleteExtractionClick" v-bind="attrs" v-on="on" icon>
        <img src="@/assets/img/ico-delete3.png" alt="삭제" />
      </v-btn>
    </template>
    <v-card class="msg-popup">
      <div class="inner-popup">
        <i class="ico-success"
          ><img src="../../../assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
          <h3>{{ $t('Delete') }}</h3>
          <p>{{ $t('Are you sure you want to delete?') }}</p>
        </div>
        <!-- 팝업 하단 버튼 -->
        <v-card-actions class="d-flex justify-center btnArea">
          <v-btn text icon @click="dialog_msg = false" class="btn">{{
            $t('Yes')
          }}</v-btn>
          <v-btn text icon @click="dialog_msg = false" class="btn">{{
            $t('No')
          }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    productIdExtraction: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      dialog_msg: false
    }
  },
  methods: {
    handleDeleteExtractionClick () {
      this.$emit('deleteExtraction', this.productIdExtraction)
    }
  }
}
</script>

<style></style>
