var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          model: {
            value: _vm.msgBox.msgBoxVisible,
            callback: function($$v) {
              _vm.$set(_vm.msgBox, "msgBoxVisible", $$v)
            },
            expression: "msgBox.msgBoxVisible"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.msgBox.msg) }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("closeMsgBox")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("OK")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }