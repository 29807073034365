<template>
  <div v-if="customerInformationEnabled" style="max-width: 970px">
    <h3 class="tit-group-bg mt-10" style="font-weight:bold;color: #001e38;">{{ $t("Customer Information") }}</h3>
    <!-- <div class="form-group mt-5">
      <span class="label-txt ml-5">{{ $t("Store") }}</span>
      <v-select
        v-model="selectedCustomerInfoStore"
        @input="handleCustomerInfoDefaultInput"
        :items="customerInfoStores"
        item-text="name"
        item-value="code"
        outlined
        dense
        hide-details
        solo
        placeholder="Select the menu"
        class="form-select ml-7"
      ></v-select>
    </div> -->
    <div class="customInfo mt-7" style="padding: 5px;">
      <div class="info-list" style="width:255px">
        <v-data-table
          :headers="customerInfoHeaders"
          :items="customerInfoList"
          @click:row="selectCustomerInfo($event, customerInfoList)"
          :item-class="getRowClass"
          :hide-default-footer="true"
          item-key="id"
          class="tbl-type04 custom-scrollBox"
          style="overflow-y: scroll;max-height: 310px;"
          :items-per-page="99"
          @page-count="pageCount = $event"
        >
        </v-data-table>
        <div class="mt-5">
          <v-btn @click="moveCustomerInfo(moveType.start, customerInfoList, selectedCustomerInfo)" icon small
            ><img src="@/assets/img/ico-move-top.png" alt=""
          /></v-btn>
          <v-btn @click="moveCustomerInfo(moveType.up, customerInfoList, selectedCustomerInfo)" icon small
            ><img src="@/assets/img/ico-move-up.png" alt=""
          /></v-btn>
          <v-btn @click="moveCustomerInfo(moveType.down, customerInfoList, selectedCustomerInfo)" icon small
            ><img src="@/assets/img/ico-move-down.png" alt=""
          /></v-btn>
          <v-btn @click="moveCustomerInfo(moveType.end, customerInfoList, selectedCustomerInfo)" icon small
            ><img src="@/assets/img/ico-move-bottom.png" alt=""
          /></v-btn>
        </div>
      </div>
      <div class="info-preview" style="width:330px">
        <h4 class="tit">PREVIEW</h4>
        <div class="preview">
          <!-- <img :src="selectedCustomerInfo.content" class="img-label" /> -->
    <VueSlickCarousel :arrows="true" :dots="true" v-if="selectedCustomerInfoPreview.length" class="container">
            <div v-for="(imgages,index) in selectedCustomerInfoPreview" :key="index">
              <div><img :src="imgages.content" alt=""  style="margin-left: -16px !important; "></div>
              <div class="tagvalues" style="">{{imgages.customerInfoContentsPK.type}}"  {{imgages.customerInfoContentsPK.split}}</div>
            </div>
    </VueSlickCarousel>

        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="mt-8">
      <app-config-customer-info-add
        :stationCode="selectedCustomerInfoStore"
        :selectedareaid='areaid'
        :usage="customerInfoUsage.default"
        :update="false"
        :selectedInfo="selectedCustomerInfo"
        @reloadCustomerInformation="reloadCustomerInformation"
        :btnDisabledFileUpload="!customerInfoStoreSelected || appConfigSaveDisabled"
        :customerInfoList="customerInfoList"
      />
      <!-- Edit Button -->
      <app-config-customer-info-add
        :stationCode="selectedCustomerInfoStore"
        :selectedareaid='areaid'
        :usage="customerInfoUsage.default"
        :update="true"
        :selectedInfo="selectedCustomerInfo"
        @reloadCustomerInformation="reloadCustomerInformation"
        :btnDisabledFileUpload="!customerInfoSelected || appConfigSaveDisabled"
        :customerInfoList="customerInfoList"
      />
      <v-btn
        @click="handleCustomerInfoDelete(selectedCustomerInfo)"
        :disabled="!customerInfoSelected || appConfigSaveDisabled"
        text class="btn-s ml-2"
      >
        {{ $t("Delete") }}
      </v-btn>
      <v-btn @click="handleCustomerInfoSave(customerInfoList)" :disabled='appConfigSaveDisabled' text class="btn-s ml-2">
        {{ $t("Save") }}
      </v-btn>
    </div>
    <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />
  </div>
</template>

<script>
// Utils
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import EventBus from '@/plugins/eventBus'
// Minxins
import MsgBoxParent from '@/mixins/MsgBoxParent'
// Components
import AppConfigCustomerInfoAdd from './modal/AppConfigCustomerInfoAdd'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'CustomerInformation',
  mixins: [MsgBoxParent],
  components: { AppConfigCustomerInfoAdd, VueSlickCarousel },
  props: {
    customerInformationEnabled: {
      type: Boolean,
      default: false
    },
    selectedareaid: [Array, Object, String, Number],
    reloaddatacust: [Boolean],
    appConfigSaveDisabled1: [Boolean]
  },
  data () {
    return {
      selectedCustomerInfoStore: null,
      customerInfoStores: [],
      customerInfoHeaders: [
        {
          text: 'PRIORITY',
          align: 'start',
          sortable: false,
          value: 'priority',
          width: '25%'
        },
        {
          text: 'TITLE',
          align: 'start',
          sortable: false,
          value: 'description',
          width: '35%'
        }
        // {
        //   text: 'FILE',
        //   align: 'start',
        //   sortable: false,
        //   value: 'fileName'
        // }
      ],
      customerInfoList: [],
      selectedCustomerInfo: {},
      selectedCustomerInfoPreview: [],
      areaid: '',
      appConfigSaveDisabled: null
    }
  },
  computed: {
    moveType () {
      const moveType = {
        start: 'START',
        up: 'UP',
        down: 'DOWN',
        end: 'END'
      }
      return moveType
    },
    customerInfoUsage () {
      const customerInfoUsage = {
        default: 'DEFAULT',
        bakery: 'BAKERY'
      }
      return customerInfoUsage
    },
    customerInfoSelected () {
      console.log(this.selectedCustomerInfo)
      return !commons.isNull(this.selectedCustomerInfo.id)
    },
    // customerInfoStoreSelected () {
    //   return !commons.isNull(this.selectedCustomerInfoStore)
    // },
    customerInfoStoreSelected () {
      return !commons.isNull(this.selectedareaid)
    },
    togetcustomerinfo () {
      return this.selectedareaid
    },
    tochangeflag () {
      return this.reloaddatacust
    }
  },
  watch: {

    customerInfoList (newCustomerInfo, oldCustomerInfo) {
      if (newCustomerInfo !== oldCustomerInfo) {
        this.reloadFavoriteMenuCustomerInfo()
      }
    },
    togetcustomerinfo () {
      console.log('sdsadasdada', this.selectedareaid)
      this.areaid = this.selectedareaid
      this.handleCustomerInfoDefaultInput(this.selectedareaid)
      // this.deleteCustomerInfo(this.selectedCustomerInfo.id)
    },
    tochangeflag () {
      console.log(this.reloaddatacust)
      if (this.reloaddatacust === true) {
        this.handleCustomerInfoDefaultInput(this.selectedareaid)
      }
    }

  },
  mounted () {
    this.customerInfoStores = this.$store.state.auth.user.managedStores

    // Disable buttons
    this.$store.dispatch('auth/getDisabledBtn', '9300').then((flag) => {
      console.log('flag' + flag)
      this.appConfigSaveDisabled = this.appConfigSaveDisabled1
      // this.appConfigSaveDisabled = flag
    })
    this.handleCustomerInfoDefaultInput(this.selectedareaid)
  },
  methods: {
    // Event Listeners
    resetSelcted (arr) {
      return arr.map((el) => {
        el.selected = false
        return el
      })
    },
    async selectCustomerInfo (item, customerInfoList) {
      console.log(item)
      console.log(customerInfoList)
      // this.selectedCustomerInfo = {}
      this.appConfigSaveDisabled = this.appConfigSaveDisabled1

      this.selectedCustomerInfoPreview = []
      this.customerInfoList = await this.resetSelcted(customerInfoList)
      console.log(this.customerInfoList.length)
      if (this.customerInfoList.length !== 0) {
        item.selected = true
        this.selectedCustomerInfo = item
        this.selectedCustomerInfoPreview = item.contents
      }
    },
    async reloadCustomerInformation (usage) {
      if (usage === this.customerInfoUsage.default) {
        console.log(this.selectedCustomerInfoStore)
        await this.handleCustomerInfoDefaultInput(this.selectedareaid)
        this.selectedCustomerInfo = {}
      } else {
        await this.handleCustomerInfoBakeryInput(this.selectedBakeryInfoStore)
        this.selectedBakeryInfo = {}
      }
      this.syncCustomerInfoPriority()
      this.handleCustomerInfoSave(this.customerInfoList, false)
    },
    async handleCustomerInfoDefaultInput (stationCode) {
      this.customerInfoList = await this.getCustomerInfo(
        stationCode,
        this.customerInfoUsage.default
      ) || []
      if (this.customerInfoList?.length > 0) this.selectedCustomerInfo = this.customerInfoList[0]
      this.selectCustomerInfo(this.customerInfoList[0], this.customerInfoList)
      this.selectedCustomerInfo.selected = true
    },
    async handleCustomerInfoDelete (selectedCustomerInfo) {
      await this.deleteCustomerInfo(selectedCustomerInfo.id, selectedCustomerInfo.usage)
      this.customerInfoList = this.customerInfoList.filter(info => info !== selectedCustomerInfo)
      this.syncCustomerInfoPriority()
      this.handleCustomerInfoSave(this.customerInfoList, false)
    },
    handleCustomerInfoSave (customerInfoList, showResult) {
      this.saveCustomerInfo(customerInfoList.map(customerInfo => {
        delete customerInfo.selected
        return customerInfo
      }), showResult)
    },
    // API
    getCustomerInfo (data, usage) {
      console.log(typeof (data))
      const valuetype = typeof (data)
      console.log(valuetype)
      var config
      if (valuetype === 'number' || valuetype === undefined) {
        config = {
          params: {
            // usage, // Default || Bakery
            contents: 'true',
            stationCode: null,
            areaId: data

          }
        }
      } else {
        config = {
          params: {
            // usage, // Default || Bakery
            contents: 'true',
            stationCode: data,
            areaId: null

          }
        }
      }

      return this.$utils
        .callAxios(
          codes.requests.getCustomerInfo.method,
					`${codes.requests.getCustomerInfo.url}`,
					config
        )
        .then((res) => {
          console.log(res.data)
          const customerid = []
          for (let i = 0; i < res.data.customerInfoList.length; i++) {
            // if (res.data.customerInfoList[i].id) {
            customerid.push(res.data.customerInfoList[i].id)
            // }
          }
          console.log(customerid)
          this.areaid = this.selectedareaid
          localStorage.setItem('customerinfoids', JSON.stringify(customerid))
          return res.data.customerInfoList
        })
        .catch((error) => {
          console.debug(`Could not find customer information. error: ${error}`)
        })
    },
    deleteCustomerInfo (id) {
      const config = { params: {} }
      this.$utils
        .callAxios(
          codes.requests.deleteCustomerInfo.method,
					`${codes.requests.deleteCustomerInfo.url}/${id}`,
					config
        )
        .then(res => {
          this.openMsgBox(this.$t('Customer information has been deleted successfully.'))
          this.getCustomerInfo(this.selectedareaid)
          this.selectedCustomerInfo = {}
          this.selectedCustomerInfoPreview = {}
          EventBus.$emit('reload')
        })
        .catch((error) => {
          console.debug(`Failed to delete customer information. error: ${error}`)
          this.openMsgBox(this.$t('Fail to delete customer infomation.'))
        })
    },
    saveCustomerInfo (customerInfoList, showResult = true) {
      const config = { params: {} }
      this.$utils
        .callAxiosWithBody(
          codes.requests.saveCustomerInfo.method,
          codes.requests.saveCustomerInfo.url,
          customerInfoList,
          config
        )
        .then((res) => {
          if (showResult) {
            this.openMsgBox(
              this.$t('Customer information has been saved successfully.')
            )
          }
        })
        .catch((error) => {
          if (showResult) this.openMsgBox(this.$t('Fail to save customer infomation.'))
          console.debug(`Failed to save customer information. error: ${error}`)
        })
    },
    // etc
    getRowClass: commons.getRowClass,
    syncCustomerInfoPriority () {
      // customerInfoList의 각 요소들의 priority값을 요소의 현재 위치+1로 맞춘다.
      this.customerInfoList = this.customerInfoList.map((info, idx) => {
        info.priority = idx + 1
        return info
      }) || []
    },
    reloadFavoriteMenuCustomerInfo () {
      // Favorite Menu에서 customerInfo radio 버튼 클릭한 경우
      // Custoemr Info List가 Favorite Menu에서 선택한 Store 기준으로
      // 보여진다. 이정보를 update한다. 현재는 AppConfig에서만 사용하지만
      // 다른 페이지에서도 사용하게 될 경우에는 Vuex에서 상태관리해야한다.
      EventBus.$emit('reloadFavoriteMenuCustomerInfo')
    },
    async moveCustomerInfo (moveType, customerInfoList, selectedCustomerInfo) {
      const selectedIdx = customerInfoList.indexOf(selectedCustomerInfo)
      const total = customerInfoList.length
      switch (moveType) {
        case this.moveType.start:
          for (let i = 0; i < selectedIdx; i++) {
            commons.swap(customerInfoList, selectedIdx - i, selectedIdx - i - 1)
          }
          break
        case this.moveType.up:
          if (selectedIdx > 0) commons.swap(customerInfoList, selectedIdx, selectedIdx - 1)
          break
        case this.moveType.down:
          if (selectedIdx < total - 1) commons.swap(customerInfoList, selectedIdx, selectedIdx + 1)
          break
        case this.moveType.end:
          // for (let i = 0; i < total - 1; i++) {
          //   commons.swap(customerInfoList, selectedIdx + i, selectedIdx + i + 1)
          // }
          if (selectedIdx === 0) {
            for (let i = 0; i < total - 1; i++) {
              console.log(i)
              commons.swap(customerInfoList, selectedIdx + i, selectedIdx + i + 1)
            }
          } else {
            if (selectedIdx < total - 1) {
              for (let i = selectedIdx; i < total - 1; i++) {
                console.log(i)
                commons.swap(customerInfoList, i, i + 1)
              }
            }
          }
          break
        default:
          break
      }
      await this.$nextTick() // CustomerInfoList 배열 요소 위치이동 DOM 반영을 기다림
      this.syncCustomerInfoPriority() // 이동한 위치에 맞게 각 요소의 priority 변경.
    }
  }
}
</script>

<style>
.row-class:hover {
  cursor: pointer;
}
.active > .text-start {
  background: #031e37 !important;
  color: #ffffff !important;
}
.customInfo .info-preview .preview {
    justify-content: center;
    align-items: center;
    height: 260px;
    background: #ECECEC;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 44px!important;
    transform: translateZ(0);
}
.slick-prev {
    left:0!important;
        position: absolute;
    z-index: 1;
}
.slick-next{
   right: 0 !important;
}
.slick-prev:before {
    background-image: url('~@/assets/img/previous.png');
    background-size: 20px 28px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content:"";
    margin-left: 18px;
      margin-top: -8px;
}
.slick-next:before {
    background-image: url('~@/assets/img/next.png');
    background-size: 20px 28px;
    display: inline-block;
    width: 50px;
    height: 50px;
    content:"";
    margin-left: -24px;
        margin-top: -9px;

}
    .slick-track {
    position: relative;
    top: 0;
    display: block;
    transform: translateZ(0);
    text-align: -webkit-center !;
}
.container {
    width: 100%;
    padding: 22px!important;
    margin-right: auto;
    margin-left: auto;
}
.slick-dots {
    position: absolute;
    bottom: 36px;
    right: 0;
    display: block;
    width: 100%;
    padding: 0  !important;
    margin: 0;
    list-style: none;
    text-align: center;
}
 .slick-dots li button:before
    {
        font-size: 10px;
        line-height: 10px;
        color: #9c9898;

    }

    .slick-slide img {
    display: block;
    width: 80%  !important;
    height: 169px !important;
    margin-left: -15px !important;
    }

    .tagvalues{
    width: 80% !important;
    text-align: center!important;
    font-weight: bold !important;
        font-size: var(--font14);
        color: #4D4F5C !important;
        margin-top: 8px;
            margin-left: -17px;
    }
</style>
