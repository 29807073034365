var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      key: _vm.newExtractionVisible,
      attrs: { width: "500", scrollable: "", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn-s",
                      attrs: {
                        disabled: _vm.productIdExtractionDisabled,
                        text: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.newExtractionVisible,
        callback: function($$v) {
          _vm.newExtractionVisible = $$v
        },
        expression: "newExtractionVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-system" }),
              _vm._v(_vm._s(_vm.$t("New Extraction")) + " ")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                ref: _vm.prodIdExtRefNames.prefix,
                staticClass: "form-input",
                attrs: {
                  rules: [_vm.rules.blank],
                  label: _vm.$t("Prifix") + " *",
                  placeholder: "" + _vm.$t("Input the Prefix"),
                  clearable: "",
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.newExtraction.prefix,
                  callback: function($$v) {
                    _vm.$set(_vm.newExtraction, "prefix", $$v)
                  },
                  expression: "newExtraction.prefix"
                }
              }),
              _c("v-text-field", {
                ref: _vm.prodIdExtRefNames.validDigits,
                staticClass: "form-input mt-5",
                attrs: {
                  rules: [_vm.rules.blank],
                  type: "number",
                  label: _vm.$t("Valid Digits") + " *",
                  placeholder: "" + _vm.$t("Input the Valid Digits"),
                  clearable: "",
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                on: { keyup: _vm.handleKeyUp },
                model: {
                  value: _vm.newExtraction.validDigits,
                  callback: function($$v) {
                    _vm.$set(_vm.newExtraction, "validDigits", $$v)
                  },
                  expression: "newExtraction.validDigits"
                }
              }),
              _c("v-text-field", {
                ref: _vm.prodIdExtRefNames.paddingValue,
                staticClass: "form-input mt-5",
                attrs: {
                  rules: [_vm.rules.blank],
                  label: _vm.$t("Padding Value") + " *",
                  placeholder: "" + _vm.$t("Input the Padding Value"),
                  clearable: "",
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.newExtraction.paddingValue,
                  callback: function($$v) {
                    _vm.$set(_vm.newExtraction, "paddingValue", $$v)
                  },
                  expression: "newExtraction.paddingValue"
                }
              }),
              _c("v-text-field", {
                ref: _vm.prodIdExtRefNames.totalDigits,
                staticClass: "form-input mt-5",
                attrs: {
                  rules: [_vm.rules.blank, _vm.rules.totalDigits],
                  type: "number",
                  label: _vm.$t("Total Digits") + " *",
                  placeholder: "" + _vm.$t("Input the Total Digits"),
                  clearable: "",
                  outlined: "",
                  dense: "",
                  "hide-details": ""
                },
                on: { keyup: _vm.handleKeyUp },
                model: {
                  value: _vm.newExtraction.totalDigits,
                  callback: function($$v) {
                    _vm.$set(_vm.newExtraction, "totalDigits", $$v)
                  },
                  expression: "newExtraction.totalDigits"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center mt-10" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "" },
                  on: { click: _vm.handleSaveNewExtraction }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "" },
                  on: { click: _vm.handleCloseNewExtraction }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }