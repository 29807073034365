var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": _vm.maxlimit ? true : false,
        width: "750",
        scrollable: "",
        persistent: ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn-s ml-2",
                      attrs: { disabled: _vm.btnDisabledFileUpload, text: "" },
                      on: {
                        click: function($event) {
                          return _vm.handleAddClick(_vm.update)
                        }
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t(_vm.update ? "Edit" : "Add")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.fileUploadVisible,
        callback: function($$v) {
          _vm.fileUploadVisible = $$v
        },
        expression: "fileUploadVisible"
      }
    },
    [
      !_vm.maxlimit
        ? _c("v-card", { staticClass: " add_store_popup" }, [
            _c(
              "div",
              { staticClass: "popup" },
              [
                _c("v-card-title", [
                  _c("h3", { staticClass: "page-title-bar" }, [
                    _c("i", {
                      staticClass: "ico ico-system",
                      staticStyle: { "margin-left": "-25px" }
                    }),
                    _vm._v(_vm._s(_vm.$t("File Upload")) + " ")
                  ])
                ]),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticStyle: { "margin-left": "-25px" },
                        attrs: { cols: "12", sm: "7" }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "form-input",
                          attrs: {
                            label: _vm.$t("Title"),
                            placeholder: _vm.$t("Input the title"),
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.customerInfoInput.description,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.customerInfoInput,
                                "description",
                                $$v
                              )
                            },
                            expression: "customerInfoInput.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("hr", {}),
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#ececec",
                  width: "750px",
                  "overflow-x": "hidden !important"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "popup" },
                  [
                    _c("span", { staticStyle: { "margin-left": "-25px" } }, [
                      _vm._v(_vm._s(_vm.$t("File Upload")))
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "45px",
                                  "margin-left": "-10px",
                                  "margin-right": "10px"
                                }
                              },
                              [_c("b", [_vm._v('2.6"')])]
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("No Split"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_1,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_1",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_1"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "45px",
                                  "margin-left": "-10px",
                                  "margin-right": "4px"
                                }
                              },
                              [_c("b", [_vm._v('5.85"')])]
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("No Split"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_2,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_2",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_2"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("Split 2"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_3,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_3",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_3"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "3" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("Split 3"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_4,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_4",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_4"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "45px",
                                  "margin-left": "-10px",
                                  "margin-right": "10px"
                                }
                              },
                              [_c("b", [_vm._v('9.7"')])]
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("No Split"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_5,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_5",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_5"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("Split 2"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_6,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_6",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_6"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "45px",
                                  "margin-left": "-7px"
                                },
                                attrs: { "align:left": "" }
                              },
                              [_c("b", [_vm._v('12.2"')])]
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("No Split"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_7,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_7",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_7"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-file-input", {
                                  staticClass: "form-file",
                                  attrs: {
                                    label: _vm.$t("Split 2"),
                                    placeholder: "" + _vm.$t("Select file"),
                                    multiple: false,
                                    outlined: "",
                                    dense: "",
                                    "hide-details": "",
                                    "prepend-icon": "",
                                    flat: ""
                                  },
                                  on: { keydown: _vm.clearTabindex },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c("img", {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                src: require("@/assets/img/ico-upload-file.gif")
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1414826615
                                  ),
                                  model: {
                                    value: _vm.customerInfoInput.file_8,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.customerInfoInput,
                                        "file_8",
                                        $$v
                                      )
                                    },
                                    expression: "customerInfoInput.file_8"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              { staticStyle: { "background-color": "#ececec" } },
              [
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleUploadCustomerInfo()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Upload")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.cancelFileUpload()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("msg-box", {
        attrs: { msgBox: _vm.msgBox },
        on: { closeMsgBox: _vm.closeMsgBox }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }