<template>
  <v-dialog
    :key="newExtractionVisible"
    v-model="newExtractionVisible"
    width="500"
    scrollable
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="productIdExtractionDisabled"
        v-bind="attrs"
        v-on="on"
        text
        class="btn-s"
        >{{ $t('Add') }}</v-btn
      >
    </template>

    <v-card class="popup add_store_popup">
      <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-system"></i>{{ $t('New Extraction') }}
      </h3>
</v-card-title>
<v-card-text >
      <v-text-field
        v-model="newExtraction.prefix"
        :ref="prodIdExtRefNames.prefix"
        :rules="[rules.blank]"
        :label="`${$t('Prifix')} *`"
        :placeholder="`${$t('Input the Prefix')}`"
        class="form-input"
        clearable
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-text-field
        v-model="newExtraction.validDigits"
        @keyup="handleKeyUp"
        :ref="prodIdExtRefNames.validDigits"
        :rules="[rules.blank]"
        type="number"
        :label="`${$t('Valid Digits')} *`"
        :placeholder="`${$t('Input the Valid Digits')}`"
        class="form-input mt-5"
        clearable
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-text-field
        v-model="newExtraction.paddingValue"
        :ref="prodIdExtRefNames.paddingValue"
        :rules="[rules.blank]"
        :label="`${$t('Padding Value')} *`"
        :placeholder="`${$t('Input the Padding Value')}`"
        class="form-input mt-5"
        clearable
        outlined
        dense
        hide-details
      ></v-text-field>
      <v-text-field
        v-model="newExtraction.totalDigits"
        @keyup="handleKeyUp"
        :ref="prodIdExtRefNames.totalDigits"
        :rules="[rules.blank, rules.totalDigits]"
        type="number"
        :label="`${$t('Total Digits')} *`"
        :placeholder="`${$t('Input the Total Digits')}`"
        class="form-input mt-5"
        clearable
        outlined
        dense
        hide-details
      ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-center mt-10">
        <v-btn text @click="handleSaveNewExtraction" class="btn">{{
          $t('Save')
        }}</v-btn>
        <v-btn text @click="handleCloseNewExtraction" class="btn">{{
          $t('Close')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import commons from '@/plugins/commons'

export default {
  name: 'AppConfigNewExtraction',
  props: {
    productIdExtractionDisabled: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      newExtractionVisible: false,
      newExtraction: {
        prefix: null,
        validDigits: null,
        paddingValue: null,
        totalDigits: null
      },
      prodIdExtRefNames: {
        prefix: 'prodIdExtPrefix',
        validDigits: 'prodIdExtValidDigits',
        paddingValue: 'prodIdExtPaddingValue',
        totalDigits: 'prodIdExtTotalDigits'
      },
      rules: {
        blank: value => commons.isValidStr(value),
        totalDigits: value => this.newExtraction.validDigits * 1 < value * 1
      }
    }
  },
  methods: {
    // etc
    handleKeyUp (e) {
      const regex = /[ㄱ-ㅎㅏ-ㅣ가-힣|A-Za-z|.+_=!@#$%^&*()-]/g
      e.target.value = e.target.value.replace(regex, '')
    },
    getBlankProdIdExtRefs (productIdExtraction) {
      const refs = []
      let ref = null
      for (const key in productIdExtraction) {
        if (!commons.isValidStr(productIdExtraction[key])) {
          ref = this.$refs[this.prodIdExtRefNames[key]]
          if (!commons.isNull(ref)) refs.push(ref)
        }
      }
      return refs
    },
    validateRefs (blankProdIdExtRefs) {
      for (const ref of blankProdIdExtRefs) {
        ref.validate()
      }
    },
    resetNewExtraction () {
      this.newExtraction = {
        prefix: null,
        validDigits: null,
        paddingValue: null,
        totalDigits: null
      }
    },
    // Event handlers
    handleSaveNewExtraction () {
      // this.$refs[this.prodIdExtRefNames.validDigits].validate(true)
      // this.$refs[this.prodIdExtRefNames.totalDigits].validate(true)

      const productIdExtraction = this.newExtraction
      const blankProdIdExtRefs = this.getBlankProdIdExtRefs(productIdExtraction)
      console.log(blankProdIdExtRefs)
      this.validateRefs(blankProdIdExtRefs)
      if (blankProdIdExtRefs.length > 0) {
        blankProdIdExtRefs[0].focus()
        return
      } else if (
        this.newExtraction.validDigits * 1 >=
        this.newExtraction.totalDigits * 1
      ) {
        const ref = this.$refs[this.prodIdExtRefNames.totalDigits]
        ref.validate(true)
        return
      }
      this.newExtractionVisible = false
      this.$emit('addNewExtraction', this.newExtraction)
      this.newExtraction = {
        prefix: null,
        validDigits: null,
        paddingValue: null,
        totalDigits: null
      }
    },
    handleCloseNewExtraction () {
      this.resetNewExtraction()
      this.newExtractionVisible = false
    }
  }
}
</script>

<style scoped>
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }
</style>
