// 1. include this mixin in your component.
// 2. include <msg-box
//              :msgBox="msgBox"
//              @closeMsgBox="closeMsgBox"
//            />

import MsgBox from '@/components/modal/MsgBox'

const MsgBoxParent = {
  components: {
    MsgBox
  },
  props: {
  },
  data () {
    return {
      msgBox: {
        msgBoxVisible: false,
        msg: null
      }
    }
  },
  methods: {
    openMsgBox (msg) {
      this.msgBox = {
        msgBoxVisible: true,
        msg
      }
    },
    closeMsgBox () {
      this.msgBox = {
        msgBoxVisible: false,
        msg: null
      }
    }
  }
}

export default MsgBoxParent
