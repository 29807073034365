var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "350" },
      on: { "click:outside": _vm.closeAppConfigMsgBox },
      model: {
        value: _vm.msgModalVisible,
        callback: function($$v) {
          _vm.msgModalVisible = $$v
        },
        expression: "msgModalVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup-success" },
        [
          _c("i", { staticClass: "ico-success" }, [
            _c("img", {
              attrs: { src: require("@/assets/img/ico-success.png"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "msg" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("Success")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("The setting successfully save.")))])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center btnArea" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.closeAppConfigMsgBox }
                },
                [_vm._v(" " + _vm._s(_vm.$t("OK")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }