var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.favoriteButtonEnabled
    ? _c(
        "div",
        { staticStyle: { "max-width": "970px" } },
        [
          _c(
            "h3",
            {
              staticClass: "tit-group-bg mt-10",
              staticStyle: { "font-weight": "bold", color: "#001e38" }
            },
            [_vm._v(_vm._s(_vm.$t("WinCE App : Favorite Menu")))]
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("h4", [_vm._v("Favorite 1")]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-3",
                      attrs: { dense: "", "hide-details": "", row: "" },
                      on: {
                        change: function($event) {
                          return _vm.handleTypeChange(
                            $event,
                            _vm.favoriteMenu[0]
                          )
                        }
                      },
                      model: {
                        value: _vm.favoriteMenu[0].type,
                        callback: function($$v) {
                          _vm.$set(_vm.favoriteMenu[0], "type", $$v)
                        },
                        expression: "favoriteMenu[0].type"
                      }
                    },
                    _vm._l(Object.values(_vm.type), function(option) {
                      return _c("v-radio", {
                        key: option.text,
                        staticClass: "form-radio flat",
                        attrs: { value: option.value, label: option.text }
                      })
                    }),
                    1
                  ),
                  _c("v-select", {
                    staticClass: "form-select mt-4",
                    attrs: {
                      items: _vm.getFavoriteMenuOptions(_vm.favoriteMenu[0]),
                      "item-text": "description",
                      disabled: _vm.favoriteMenu[0].type === "NONE",
                      placeholder: _vm.getFavoriteBtnPlaceholder(
                        _vm.favoriteMenu[0].type
                      ),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      solo: ""
                    },
                    model: {
                      value: _vm.favoriteMenu[0].menuOrDescription,
                      callback: function($$v) {
                        _vm.$set(_vm.favoriteMenu[0], "menuOrDescription", $$v)
                      },
                      expression: "favoriteMenu[0].menuOrDescription"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("h4", [_vm._v("Favorite 2")]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-3",
                      attrs: { dense: "", "hide-details": "", row: "" },
                      on: {
                        change: function($event) {
                          return _vm.handleTypeChange(
                            $event,
                            _vm.favoriteMenu[1]
                          )
                        }
                      },
                      model: {
                        value: _vm.favoriteMenu[1].type,
                        callback: function($$v) {
                          _vm.$set(_vm.favoriteMenu[1], "type", $$v)
                        },
                        expression: "favoriteMenu[1].type"
                      }
                    },
                    _vm._l(Object.values(_vm.type), function(option) {
                      return _c("v-radio", {
                        key: option.text,
                        staticClass: "form-radio flat",
                        attrs: { value: option.value, label: option.text }
                      })
                    }),
                    1
                  ),
                  _c("v-select", {
                    staticClass: "form-select mt-4",
                    attrs: {
                      items: _vm.getFavoriteMenuOptions(_vm.favoriteMenu[1]),
                      "item-text": "description",
                      disabled: _vm.favoriteMenu[1].type === "NONE",
                      placeholder: _vm.getFavoriteBtnPlaceholder(
                        _vm.favoriteMenu[1].type
                      ),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      solo: ""
                    },
                    model: {
                      value: _vm.favoriteMenu[1].menuOrDescription,
                      callback: function($$v) {
                        _vm.$set(_vm.favoriteMenu[1], "menuOrDescription", $$v)
                      },
                      expression: "favoriteMenu[1].menuOrDescription"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("h4", [_vm._v("Favorite 3")]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-3",
                      attrs: { dense: "", "hide-details": "", row: "" },
                      on: {
                        change: function($event) {
                          return _vm.handleTypeChange(
                            $event,
                            _vm.favoriteMenu[2]
                          )
                        }
                      },
                      model: {
                        value: _vm.favoriteMenu[2].type,
                        callback: function($$v) {
                          _vm.$set(_vm.favoriteMenu[2], "type", $$v)
                        },
                        expression: "favoriteMenu[2].type"
                      }
                    },
                    _vm._l(Object.values(_vm.type), function(option) {
                      return _c("v-radio", {
                        key: option.text,
                        staticClass: "form-radio flat",
                        attrs: { value: option.value, label: option.text }
                      })
                    }),
                    1
                  ),
                  _c("v-select", {
                    staticClass: "form-select mt-4",
                    attrs: {
                      items: _vm.getFavoriteMenuOptions(_vm.favoriteMenu[2]),
                      "item-text": "description",
                      disabled: _vm.favoriteMenu[2].type === "NONE",
                      placeholder: _vm.getFavoriteBtnPlaceholder(
                        _vm.favoriteMenu[2].type
                      ),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      solo: ""
                    },
                    model: {
                      value: _vm.favoriteMenu[2].menuOrDescription,
                      callback: function($$v) {
                        _vm.$set(_vm.favoriteMenu[2], "menuOrDescription", $$v)
                      },
                      expression: "favoriteMenu[2].menuOrDescription"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-2" },
            [
              _c(
                "v-col",
                [
                  _c("h4", [_vm._v("Favorite 4")]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-3",
                      attrs: { dense: "", "hide-details": "", row: "" },
                      on: {
                        change: function($event) {
                          return _vm.handleTypeChange(
                            $event,
                            _vm.favoriteMenu[3]
                          )
                        }
                      },
                      model: {
                        value: _vm.favoriteMenu[3].type,
                        callback: function($$v) {
                          _vm.$set(_vm.favoriteMenu[3], "type", $$v)
                        },
                        expression: "favoriteMenu[3].type"
                      }
                    },
                    _vm._l(Object.values(_vm.type), function(option) {
                      return _c("v-radio", {
                        key: option.text,
                        staticClass: "form-radio flat",
                        attrs: { value: option.value, label: option.text }
                      })
                    }),
                    1
                  ),
                  _c("v-select", {
                    staticClass: "form-select mt-4",
                    attrs: {
                      "item-text": "description",
                      disabled: _vm.favoriteMenu[3].type === "NONE",
                      items: _vm.getFavoriteMenuOptions(_vm.favoriteMenu[3]),
                      placeholder: _vm.getFavoriteBtnPlaceholder(
                        _vm.favoriteMenu[3].type
                      ),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      solo: ""
                    },
                    model: {
                      value: _vm.favoriteMenu[3].menuOrDescription,
                      callback: function($$v) {
                        _vm.$set(_vm.favoriteMenu[3], "menuOrDescription", $$v)
                      },
                      expression: "favoriteMenu[3].menuOrDescription"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("h4", [_vm._v("Favorite 5")]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-3",
                      attrs: { dense: "", "hide-details": "", row: "" },
                      on: {
                        change: function($event) {
                          return _vm.handleTypeChange(
                            $event,
                            _vm.favoriteMenu[4]
                          )
                        }
                      },
                      model: {
                        value: _vm.favoriteMenu[4].type,
                        callback: function($$v) {
                          _vm.$set(_vm.favoriteMenu[4], "type", $$v)
                        },
                        expression: "favoriteMenu[4].type"
                      }
                    },
                    _vm._l(Object.values(_vm.type), function(option) {
                      return _c("v-radio", {
                        key: option.text,
                        staticClass: "form-radio flat",
                        attrs: { value: option.value, label: option.text }
                      })
                    }),
                    1
                  ),
                  _c("v-select", {
                    staticClass: "form-select mt-4",
                    attrs: {
                      items: _vm.getFavoriteMenuOptions(_vm.favoriteMenu[4]),
                      "item-text": "description",
                      disabled: _vm.favoriteMenu[4].type === "NONE",
                      placeholder: _vm.getFavoriteBtnPlaceholder(
                        _vm.favoriteMenu[4].type
                      ),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      solo: ""
                    },
                    model: {
                      value: _vm.favoriteMenu[4].menuOrDescription,
                      callback: function($$v) {
                        _vm.$set(_vm.favoriteMenu[4], "menuOrDescription", $$v)
                      },
                      expression: "favoriteMenu[4].menuOrDescription"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("h4", [_vm._v("Favorite 6")]),
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "mt-3",
                      attrs: { dense: "", "hide-details": "", row: "" },
                      on: {
                        change: function($event) {
                          return _vm.handleTypeChange(
                            $event,
                            _vm.favoriteMenu[5]
                          )
                        }
                      },
                      model: {
                        value: _vm.favoriteMenu[5].type,
                        callback: function($$v) {
                          _vm.$set(_vm.favoriteMenu[5], "type", $$v)
                        },
                        expression: "favoriteMenu[5].type"
                      }
                    },
                    _vm._l(Object.values(_vm.type), function(option) {
                      return _c("v-radio", {
                        key: option.text,
                        staticClass: "form-radio flat",
                        attrs: { value: option.value, label: option.text }
                      })
                    }),
                    1
                  ),
                  _c("v-select", {
                    staticClass: "form-select mt-4",
                    attrs: {
                      "item-text": "description",
                      disabled: _vm.favoriteMenu[5].type === "NONE",
                      items: _vm.getFavoriteMenuOptions(_vm.favoriteMenu[5]),
                      placeholder: _vm.getFavoriteBtnPlaceholder(
                        _vm.favoriteMenu[5].type
                      ),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      solo: ""
                    },
                    model: {
                      value: _vm.favoriteMenu[5].menuOrDescription,
                      callback: function($$v) {
                        _vm.$set(_vm.favoriteMenu[5], "menuOrDescription", $$v)
                      },
                      expression: "favoriteMenu[5].menuOrDescription"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-s ml-2",
                  attrs: {
                    disabled: _vm.btnDisabledDashboardSettings,
                    text: ""
                  },
                  on: { click: _vm.hadleSaveBtnClick }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
              )
            ],
            1
          ),
          _c("msg-box", {
            attrs: { msgBox: _vm.msgBox },
            on: { closeMsgBox: _vm.closeMsgBox }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }