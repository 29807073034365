var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customerInformationEnabled
    ? _c(
        "div",
        { staticStyle: { "max-width": "970px" } },
        [
          _c(
            "h3",
            {
              staticClass: "tit-group-bg mt-10",
              staticStyle: { "font-weight": "bold", color: "#001e38" }
            },
            [_vm._v(_vm._s(_vm.$t("Customer Information")))]
          ),
          _c(
            "div",
            { staticClass: "customInfo mt-7", staticStyle: { padding: "5px" } },
            [
              _c(
                "div",
                { staticClass: "info-list", staticStyle: { width: "255px" } },
                [
                  _c("v-data-table", {
                    staticClass: "tbl-type04 custom-scrollBox",
                    staticStyle: {
                      "overflow-y": "scroll",
                      "max-height": "310px"
                    },
                    attrs: {
                      headers: _vm.customerInfoHeaders,
                      items: _vm.customerInfoList,
                      "item-class": _vm.getRowClass,
                      "hide-default-footer": true,
                      "item-key": "id",
                      "items-per-page": 99
                    },
                    on: {
                      "click:row": function($event) {
                        return _vm.selectCustomerInfo(
                          $event,
                          _vm.customerInfoList
                        )
                      },
                      "page-count": function($event) {
                        _vm.pageCount = $event
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.moveCustomerInfo(
                                _vm.moveType.start,
                                _vm.customerInfoList,
                                _vm.selectedCustomerInfo
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/ico-move-top.png"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.moveCustomerInfo(
                                _vm.moveType.up,
                                _vm.customerInfoList,
                                _vm.selectedCustomerInfo
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/ico-move-up.png"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.moveCustomerInfo(
                                _vm.moveType.down,
                                _vm.customerInfoList,
                                _vm.selectedCustomerInfo
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/ico-move-down.png"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.moveCustomerInfo(
                                _vm.moveType.end,
                                _vm.customerInfoList,
                                _vm.selectedCustomerInfo
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/ico-move-bottom.png"),
                              alt: ""
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "info-preview",
                  staticStyle: { width: "330px" }
                },
                [
                  _c("h4", { staticClass: "tit" }, [_vm._v("PREVIEW")]),
                  _c(
                    "div",
                    { staticClass: "preview" },
                    [
                      _vm.selectedCustomerInfoPreview.length
                        ? _c(
                            "VueSlickCarousel",
                            {
                              staticClass: "container",
                              attrs: { arrows: true, dots: true }
                            },
                            _vm._l(_vm.selectedCustomerInfoPreview, function(
                              imgages,
                              index
                            ) {
                              return _c("div", { key: index }, [
                                _c("div", [
                                  _c("img", {
                                    staticStyle: {
                                      "margin-left": "-16px !important"
                                    },
                                    attrs: { src: imgages.content, alt: "" }
                                  })
                                ]),
                                _c("div", { staticClass: "tagvalues" }, [
                                  _vm._v(
                                    _vm._s(
                                      imgages.customerInfoContentsPK.type
                                    ) +
                                      '" ' +
                                      _vm._s(
                                        imgages.customerInfoContentsPK.split
                                      )
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c("br"),
          _c("br"),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("app-config-customer-info-add", {
                attrs: {
                  stationCode: _vm.selectedCustomerInfoStore,
                  selectedareaid: _vm.areaid,
                  usage: _vm.customerInfoUsage.default,
                  update: false,
                  selectedInfo: _vm.selectedCustomerInfo,
                  btnDisabledFileUpload:
                    !_vm.customerInfoStoreSelected || _vm.appConfigSaveDisabled,
                  customerInfoList: _vm.customerInfoList
                },
                on: { reloadCustomerInformation: _vm.reloadCustomerInformation }
              }),
              _c("app-config-customer-info-add", {
                attrs: {
                  stationCode: _vm.selectedCustomerInfoStore,
                  selectedareaid: _vm.areaid,
                  usage: _vm.customerInfoUsage.default,
                  update: true,
                  selectedInfo: _vm.selectedCustomerInfo,
                  btnDisabledFileUpload:
                    !_vm.customerInfoSelected || _vm.appConfigSaveDisabled,
                  customerInfoList: _vm.customerInfoList
                },
                on: { reloadCustomerInformation: _vm.reloadCustomerInformation }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "btn-s ml-2",
                  attrs: {
                    disabled:
                      !_vm.customerInfoSelected || _vm.appConfigSaveDisabled,
                    text: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleCustomerInfoDelete(
                        _vm.selectedCustomerInfo
                      )
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn-s ml-2",
                  attrs: { disabled: _vm.appConfigSaveDisabled, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleCustomerInfoSave(_vm.customerInfoList)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
              )
            ],
            1
          ),
          _c("msg-box", {
            attrs: { msgBox: _vm.msgBox },
            on: { closeMsgBox: _vm.closeMsgBox }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }