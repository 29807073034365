<template>
  <div v-if="favoriteButtonEnabled" style="max-width: 970px">
    <h3 class="tit-group-bg mt-10" style="font-weight:bold;color: #001e38;">{{ $t("WinCE App : Favorite Menu") }}</h3>
    <!-- <div class="form-group mt-5">
      <span class="label-txt ml-5">{{ $t("Store") }}</span>
      <v-select
        v-model="selectedFavoriteMenuStore"
        @input="handleFavoriteMenuStoreInput"
        :items="favoriteMenuStores"
        item-text="name"
        item-value="code"
        outlined
        dense
        hide-details
        solo
        placeholder="Select the menu"
        class="form-select ml-7"
      ></v-select>
    </div> -->
    <v-row>
      <v-col>
        <h4>Favorite 1</h4>
        <v-radio-group
          v-model="favoriteMenu[0].type"
          @change="handleTypeChange($event, favoriteMenu[0])"
          class="mt-3" dense hide-details row
        >
          <v-radio
            v-for="option of Object.values(type)"
            :value="option.value"
            :key="option.text"
            :label="option.text"
            class="form-radio flat"
          ></v-radio>
        </v-radio-group>
        <v-select
          v-model="favoriteMenu[0].menuOrDescription"
          :items="getFavoriteMenuOptions(favoriteMenu[0])"
          item-text='description'
          :disabled="favoriteMenu[0].type==='NONE'"
          :placeholder="getFavoriteBtnPlaceholder(favoriteMenu[0].type)"
          class="form-select mt-4"
          outlined
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <h4>Favorite 2</h4>
        <v-radio-group
          v-model="favoriteMenu[1].type"
          @change="handleTypeChange($event, favoriteMenu[1])"
          class="mt-3" dense hide-details row
        >
          <v-radio
            v-for="option of Object.values(type)"
            :value="option.value"
            :key="option.text"
            :label="option.text"
            class="form-radio flat"
          ></v-radio>
        </v-radio-group>
        <v-select
          v-model="favoriteMenu[1].menuOrDescription"
          :items="getFavoriteMenuOptions(favoriteMenu[1])"
          item-text='description'
          :disabled="favoriteMenu[1].type==='NONE'"
          :placeholder="getFavoriteBtnPlaceholder(favoriteMenu[1].type)"
          class="form-select mt-4"
          outlined
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <h4>Favorite 3</h4>
        <v-radio-group
          v-model="favoriteMenu[2].type"
          @change="handleTypeChange($event, favoriteMenu[2])"
          class="mt-3" dense hide-details row
        >
          <v-radio
            v-for="option of Object.values(type)"
            :value="option.value"
            :key="option.text"
            :label="option.text"
            class="form-radio flat"
          ></v-radio>
        </v-radio-group>
        <v-select
          v-model="favoriteMenu[2].menuOrDescription"
          :items="getFavoriteMenuOptions(favoriteMenu[2])"
          item-text='description'
          :disabled="favoriteMenu[2].type==='NONE'"
          :placeholder="getFavoriteBtnPlaceholder(favoriteMenu[2].type)"
          class="form-select mt-4"
          outlined
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <h4>Favorite 4</h4>
        <v-radio-group
          v-model="favoriteMenu[3].type"
          @change="handleTypeChange($event, favoriteMenu[3])"
          class="mt-3" dense hide-details row
        >
          <v-radio
            v-for="option of Object.values(type)"
            :value="option.value"
            :key="option.text"
            :label="option.text"
            class="form-radio flat"
          ></v-radio>
        </v-radio-group>
        <v-select
          v-model="favoriteMenu[3].menuOrDescription"
          item-text='description'
          :disabled="favoriteMenu[3].type==='NONE'"
          :items="getFavoriteMenuOptions(favoriteMenu[3])"
          :placeholder="getFavoriteBtnPlaceholder(favoriteMenu[3].type)"
          class="form-select mt-4"
          outlined
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <h4>Favorite 5</h4>
        <v-radio-group
          v-model="favoriteMenu[4].type"
          @change="handleTypeChange($event, favoriteMenu[4])"
          class="mt-3" dense hide-details row
        >
          <v-radio
            v-for="option of Object.values(type)"
            :value="option.value"
            :key="option.text"
            :label="option.text"
            class="form-radio flat"
          ></v-radio>
        </v-radio-group>
        <v-select
          v-model="favoriteMenu[4].menuOrDescription"
          :items="getFavoriteMenuOptions(favoriteMenu[4])"
          item-text='description'
          :disabled="favoriteMenu[4].type==='NONE'"
          :placeholder="getFavoriteBtnPlaceholder(favoriteMenu[4].type)"
          class="form-select mt-4"
          outlined
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col>
        <h4>Favorite 6</h4>
        <v-radio-group
          v-model="favoriteMenu[5].type"
          @change="handleTypeChange($event, favoriteMenu[5])"
          class="mt-3" dense hide-details row
        >
          <v-radio
            v-for="option of Object.values(type)"
            :value="option.value"
            :key="option.text"
            :label="option.text"
            class="form-radio flat"
          ></v-radio>
        </v-radio-group>
        <v-select
          v-model="favoriteMenu[5].menuOrDescription"
          item-text='description'
          :disabled="favoriteMenu[5].type==='NONE'"
          :items="getFavoriteMenuOptions(favoriteMenu[5])"
          :placeholder="getFavoriteBtnPlaceholder(favoriteMenu[5].type)"
          class="form-select mt-4"
          outlined
          dense
          hide-details
          solo
        ></v-select>
      </v-col>
    </v-row>
    <div class="mt-5">
      <v-btn
        @click="hadleSaveBtnClick"
        :disabled='btnDisabledDashboardSettings'
        text class="btn-s ml-2"
      >
        {{ $t("Save") }}
      </v-btn>
    </div>
    <msg-box
      :msgBox="msgBox"
      @closeMsgBox="closeMsgBox"
    />
  </div>
</template>

<script>
// Utils
import codes from '@/plugins/codes'
import commons from '@/plugins/commons'
import EventBus from '@/plugins/eventBus'
// Minxins
import MsgBoxParent from '@/mixins/MsgBoxParent'

export default {
  name: 'FavoriteMenu',
  mixins: [MsgBoxParent],
  props: {
    favoriteButtonEnabled: {
      type: Boolean,
      default: false
    },
    selectedareaid: [Array, Object, String, Number],
    reloaddatafav: [Boolean],
    appConfigSaveDisabled1: [Boolean]

  },
  data () {
    return {
      selectedFavoriteMenuStore: {},
      favoriteMenuStores: [],
      favoriteMenu: [],
      customerInfoList: [],
      // customerdescription: []
      // button disabled
      btnDisabledDashboardSettings: null
    }
  },
  computed: {
    selectedStore () {
      return this.$store.state.dataStore.selectedStore
    },
    defaultFavoriteButtonList () {
      return [
        {
          areaId: null,
          description: null,
          menu: null,
          priority: 0,
          stationCode: null,
          type: 'MENU'
        },
        {
          areaId: null,
          description: null,
          menu: null,
          priority: 0,
          stationCode: null,
          type: 'MENU'
        },
        {
          areaId: null,
          description: null,
          menu: null,
          priority: 0,
          stationCode: null,
          type: 'MENU'
        },
        {
          areaId: null,
          description: null,
          menu: null,
          priority: 0,
          stationCode: null,
          type: 'MENU'
        },
        {
          areaId: null,
          description: null,
          menu: null,
          priority: 0,
          stationCode: null,
          type: 'MENU'
        },
        {
          areaId: null,
          description: null,
          menu: null,
          priority: 0,
          stationCode: null,
          type: 'MENU'
        }
      ]
    },
    type () {
      return {
        MENU: { text: 'Menu', value: 'MENU' },
        CUSTOMER_INFO: { text: 'Customer Info', value: 'CUSTOMER_INFO' },
        NONE: { text: 'None', value: 'NONE' }

      }
    },
    menu () {
      return {
        1: 'Split Label',
        2: 'Additional Label',
        3: 'Customer Information',
        4: 'SSC for ESL',
        5: 'Rolling',
        6: 'Freshness Control',
        7: 'Low Battery',
        8: 'Delete Label(RMA)',
        9: 'Language'
      }
    },
    togetcustomerinfo () {
      return this.selectedareaid
    },
    tochangeflag () {
      return this.reloaddatafav
    }
  },
  watch: {
    togetcustomerinfo () {
      console.log('sdsadasdada', this.selectedareaid)
      // this.areaid = this.selectedareaid
      // this.handleCustomerInfoDefaultInput(this.selectedareaid)
      this.handleFavoriteMenuStoreInput(this.selectedareaid)
    },
    tochangeflag () {
      console.log(this.reloaddatafav)
      if (this.reloaddatafav === true) {
        this.handleFavoriteMenuStoreInput(this.selectedareaid)
      }
    }
  },
  created () {
    EventBus.$on('reload', () => {
      this.handleFavoriteMenuStoreInput(this.selectedareaid)
    })

    this.favoriteMenu = this.convertFavoriteBtnsRes(this.defaultFavoriteButtonList)
  },
  mounted () {
    this.favoriteMenuStores = this.$store.state.auth.user.managedStores
    EventBus.$on('reloadFavoriteMenuCustomerInfo', () => {
      this.reloadCustomerInfo()
    })
    this.$store.dispatch('auth/getDisabledBtn', '9300').then((flag) => {
      // this.btnDisabledDashboardSettings = flag
      this.btnDisabledDashboardSettings = this.appConfigSaveDisabled1
      this.handleFavoriteMenuStoreInput(this.selectedareaid)
    })
  },
  methods: {
    // etc
    getFavoriteMenuOptions (favoriteMenu) {
      return favoriteMenu.type === this.type.MENU.value ? Object.values(this.menu).map(el => el) : this.customerInfoList
    },
    async initFavoriteMenu (stationCode) {
      this.favoriteMenu = this.convertFavoriteBtnsRes(await this.getFavoriteButtonList(stationCode))
    },
    getFavoriteBtnPlaceholder (type) {
      if (type !== 'NONE') {
        return `Select the ${this.type[type].text}`
      } else { return '-' }
    },
    convertFavoriteBtnsRes (buttonList) {
      const convertedBtnList = buttonList.map(button => {
        if (button.type && (button.menu || button.description)) {
          button.menuOrDescription = (button.type === this.type.MENU.value)
            ? Object.values(this.menu)[button.menu - 1] : button.description
        } else {
          button.menuOrDescription = null
        }
        return button
      })
      if (convertedBtnList.length < 6) {
        const defaultBtn = {
          areaId: null,
          description: null,
          menu: null,
          priority: 0,
          stationCode: null,
          type: 'MENU'
        }
        for (let i = convertedBtnList.length; i < 6; i++) {
          convertedBtnList.push(commons.copy(defaultBtn))
        }
      }
      return convertedBtnList
    },
    buildFavoriteMenuToPost (favoriteMenu) {
      return favoriteMenu.map(button => {
        if (button.type === this.type.MENU.value) {
          button.menu = Object.values(this.menu).indexOf(button.menuOrDescription) + 1
          button.description = null
        } else {
          button.description = button.menuOrDescription
          button.menu = null
        }
        const valuetype = typeof (this.selectedareaid)
        console.log(valuetype)
        if (valuetype === 'number' || valuetype === undefined) {
          button.stationCode = null
          button.areaId = this.selectedareaid
        } else {
          button.stationCode = this.selectedareaid
          button.areaId = null
        }

        delete button.menuOrDescription
        return button
      })
    },
    validateFavoriteMenu (favoriteMenu) {
      const menuOptions = Object.values(this.menu)

      for (const menu of favoriteMenu) {
        // console.log(menu.type)
        // console.log(this.type.MENU.value)
        if (menu.menuOrDescription === 0) menu.menuOrDescription = null
        if (commons.isNull(menu.menuOrDescription) && (menu.type === this.type.MENU.value || menu.type !== 'NONE') && (menu.type !== this.type.MENU.value || menu.type !== 'NONE' || menu.type !== 'CUSTOMER_INFO' || menu.type !== 'MENU') ? !menuOptions.includes(menu.menuOrDescription) : this.customerInfoList.includes(menu.menuOrDescription)) {
          this.openMsgBox(`Please select ${this.type[menu.type].text}`)
          return false
        }
      }
      return true
    },
    async reloadCustomerInfo (stationCode = this.selectedFavoriteMenuStore.code) {
      this.btnDisabledDashboardSettings = this.appConfigSaveDisabled1
      if (!stationCode) return
      const customerInfoList = await this.$utils.getCustomerInfo(
        stationCode,
        codes.customerInfoUsage.default
      )
      this.customerInfoList = customerInfoList?.map(info => info.description) || []
    },
    // Event listeners
    handleFavoriteMenuStoreInput (stationCode) {
      this.initFavoriteMenu(stationCode)
      this.getCustomerInfo(stationCode)
      // this.reloadCustomerInfo(stationCode)
    },
    getCustomerInfo (data) {
      console.log(typeof (data))
      const valuetype = typeof (data)
      console.log(valuetype)
      var config
      if (valuetype === 'number' || valuetype === undefined) {
        config = {
          params: {
            // usage, // Default || Bakery
            contents: 'false',
            stationCode: null,
            areaId: data

          }
        }
      } else {
        config = {
          params: {
            // usage, // Default || Bakery
            contents: 'false',
            stationCode: data,
            areaId: null

          }
        }
      }

      this.$utils
        .callAxios(
          codes.requests.getCustomerInfo.method,
					`${codes.requests.getCustomerInfo.url}`,
					config
        )
        .then((res) => {
          console.log(res.data)
          // for (let i = 0; i < res.data.customerInfoList.length; i++) {
          //   this.customerdescription.push(res.data.customerInfoList[i].description)
          // }
          this.customerInfoList = res.data.customerInfoList
        })
        .catch((error) => {
          console.debug(`Could not find customer information. error: ${error}`)
        })
    },
    handleTypeChange (event, favofiteButton) {
      if (event === 'CUSTOMER_INFO') {
        this.getCustomerInfo(this.selectedareaid)
      }
      favofiteButton.menuOrDescription = (event === this.type.MENU.value)
        ? Object.values(this.menu).indexOf(favofiteButton.menuOrDescription) + 1
        : this.menu[favofiteButton.menuOrDescription]
    },
    hadleSaveBtnClick () {
      console.log(this.favoriteMenu)
      // 저장하려는 버튼들의 값이 비었는지, 혹은 type와 데이터형식이 일치하는지 확인.
      if (!this.validateFavoriteMenu(this.favoriteMenu)) return
      // favoriteButton.type이 Menu 인 경우: favoriteButton.description 화면에 표출.
      // favoriteButton.type이 Customer Information 인 경우: 선택한 store의 Customer Information.description 화면에 표출.
      // 화면에 표출하는 정보는 menuOrDescription이고, 서버에서 사용하는 값은 API 문서 참고.
      // 아래 buildFavoriteMenuToPost 메서드에서 적절히 위의 규칙에 따라 형식을 수정해서 보낸다.
      const favoriteMenu = this.buildFavoriteMenuToPost(commons.copy(this.favoriteMenu))
      this.saveFavoriteButtonList(favoriteMenu)
    },
    // API
    getFavoriteButtonList (stationCode) {
      if (!stationCode) return
      const valuetype = typeof (stationCode)
      var config
      if (valuetype === 'number' || valuetype === undefined) {
        config = {
          params: {
            stationCode: null,
            areaId: stationCode
          }
        }
      } else {
        config = {
          params: {
            stationCode: stationCode,
            areaId: null

          }
        }
      }
      // const config = {
      //   params: {
      //     stationCode: stationCode
      //   }
      // }
      return this.$utils
        .callAxios(
          codes.requests.getFavoriteButtonList.method,
					`${codes.requests.getFavoriteButtonList.url}`,
					config
        )
        .then((res) => {
          if (res.status === 204) throw Error(`Could not find any favorite button list. status: ${res.status}`)
          console.log(res.data.favoriteButtonList)
          const favoriteid = []
          for (let i = 0; i < res.data.favoriteButtonList.length; i++) {
            // if (res.data.customerInfoList[i].id) {
            favoriteid.push(res.data.favoriteButtonList[i].id)
            // }
          }
          this.customerInfoList = res.data.favoriteButtonList
          console.log(favoriteid)
          localStorage.setItem('favoriteids', JSON.stringify(favoriteid))
          this.getCustomerInfo(this.selectedareaid)
          return res.data.favoriteButtonList
        })
        .catch((error) => {
          console.debug(`An error occured while loading favorite buttons. error: ${error}`)
          return this.defaultFavoriteButtonList
        })
    },
    saveFavoriteButtonList (buttonList) {
      const config = { params: {} }
      this.$utils
        .callAxiosWithBody(
          codes.requests.saveFavoriteButtonList.method,
          codes.requests.saveFavoriteButtonList.url,
          buttonList,
          config
        )
        .then((res) => {
          this.openMsgBox(this.$t(res.data.responseMessage))
          this.initFavoriteMenu(this.selectedareaid)
        })
        .catch((error) => {
          console.debug(`Failed to save Favorite Button. error: ${error}`)
          this.openMsgBox(this.$t('Fail to save Favorite Button.'))
        })
    }
  }
}
</script>

<style>
</style>
